import {ApolloClient, createHttpLink, InMemoryCache, gql} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {DataManager} from './DataManager.js';

const API_URI =
	process.env.NODE_ENV === 'development'
		? `http://localhost:4443/graphql`
		: `https://mundo.visitavirtualmonzon.com:4443/graphql`;
const httpLink = createHttpLink({
	uri: API_URI,
});
const authLink = setContext((_, {headers}) => {
	// get the authentication token from local storage if it exists
	const token = localStorage.getItem('token');
	// return the headers to the context so httpLink can read them
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : '',
		},
	};
});

const apolloClient = new ApolloClient({
	link: authLink.concat(httpLink),
	cache: new InMemoryCache(),
});

//#region Users
const GET_USERS = gql`
	query getUsers {
		getUsers {
			id
			email
			name
			surname
			position
			best_time
			avatar_id
			description
		}
	}
`;

async function GetUsers() {
	const clients = await GetElements(GET_USERS);
	DataManager.Instance().SetClients(clients.getUsers);
}

// By Best time --------------------------------------------------------------------
const GET_USERS_BY_BEST_TIME = gql`
	query getUsersByBestTime {
		getUsersByBestTime {
			id
			email
			name
			surname
			position
			best_time
			avatar_id
			description
		}
	}
`;

async function GetUsersByBestTime() {
	const clients = await GetElements(GET_USERS_BY_BEST_TIME);
	return clients;
}

// User --------------------------------------------------------------------------
const GET_USER = gql`
	query login($email: String, $password: String) {
		login(email: $email, password: $password) {
			correct
			user {
				id
				email
				name
				surname
				position
				best_time
				avatar_id
				description
				admin
			}
			message
			token
		}
	}
`;
async function Login(email, password) {
	return await apolloClient.query({
		query: GET_USER,
		variables: {email: email, password: password},
	});
}

//#endregion Users

//#region Rooms
const GET_ROOMS = gql`
	query getRooms {
		getRooms {
			name
			capacity
			multiple
			stage
			video
			model
		}
	}
`;

async function GetRooms() {
	const data = await GetElements(GET_ROOMS);
	DataManager.Instance().SetRooms(data.getRooms);
}
//#endregion Rooms

//#region Avatars
const GET_AVATAR = gql`
	query getAvatar($id: Int) {
		getAvatar(id: $id) {
			genre
			hair
			hair_color
			skin_color
			jacket
			trousers
			shoes
			beard
		}
	}
`;

const GET_AVATARS = gql`
	query getAvatars {
		getAvatars {
			id
			genre
			hair
			hair_color
			skin_color
			jacket
			trousers
			shoes
			beard
		}
	}
`;

const POST_AVATAR = gql`
	mutation PostAvatar(
		$user_id: Int
		$genre: Int
		$hair: Int
		$hair_color: Int
		$skin_color: Int
		$jacket: Int
		$trousers: Int
		$shoes: Int
		$beard: Int
	) {
		postAvatar(
			user_id: $user_id
			genre: $genre
			hair: $hair
			hair_color: $hair_color
			skin_color: $skin_color
			jacket: $jacket
			trousers: $trousers
			shoes: $shoes
			beard: $beard
		) {
			id
		}
	}
`;

async function GetAvatar(id) {
	const data = await GetElements(GET_AVATAR, {id: id});
	DataManager.Instance().SetAvatar(data.getAvatars[0]);
}

async function GetAvatars() {
	const data = await GetElements(GET_AVATARS);
	DataManager.Instance().SetAvatars(data.getAvatars);
}

async function PostAvatar(
	user_id,
	{genre, hair, hair_color, skin_color, jacket, trousers, shoes, beard = -1}
) {
	const result = await apolloClient.mutate({
		mutation: POST_AVATAR,
		variables: {
			user_id: user_id,
			genre: genre,
			hair: hair,
			hair_color: hair_color,
			skin_color: skin_color,
			jacket: jacket,
			trousers: trousers,
			shoes: shoes,
			beard: beard,
		},
	});

	return result;
}

const UPDATE_AVATAR = gql`
	mutation UpdateAvatar(
		$id: Int
		$genre: Int
		$hair: Int
		$hair_color: Int
		$skin_color: Int
		$jacket: Int
		$trousers: Int
		$shoes: Int
		$beard: Int
	) {
		updateAvatar(
			id: $id
			genre: $genre
			hair: $hair
			hair_color: $hair_color
			skin_color: $skin_color
			jacket: $jacket
			trousers: $trousers
			shoes: $shoes
			beard: $beard
		) {
			success
		}
	}
`;

async function UpdateAvatar(
	id,
	{genre, hair, hair_color, skin_color, jacket, trousers, shoes, beard = -1}
) {
	const result = await apolloClient.mutate({
		mutation: UPDATE_AVATAR,
		variables: {
			id: id,
			genre: genre,
			hair: hair,
			hair_color: hair_color,
			skin_color: skin_color,
			jacket: jacket,
			trousers: trousers,
			shoes: shoes,
			beard: beard,
		},
	});

	return result;
}
//#endregion Avatars

//#region Videos
const GET_VIDEOS = gql`
	query getVideos {
		getVideos {
			id
			url
			type
			positionX
			positionY
			positionZ
			scaleX
			scaleY
			scaleZ
			inverseVideoScale
			initialVolume
		}
	}
`;

async function GetVideos() {
	const data = await GetElements(GET_VIDEOS);
	DataManager.Instance().SetVideos(data.getVideos);
}
//#endregion Videos

//#region Best Time
const POST_BESTIME = gql`
	mutation PostBestTime($user_id: Int, $best_time: Float) {
		postBestTime(user_id: $user_id, best_time: $best_time) {
			id
		}
	}
`;

async function PostBestTime(user_id, bestTime) {
	const result = await apolloClient.mutate({
		mutation: POST_BESTIME,
		variables: {
			user_id: user_id,
			best_time: bestTime,
		},
	});

	return result;
}
//#endregion Best Time

//#region Logs
const POST_LOG = gql`
	mutation PostLog($log_type: Int, $user_id: Int, $value: String) {
		postLog(log_type: $log_type, user_id: $user_id, value: $value) {
			success
		}
	}
`;

async function PostLog(log_type, user_id, value) {
	const variables = {
		log_type: log_type,
		user_id: user_id,
		value: JSON.stringify(value),
	};
	const result = await apolloClient.mutate({
		mutation: POST_LOG,
		variables: variables,
	});
	return result;
}
//#endregion Logs

//#region Post Questions
const POST_QUESTION = gql`
	mutation PostQuestion($userMail: String, $question: String, $time: String) {
		postQuestion(userMail: $userMail, question: $question, time: $time) {
			id
		}
	}
`;

async function PostQuestion(userMail, question, time) {
	const result = await apolloClient.mutate({
		mutation: POST_QUESTION,
		variables: {
			userMail: userMail,
			question: question,
			time: time,
		},
	});

	return result;
}
//#endregion Post Questions

//#region Reset password

const POST_RESET = gql`
	mutation PostReset($email: String) {
		postReset(email: $email) {
			success
			message
		}
	}
`;

async function PostReset(email) {
	const result = await apolloClient.mutate({
		mutation: POST_RESET,
		variables: {
			email: email,
		},
	});
	return result;
}

const POST_CHANGE_PWD = gql`
	mutation PostChangePwd($email: String, $code: String, $newPassword: String) {
		postChangePwd(email: $email, code: $code, newPassword: $newPassword) {
			success
			message
		}
	}
`;

async function PostChangePwd(email, code, newPwd) {
	const result = await apolloClient.mutate({
		mutation: POST_CHANGE_PWD,
		variables: {
			email: email,
			code: code,
			newPassword: newPwd,
		},
	});
	return result;
}
//@endregion Reset password

//Generic query petition
async function GetElements(query, parameters = null) {
	const result = await apolloClient.query({
		query: query,
		variables: parameters,
		fetchPolicy: 'network-only',
	});
	return result.data;
}

export {
	GetUsers,
	GetUsersByBestTime,
	Login,
	GetRooms,
	GetAvatar,
	GetAvatars,
	GetVideos,
	PostAvatar,
	UpdateAvatar,
	PostBestTime,
	PostQuestion,
	PostLog,
	PostReset,
	PostChangePwd,
	apolloClient,
};
