import * as THREE from './3D/three.module.js';
import * as Utils from './utils.js';
import {AsyncLoader} from './AsyncLoader';
import {DisposeObj} from './utils.js';

export class CharacterCreator {
	constructor(callback, globalManager, previousAspect = null) {
		this.globalManager = globalManager;

		this.globalManager.characterCreator = this;
		this.globalManager.ShowLoadingScreen(3);
		//
		this.initVariables();
		this.previousAspect = previousAspect;
		this.initClotheArrays();

		this.scene = new THREE.Scene();
		this.scene.clock = new THREE.Clock();
		//Camera and renderer
		this.initCamera();
		this.initRenderer();
		//load env and models
		//this.scene.add(new THREE.AmbientLight(0xffffe6, 0.7));
		this.loadPlayer(callback);
		this.loadEnvironment();
		//
		this.initEventHandlers();
		this.id = requestAnimationFrame(() => this.update());
	}

	//#region Initialization

	initVariables() {
		this.maleMixer = null;
		this.femaleMixer = null;
		this.maleModel = null;
		this.femaleModel = null;
		this.keyState = {};
		this.maleAnimations = [];
		this.femaleAnimation = [];
		this.oneAvailable = true;
	}

	initCamera() {
		this.width = window.innerWidth;
		this.height = window.innerHeight;
		this.camera = new THREE.PerspectiveCamera(50, this.width / this.height, 0.01, 200);
		this.camera.position.set(0, 1, 3);
		this.scene.add(this.camera);
	}

	initClotheArrays() {
		this.male = {
			hairs: [],
			beards: [],
			jackets: [],
			trousers: [],
			shoes: [],
			skin: [],
		};
		this.female = {
			hairs: [],
			jackets: [],
			trousers: [],
			shoes: [],
			skin: [],
		};
		if (this.previousAspect === null) {
			this.selection = {
				genre: 0,
				hair: 0,
				beard: 0,
				jacket: 0,
				trousers: 0,
				shoes: 0,
				skin_color: 0,
				hair_color: 0,
			};
		} else {
			this.selection = {
				genre: this.previousAspect.genre,
				hair: this.previousAspect.hair,
				beard: this.previousAspect.beard,
				jacket: this.previousAspect.jacket,
				trousers: this.previousAspect.trousers,
				shoes: this.previousAspect.shoes,
				skin_color: this.previousAspect.skin_color,
				hair_color: this.previousAspect.hair_color,
			};
		}
	}

	initRenderer() {
		//THREE WebGL renderer
		this.renderer = new THREE.WebGLRenderer({
			powerPreference: 'high-performance',
			antialias: true,
		});
		this.renderer.setClearColor(new THREE.Color('lightblue'));
		this.renderer.setSize(this.width, this.height);
		// Extra para modeles y skyboxes
		this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
		this.renderer.toneMappingExposure = 1;
		this.renderer.outputEncoding = THREE.sRGBEncoding;
		//Push the canvas to the DOM
		let domElement = document.getElementById('canvas-container');
		domElement.append(this.renderer.domElement);
	}

	initEventHandlers() {
		this.resizeHandler = (e) => {
			this.onWindowResize(e);
		};
		this.kDownHandler = (e) => {
			this.onKeyDown(e);
		};
		this.kUpHandler = (e) => {
			this.onKeyUp(e);
		};
		window.addEventListener('resize', this.resizeHandler, false);
		window.addEventListener('keydown', this.kDownHandler, false);
		window.addEventListener('keyup', this.kUpHandler, false);
	}

	async loadEnvironment() {
		this.loadEnvMap();
		const scenario = await AsyncLoader.LoadGLBModelAsync('../assets/models/hall_monzon.glb');

		scenario.scene.traverse(function (child) {
			if (
				child.name.indexOf('floor_collider') >= 0 ||
				child.name === 'collidersuelo' ||
				child.name === 'collider_suelo'
			) {
				child.visible = false;
			}
		});

		this.scene.add(scenario.scene);
		this.scenario = scenario.scene;
		this.scenario.rotation.y = Math.PI * 2;
		this.scenario.position.z = -10;
		this.scenario.position.x = -5;
		this.loadedAssets++;
		this.globalManager.assetLoaded();
	}
	//#endregion Initialization

	//#region Loaders
	async loadEnvMap() {
		this.scene.environment = await AsyncLoader.LoadEnvMapAsync(
			'assets/environment.hdr',
			this.renderer
		);
	}

	async loadPlayer(callback) {
		const maleGltf = await AsyncLoader.GetPlayerModel(0);
		this.loadedAssets++;
		this.globalManager.assetLoaded();
		this.OnMaleModelLoaded(maleGltf);
		const femaleGltf = await AsyncLoader.GetPlayerModel(1);
		this.globalManager.assetLoaded();
		this.globalManager.setShowCharacterConfigurator(true);
		this.OnFemaleModelLoaded(femaleGltf);
		this.orderClotheArrays();
		if (this.previousAspect !== null) {
			if (this.selection.genre === 0) {
				this.setClothes(this.male);
			} else {
				this.setClothes(this.female);
			}
			this.applyAspect();
		} else {
			this.generateRandomPlayer();
		}
		this.startFunction = callback;
	}

	OnMaleModelLoaded(gltf) {
		const object = Utils.clone(gltf.scene);
		this.maleMixer = new THREE.AnimationMixer(object);

		object.traverse((child) => {
			if (child.isMesh) {
				child.castShadow = false;
				child.receiveShadow = false;
				child.frustumCulled = true;
			}
			if (child.name.indexOf('Hair') >= 0) {
				this.male.hairs.push(child);
			} else if (child.name.indexOf('Beard') >= 0) {
				this.male.beards.push(child);
			} else if (child.name.indexOf('Chest') >= 0) {
				this.male.jackets.push(child);
			} else if (child.name.indexOf('Trousers') >= 0) {
				this.male.trousers.push(child);
			} else if (child.name.indexOf('shoes') >= 0) {
				this.male.shoes.push(child);
			} else if (child.name.indexOf('Male_Body') >= 0) {
				this.male.skin.push(child);
			}
		});

		this.scene.add(object);
		this.maleModel = object;
		this.maleModel.visible = false;

		this.maleAnimations = gltf.animations;
	}

	OnFemaleModelLoaded(gltf) {
		const object = Utils.clone(gltf.scene);
		this.femaleMixer = new THREE.AnimationMixer(object);

		object.traverse((child) => {
			if (child.isMesh) {
				child.castShadow = false;
				child.receiveShadow = false;
				child.frustumCulled = true;
			}
			if (child.name.indexOf('Hair') >= 0) {
				this.female.hairs.push(child);
			} else if (child.name.indexOf('Chest') >= 0) {
				this.female.jackets.push(child);
			} else if (child.name.indexOf('Trousers') >= 0) {
				this.female.trousers.push(child);
			} else if (child.name.indexOf('shoes') >= 0) {
				this.female.shoes.push(child);
			} else if (child.name.indexOf('Female_Body') >= 0) {
				this.female.skin.push(child);
			}
		});

		this.scene.add(object);
		this.femaleModel = object;
		this.femaleModel.visible = false;

		this.femaleAnimations = gltf.animations;
	}

	orderClotheArrays() {
		this.female.hairs.sort(Utils.sortingFunc);
		this.female.jackets.sort(Utils.sortingFunc);
		this.female.trousers.sort(Utils.sortingFunc);
		this.female.shoes.sort(Utils.sortingFunc);
		this.female.skin.sort(Utils.sortingFunc);
		this.male.hairs.sort(Utils.sortingFunc);
		this.male.jackets.sort(Utils.sortingFunc);
		this.male.trousers.sort(Utils.sortingFunc);
		this.male.shoes.sort(Utils.sortingFunc);
		this.male.skin.sort(Utils.sortingFunc);
		this.male.beards.sort(Utils.sortingFunc);
	}

	//#endregion Loaders

	update() {
		this.id = requestAnimationFrame(() => this.update());
		let delta = this.scene.clock.getDelta();
		if (this.maleMixer) this.maleMixer.update(delta);
		if (this.femaleMixer) this.femaleMixer.update(delta);

		this.renderer.render(this.scene, this.camera);
	}

	dispose() {
		DisposeObj(this.scene);
		this.renderer.dispose();
		cancelAnimationFrame(this.id);
		this.scene = null;
		this.maleMixer = null;
		this.femaleMixer = null;
		this.renderer = null;
		this.scenario = null;
		let domElement = document.getElementById('canvas-container');
		domElement.removeChild(domElement.lastChild);
		window.removeEventListener('resize', this.resizeHandler, false);
		window.removeEventListener('keydown', this.kDownHandler, false);
		window.removeEventListener('keyup', this.kUpHandler, false);
		delete this;
	}

	//#region Event Handlers
	onWindowResize(e) {
		this.width = window.innerWidth;
		this.height = window.innerHeight;
		this.camera.aspect = this.width / this.height;
		this.camera.updateProjectionMatrix();
		this.renderer.setSize(this.width, this.height);
	}

	// keystate functions from playercontrols
	onKeyDown(event) {
		event = event || window.event;
		this.keyState[event.keyCode || event.which] = true;
	}

	onKeyUp(event) {
		event = event || window.event;
		this.keyState[event.keyCode || event.which] = false;
	}

	//#endregion Event Handlers

	//#region Aspect
	generateRandomPlayer() {
		this.selection.genre = Utils.getRandomInt(0, 1);
		if (this.selection.genre === 0) {
			this.setClothes(this.male);
		} else {
			this.setClothes(this.female);
		}
		this.randomAspect();
		this.applyAspect();
	}

	randomAspect() {
		this.selection.hair = Utils.getRandomInt(0, this.hairs.length - 1);
		if (this.selection.genre === 0) {
			this.selection.beard = Utils.getRandomInt(0, this.beards.length - 1);
		}
		this.selection.jacket = Utils.getRandomInt(0, this.jackets.length - 1);
		this.selection.trousers = Utils.getRandomInt(0, this.trousers.length - 1);
		this.selection.shoes = Utils.getRandomInt(0, this.shoes.length - 1);
		this.selection.skin_color = Utils.getRandomInt(0, this.skin.length - 1);
	}

	setClothes(model) {
		this.hairs = model.hairs;
		this.beards = model.beards;
		this.jackets = model.jackets;
		this.trousers = model.trousers;
		this.shoes = model.shoes;
		this.skin = model.skin;
	}

	applyAspect() {
		this.applySelection(this.selection.hair, this.hairs);
		this.applySelection(this.selection.jacket, this.jackets);
		this.applySelection(this.selection.trousers, this.trousers);
		this.applySelection(this.selection.shoes, this.shoes);
		this.applySelection(this.selection.skin_color, this.skin);
		if (this.selection.genre === 0) {
			this.maleModel.visible = true;
			this.femaleModel.visible = false;
			this.applySelection(this.selection.beard, this.beards);
			this.femaleMixer.clipAction(this.femaleAnimations[1]).stop();
			this.maleMixer.clipAction(this.maleAnimations[1]).play();
		} else {
			this.maleModel.visible = false;
			this.femaleModel.visible = true;
			this.maleMixer.clipAction(this.maleAnimations[1]).stop();
			this.femaleMixer.clipAction(this.femaleAnimations[1]).play();
		}
		this.globalManager.setCurrentOptions(this.selection.genre);
		this.globalManager.setCharacterSelection(this.selection);
	}

	applySelection(index, array) {
		for (let i = 0; i < array.length; i++) {
			const element = array[i];
			element.visible = i === index;
		}
	}
	//#endregion Aspect

	//#region Button Functions
	// Hair ---------------------------------------------------------------------------
	switchHair() {
		this.selection.hair = this.switchPart(this.selection.hair, this.hairs);
	}

	selectHair(index) {
		this.selection.hair = this.selectPart(index, this.hairs);
	}

	// Beard ----------------------------------------------------------------------------
	switchBeard() {
		this.selection.beard = this.switchPart(this.selection.beard, this.beards);
	}
	selectBeard(index) {
		this.selection.beard = this.selectPart(index, this.beards);
	}

	// Chest -------------------------------------------------------------------------------
	switchChest() {
		this.selection.jacket = this.switchPart(this.selection.jacket, this.jackets);
	}
	selectJacket(index) {
		this.selection.jacket = this.selectPart(index, this.jackets);
	}

	// Trousers ------------------------------------------------------------------------------
	switchTrousers() {
		this.selection.trousers = this.switchPart(this.selection.trousers, this.trousers);
	}
	selectTrousers(index) {
		this.selection.trousers = this.selectPart(index, this.trousers);
	}

	// Shoes -------------------------------------------------------------------------------
	switchShoes() {
		this.selection.shoes = this.switchPart(this.selection.shoes, this.shoes);
	}
	selectShoes(index) {
		this.selection.shoes = this.selectPart(index, this.shoes);
	}

	// Skin ------------------------------------------------------
	switchSkin() {
		this.selection.skin_color = this.switchPart(this.selection.skin_color, this.skin);
	}

	selectSkin(index) {
		this.selection.skin_color = this.selectPart(index, this.skin);
	}

	// Parts -----------------------------------------------------------------
	switchPart(index, array) {
		index++;
		index = index >= array.length ? 0 : index;
		this.applySelection(index, array);
		return index;
	}

	selectPart(index, array) {
		this.applySelection(index, array);
		//
		this.globalManager.setCharacterSelection(this.selection);
		//
		return index;
	}

	// Gender -------------------------------------------------------------
	switchGender() {
		this.selection.genre = this.selection.genre === 0 ? 1 : 0;
		if (this.selection.genre === 0) {
			this.setClothes(this.male);
		} else {
			this.setClothes(this.female);
		}
		this.randomAspect();
		this.applyAspect();
	}

	selectGender(index) {
		this.selection.genre = index;
		switch (index) {
			case 0:
				this.setClothes(this.male);
				break;
			case 1:
				this.setClothes(this.female);
				break;
			default:
				break;
		}
		this.randomAspect();
		this.applyAspect();
	}

	toNextPage() {
		if (this.startFunction == null) {
			return;
		}
		this.startFunction(this.selection);
		this.dispose();
	}

	cancelOperation() {
		if (this.startFunction == null) {
			return;
		}
		this.startFunction(this.previousAspect, false);
		this.dispose();
	}
	//#endregion Button Functions
}
