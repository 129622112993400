export class DataManager {
	static instance;
	static Instance = () => {
		if (DataManager.instance == null) {
			new DataManager();
		}
		return DataManager.instance;
	};

	constructor() {
		DataManager.instance = this;
		//Users
		this.users = [];
		this.OnUsersReceived = null;
		//Rooms
		this.rooms = [];
		this.OnRoomsRecived = null;
		//Avatar
		this.avatars = {};
		this.OnAvatarsReceived = null;
		//Videos
		this.videos = [];
		this.OnVideosRecived = null;
	}

	SetClients(userList) {
		this.users = [];
		for (let i = 0; i < userList.length; i++) {
			this.users.push(userList[i]);
		}
		if (this.OnUsersReceived != null) this.OnUsersReceived();
	}

	SetRooms(roomList) {
		this.rooms = [];
		for (let i = 0; i < roomList.length; i++) {
			this.rooms.push(roomList[i]);
		}
		if (this.OnRoomsRecived != null) this.OnRoomsRecived();
	}

	SetAvatars(avatars) {
		this.avatars = [];
		for (let i = 0; i < avatars.length; i++) {
			this.avatars.push(avatars[i]);
		}
		if (this.OnAvatarsReceived != null) this.OnAvatarsReceived();
	}

	SetVideos(videos) {
		this.videos = [];
		for (let i = 0; i < videos.length; i++) {
			this.videos.push(videos[i]);
		}
		if (this.OnVideosRecived != null) this.OnVideosRecived();
	}

	FindUserByEmail(email) {
		for (let i = 0; i < this.users.length; i++) {
			const element = this.users[i];
			if (element.email === email) {
				return element;
			}
		}
		return null;
	}

	GetAvatarById(id) {
		for (let i = 0; i < this.avatars.length; i++) {
			const avatar = this.avatars[i];
			if (avatar.id === id) {
				return avatar;
			}
		}
		return null;
	}

	GetVideoById(id) {
		for (let i = 0; i < this.videos.length; i++) {
			const video = this.videos[i];
			if (video.id === id) {
				return video;
			}
		}
		return null;
	}

	UpdateAvatar(id, aspect) {
		for (let i = 0; i < this.avatars.length; i++) {
			if (this.avatars[i].id === id) {
				aspect.id = id;
				this.avatars[i] = aspect;
				return;
			}
		}
	}
}
