import {Scene} from './scene';
import {DataManager} from './DataManager';
import * as THREE from './3D/three.module.js';

export class VideoElement {
	constructor(videoId) {
		this.id = videoId;
		this.loadVideo(false);
	}

	prepare(callback) {
		if (this.loaded) {
			callback(this);
		} else {
			DataManager.Instance().OnVideosRecived = () => {
				this.loadVideo(true);
				callback(this);
			};
		}
	}

	loadVideo(refresh) {
		const video = DataManager.Instance().GetVideoById(this.id);
		if (video === null) {
			this.loaded = false;
			return;
		}
		this.url = video.url;
		this.type = video.type;
		this.position = new THREE.Vector3(video.positionX, video.positionY, video.positionZ);
		this.scale = new THREE.Vector3(video.scaleX, video.scaleY, video.scaleZ);
		this.videoScale = 1 / video.inverseVideoScale;
		this.initialVolume = video.initialVolume;
		if (refresh) {
			Scene.glScene.player.loadVideoById(video.url);
		}
		this.loaded = true;
	}
}
