/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Login} from './components/Login';
import {toAbsoluteUrl} from '../../../_metronic/helpers';

import './auth-custom.scss';
import {Instructions} from '../socket/Instructions';

import {CheckIfMobileOrTablet} from './deviceCheck';
import {ChangePassword} from './components/ChangePassword';

export function AuthPage() {
	useEffect(() => {
		document.body.classList.add('bg-white');
		return () => {
			document.body.classList.remove('bg-white');
		};
	}, []);

	const [isMobile] = useState(CheckIfMobileOrTablet);

	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const error = urlParams.get('error');
	const resetPassword = urlParams.get('reset_password');

	const personalicedWidth = !resetPassword ? '50%' : '100%';

	const [errorName, setErrorName] = useState(error);
	//localStorage.setItem('lowQuality', (errorName === 'contextLost') + '');
	return (
		<>
			<div
				className='d-flex flex-column flex-column-fluid position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed custom-background'
				style={{
					width: personalicedWidth,
					backgroundImage: `url('/media/background/cMonzon_fondo.png')`,
				}}
			>
				<img
					alt='Logo'
					src={toAbsoluteUrl('/media/icons/castillo_monzon/monzonTemplario.png')}
					className='custom-corner-logo'
				/>
				<div className='logomonzon'>
					<img
						alt='Logo'
						src={toAbsoluteUrl('/media/icons/castillo_monzon/Logos-AytoMonzon.png')}
					/>
					<div className='w-lg-500px rounded mx-auto'>
						{resetPassword ? (
							ChangePassword()
						) : (
							<Switch>
								<Route path='/auth/login' component={Login} />
								<Redirect from='/auth' exact={true} to='/auth/login' />
								<Redirect to='/auth/login' />
							</Switch>
						)}
					</div>
				</div>
			</div>
			{!resetPassword ? (
				<div className='instructions-custom-wrapper'>
					<Instructions></Instructions>
				</div>
			) : (
				''
			)}
			{errorName === 'alreadyLogged' ? (
				<div className='login-popup-background'>
					<div className='login-popup'>
						<div className='message'>
							Ya tienes una <b>sesión abierta</b> en este ordenador
						</div>
						<button className='accept-button' onClick={(e) => setErrorName('')}>
							Aceptar
						</button>
					</div>
				</div>
			) : (
				''
			)}
			{errorName === 'contextLost' ? (
				<div className='login-popup-background'>
					<div className='login-popup'>
						<div className='message'>
							Ha habido un problema con el navegador, asegurese de que su equipo cumple los
							<b> requisitos mínimos</b> (ver en{' '}
							<b>Preguntas Frequentes -{'>'} Especificaciones de Equipo</b>)
						</div>
						<button className='accept-button' onClick={(e) => setErrorName('')}>
							Aceptar
						</button>
					</div>
				</div>
			) : (
				''
			)}
			{isMobile ? (
				<div className='login-popup-background'>
					<div className='login-popup'>
						<div className='message'>Por favor, acceda desde un ordenador</div>
					</div>
				</div>
			) : (
				''
			)}
		</>
	);
}
