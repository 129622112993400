import {useState} from 'react';

function Instructions() {
	const [currentSectionIndex, setCurrentSectionIndex] = useState(0);

	let sections = [
		{
			title: 'ESPECIFICACIONES DE EQUIPO',
			content: (
				<div>
					<p>
						Esta aplicación web está diseñada para ser utilizada desde un equipo de{' '}
						<b>escritorio PC/Mac</b> y con el navegador web <b>Google Chrome</b>. Actualmente la
						aplicación no está disponible en smartphones/tablets.<br></br>Los{' '}
						<b>requisitos mínimos</b> del equipo son:<br></br>
					</p>
					<ul>
						<li>CPU 2Ghz</li>
						<li>Memoria RAM 4GB</li>
						<li>
							GPU (tarjeta gráfica) Compatible OpenGL® 3.2
							<ul>
								<li>Con soporte GLSL 1.40</li>
								<li>512MB de memoria dedicada (VRAM)</li>
							</ul>
						</li>
						<li>Tarjeta de sonido Compatible con DirectSound™</li>
					</ul>
				</div>
			),
		},
		{
			title: 'MICRÓFONO Y AUDIO',
			content: (
				<p>
					Para una experiencia completa y poder interactuar con el resto de usuarios; usted deberá
					tener auriculares y micrófono en su equipo. La primera vez que acceda a la plataforma, su
					navegador le pedirá permisos para acceder a su micrófono.{' '}
					<u>Deberá aceptar esta solicitud para poder hablar con el resto de usuarios.</u>
					<br></br>
					En caso de no ver la solicitud, es posible que su navegador haya bloqueado la petición; en
					tal caso deberá ir a <b>Preferencias{'>'}Seguridad y privacidad</b> y en la sección{' '}
					<b>
						permisos{'>'}
						micrófono
					</b>{' '}
					marcar la selección:{' '}
					<b>“Los sitios pueden solicitar permiso para utilizar el micrófono”</b>.{' '}
				</p>
			),
		},
		{
			title: 'NO PUEDO ACCEDER A LA PLATAFORMA',
			content: (
				<p>
					En caso de no poder acceder a la plataforma asegúrese de: <br></br>
					<ol>
						<li>
							Utilizar <b>Google Chrome</b> como navegador
						</li>
						<li>
							Tener una <b>conexión a internet rápida y estable</b> (preferentemente conectada por
							cable)
						</li>
						<li>
							Tener su <b>mail dado de alta</b> en nuestra base de datos (en caso de no estarlo
							contacte con su responsable o a{' '}
							<a href='mailto: soporte@deusens.com'>soporte@deusens.com</a>)
						</li>
						<li>
							Introducir sus <b>credenciales</b> correctamente: mail y contraseña.
						</li>
					</ol>{' '}
				</p>
			),
		},
		{
			title: 'CAMBIO DE CONTRASEÑA',
			content: (
				<p>
					<b>Se le suministrará una contraseña genérica</b> mediante algún medio de comunicación
					interno de la compañía; sin embargo, por cuestiones de seguridad, la primera vez que usted
					acceda,
					<b>deberá cambiar dicha contraseña e introducir la suya propia</b>. El sistema detectará
					automáticamente que es la primera vez que usted intenta acceder y le notificará como hacer
					el cambio.<br></br> En caso de que haya olvidado su contraseña, seleccione la opción de{' '}
					<b>
						restablecer contraseña. Una vez lo pulse recibirá un e-mail con un enlace. Pulse el
						enlace y este le llevará a la pagina de cambio de contraseña.
					</b>
				</p>
			),
		},
		{
			title: 'CRITERIOS DE CONTRASEÑA',
			content: (
				<p>
					Deberá introducir una contraseña de{' '}
					<b>
						mínimo 10 caracteres que contenga mínimo una letra mayúscula, una minúscula y un número
						o carácter especial.
					</b>{' '}
					<br></br>
					Si desea cambiar su contraseña, no podrá volver a hacer uso de una contraseña antigua,
					deberá introducir una completamente nueva.
				</p>
			),
		},
		{
			title: 'LA PLATAFORMA VA MUY LENTA',
			content: (
				<p>
					Esto puede producirse por diferentes factores:{' '}
					<ul>
						<li>
							<b>Conexión débil, lenta o inestable</b>: trate de conectarse por cable y tener acceso
							a una red de alta velocidad.{' '}
						</li>
						<li>
							<b>Su equipo no reúne los requisitos mínimos</b>: (ver requisitos){' '}
						</li>
						<li>
							<b>Está tratando de acceder desde un dispositivo no compatible</b>: actualmente la
							plataforma no es accesible con smartphones, tablets…{' '}
						</li>
						<li>
							<b>Está usando un navegador no compatible</b>: Esta plataforma está diseñada para ser
							utilizada en Google Chrome.{' '}
							<a href='https://www.google.com/chrome/'>Descargar aquí</a>{' '}
						</li>
						<li>
							<b>Tiene demasiadas aplicaciones/programas/pestañas abiertas</b>: esta aplicación
							consume una cantidad elevada de memoria RAM. Por esta razón, es importante que cierre
							otras aplicaciones y programas para tener disponible la mayor cantidad de memoria RAM
							posible.
						</li>
					</ul>{' '}
				</p>
			),
		},
		{
			title: 'LA PLATAFORMA SE HA BLOQUEADO',
			content: (
				<p>
					En caso de que la plataforma se quede congelada, recargue la página web y vuelva a
					acceder. La causa de experimentar este tipo de problema puede deberse a diversos motivos:
					<ul>
						<li>
							<b>Conexión débil, lenta o inestable</b>: trate de conectarse por cable y tener acceso
							a una red de alta velocidad.{' '}
						</li>

						<li>
							<b>Tiene demasiadas aplicaciones/programas/pestañas abiertas</b>: esta aplicación
							consume una cantidad elevada de memoria RAM. Por esta razón, es importante que cierre
							otras aplicaciones y programas para tener disponible la mayor cantidad de memoria RAM
							posible.{' '}
						</li>
					</ul>
				</p>
			),
		},
		{
			title: 'DESPLAZAMIENTO Y CÁMARA',
			content: (
				<p>
					Para desplazarse por el entorno, simplemente <b>posicione su ratón en el suelo</b> y un
					icono de huellas azules resaltará el lugar al que va a desplazarse; haga clic izquierdo y
					su avatar se desplazará automáticamente al lugar señalado <br></br>
					Si lo prefiere, <b>
						también puede utilizar las teclas W,A,S,D o las flechas del teclado
					</b>{' '}
					para un control más preciso de su avatar. <br></br>
					Con la intención de que usted pueda tener un mejor control de su visión dentro de la
					plataforma,{' '}
					<b>puede controlar la posición de la cámara y rotarla alrededor de su avatar</b>. Para
					hacerlo, tan solo tiene que <b>mantener pulsado el clic izquierdo y arrastrar el ratón</b>
					en la dirección opuesta a donde quiere rotar la cámara. <br></br>
					También puede <b>acercar o alejar la cámara</b> utilizando la rueda de su ratón.
				</p>
			),
		},
		{
			title: 'CONVERSACIÓN ENTRE AVATARES',
			content: (
				<p>
					Para poder <b>relacionarse con otros usuarios</b> dentro de la plataforma, simplemente
					tendrá que
					<b>acercarse al usuario con el que quiere interactuar y hablar de forma natural</b>.{' '}
					<br></br>
					La plataforma incorpora un sistema de audio por proximidad, lo que significa que{' '}
					<b>para hablar con el resto de usuarios debe encontrarse cerca de su avatar</b> (dentro de
					un radio de 1.5 metros). Dentro de este radio, pueden conversar tantos usuarios como se
					desee, pero recuerde:{' '}
					<b>si usted se aleja de estos usuarios dejará de escucharlos y ellos a usted</b>. ¡igual
					que en la vida real! <br></br>
					Asegúrese, también, de que su micrófono y auriculares funcionan y no se encuentra
					silenciado. Para ello <b>fíjese en el icono inferior con forma de micrófono</b> de su
					barra de herramientas. Si el micrófono se ilumina en verde, quiere decir que está activo y
					otros usuarios le pueden escuchar. Si el icono de micrófono se encuentra tachado y en
					rojo, significa que está inactivo y usted está silenciado.{' '}
					<b>Para cambiar el estado del micrófono, simplemente haga clic en dicho icono</b> y podrá
					comprobar como este cambia entre activo e inactivo. <br></br>
					Si experimenta cualquier problema, <b>consulte el apartado de micrófono y audio</b>.
				</p>
			),
		},
	];

	return (
		<div className='instructions-custom'>
			<div className='instructions-content'>
				<div className='instructions-main'>
					<div className='title'>Preguntas Frequentes</div>
					<div className='content'>
						Bienvenido a la visita virtual del Castillo de Monzón. Si es su primera experiencia
						virtual, le pedimos que por favor se familiarice con la siguiente guía.<br></br> En este
						entorno usted estará representado por un avatar virtual que le permitirá moverse por un
						entorno 3D, hablar utilizando su propia voz con otros usuarios, asistir a ponencias y
						presentaciones; así como realizar actividades de networking.{' '}
					</div>
				</div>

				<div className='separator-bar'></div>

				<div className='instructions-sections'>
					{sections.map((item, index) => (
						<div className='section' key={index}>
							<button className='section-button' onClick={(e) => setCurrentSectionIndex(index)}>
								{currentSectionIndex === index ? (
									<img
										className='section-button-image'
										src='/media/icons/castillo_monzon/mas.png'
										alt=''
									></img>
								) : (
									<img
										className='section-button-image'
										src='/media/icons/castillo_monzon/menos.png'
										alt=''
									></img>
								)}

								<div className='section-button-text'>{item.title}</div>
							</button>
							{currentSectionIndex === index ? <div className='content'>{item.content}</div> : ''}
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

export {Instructions};
