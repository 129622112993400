import React from 'react';
import {Content} from './components/Content';
import {PageDataProvider, useLayout} from './core';
import clsx from 'clsx';

const MasterLayout: React.FC = ({children}) => {
	const {classes} = useLayout();
	return (
		<PageDataProvider>
			<div className='page d-flex flex-row flex-column-fluid'>
				<div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
					<div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
						<div
							className={clsx(
								'd-flex flex-column-fluid align-items-start',
								classes.contentContainer.join(' ')
							)}
							id='kt_post'
						>
							<Content>{children}</Content>
						</div>
					</div>
				</div>
			</div>
		</PageDataProvider>
	);
};

export {MasterLayout};
