import {useState} from 'react';
import {DataManager} from '../../Socket/DataManager';

function UsersTable(args: any) {
	let userEntries: {name: any; surname: any; position: any; description: any; location: any}[] = [];

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [usersInRoom, setUsersInRoom] = useState(false);
	args.globalManager.userChangeRoom = () => {
		setUsersInRoom(!usersInRoom);
	};

	let rooms = Object.keys(args.globalManager.hud.playerList);

	DataManager.Instance().users.forEach((user: any) => {
		rooms.forEach((room: any) => {
			args.globalManager.hud.playerList[room].forEach((userInRoom: any) => {
				if (user.email === userInRoom) {
					userEntries.push({
						name: user.name,
						surname: user.surname,
						position: user.position,
						description: user.description,
						location: room,
					});
				}
			});
		});
		userEntries.sort((a, b) =>
			a.name > b.name
				? 1
				: b.name > a.name
				? -1
				: a.surname > b.surname
				? 1
				: b.surname > a.surname
				? -1
				: a.position > b.position
				? 1
				: b.position > a.position
				? -1
				: 0
		);
	});

	let [roomFilter, setRoomFilter] = useState<boolean>(false);
	let [filter, setFilter] = useState<string>('');
	aplyFilter();

	const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (e.keyCode === 13 && e.shiftKey === false) {
			e.preventDefault();
			setFilter(filter);
		}
	};

	function focusOnSearchField() {
		let chatField = document.getElementById('chat-field');
		if (chatField) chatField.focus();
	}

	function aplyFilter() {
		let mFilter = filter.trim();
		mFilter.toLowerCase();
		if (mFilter === '') {
			if (roomFilter) {
				userEntries.sort((a, b) =>
					a.location > b.location
						? 1
						: b.location > a.location
						? -1
						: a.name > b.name
						? 1
						: b.name > a.name
						? -1
						: a.surname > b.surname
						? 1
						: b.surname > a.surname
						? -1
						: a.position > b.position
						? 1
						: b.position > a.position
						? -1
						: 0
				);
			}
			return;
		}

		userEntries = [];

		DataManager.Instance().users.forEach((user: any) => {
			rooms.forEach((room: any) => {
				args.globalManager.hud.playerList[room].forEach((userInRoom: any) => {
					if (
						user.email === userInRoom &&
						(user.surname.toLowerCase().indexOf(mFilter) >= 0 ||
							[user.position, ' ', user.name, ' ', user.surname]
								.join('')
								.toLowerCase()
								.indexOf(mFilter) >= 0 ||
							[user.name, ' ', user.surname].join('').toLowerCase().indexOf(mFilter) >= 0)
					) {
						userEntries.push({
							name: user.name,
							surname: user.surname,
							position: user.position,
							description: user.description,
							location: room,
						});
					}
				});
			});
		});
		if (roomFilter) {
			userEntries.sort((a, b) =>
				a.location > b.location
					? 1
					: b.location > a.location
					? -1
					: a.name > b.name
					? 1
					: b.name > a.name
					? -1
					: a.surname > b.surname
					? 1
					: b.surname > a.surname
					? -1
					: a.position > b.position
					? 1
					: b.position > a.position
					? -1
					: 0
			);
		} else {
			userEntries.sort((a, b) =>
				a.name > b.name
					? 1
					: b.name > a.name
					? -1
					: a.surname > b.surname
					? 1
					: b.surname > a.surname
					? -1
					: a.position > b.position
					? 1
					: b.position > a.position
					? -1
					: 0
			);
		}
		focusOnSearchField();
	}

	args.focusAfterAnim();

	return (
		<>
			<div className='popup-panel-title'>Usuarios activos</div>
			<div className='input-searchgroup'>
				<div className='input-searcharea-wrapper'>
					<textarea
						className='input-searcharea'
						id='chat-field'
						onChange={(e) => setFilter(e.target.value)}
						onKeyDown={onEnterPress}
						placeholder='Filtrar lista...'
						rows={1}
						value={filter}
						onClick={focusOnSearchField}
					/>
				</div>

				<div className='new-checkbox-field'>
					<input
						className='form-check-input'
						type='checkbox'
						id='room-filter'
						onClick={() => setRoomFilter(!roomFilter)}
					/>
					<label className='form-check-label' htmlFor='terms-and-conditions'>
						{''}Ordenar por sala{''}
					</label>
				</div>
			</div>
			<table className='user-table'>
				<thead>
					<tr>
						<th>Nombre y cargo</th>
						<th>Descripción personal</th>
						<th>Ubicación</th>
					</tr>
				</thead>
				<tbody>
					{userEntries.map((item, index) => (
						<tr key={index} className='user-row'>
							<td className='info-cell'>
								<img src='/media/character-creator/man/hairs/beard4.png' alt=''></img>
								<div className='text-section'>
									<div className='name-and-surname'>{item.name + ' ' + item.surname}</div>
									<div className='position'>{item.position}</div>
								</div>
							</td>
							<td>{item.description}</td>
							<td className='location'>{item.location}</td>
						</tr>
					))}
				</tbody>
			</table>
			<button className='close-button' onClick={args.exitAudioOptions}>
				<img src='/media/icons/castillo_monzon/Cerrar.png' alt='' />
			</button>
		</>
	);
}

export {UsersTable};
