import {GLTFLoader} from './libs/GLTFLoader.js';
import {RGBELoader} from './libs/RGBELoader.js';
import {FontLoader} from './libs/FontLoader.js';
import {DRACOLoader} from './libs/DRACOLoader.js';
import {FBXLoader} from './libs/FBXLoader.js';
import * as THREE from './3D/three.module.js';
import * as Utils from './utils.js';

export class AsyncLoader {
	constructor() {
		this.loader = new GLTFLoader();
		this.FBXLoader = new FBXLoader();
		this.rgbeLoader = new RGBELoader().setDataType(THREE.UnsignedByteType);
		this.fontLoader = new FontLoader();
		this.dracoLoader = new DRACOLoader();
		this.dracoLoader.setDecoderConfig({type: 'js'});
		this.dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
		this.loader.setDRACOLoader(this.dracoLoader);
		this.font = null;
		this.femaleModel = null;
		this.maleModel = null;
	}

	static Instance() {
		if (AsyncLoader.instance === undefined) {
			AsyncLoader.instance = new AsyncLoader();
		}
		return AsyncLoader.instance;
	}

	static async LoadGLBModelAsync(path) {
		const gltf = await AsyncLoader.Instance().modelLoader(path);
		return gltf;
	}

	static async LoadFBXModelAsync(path) {
		const model = await AsyncLoader.Instance().loadFbx(path);
		return model;
	}

	static async GetPlayerModel(isFemale) {
		if (isFemale) {
			if (this.femaleModel == null) {
				this.femaleModel = await AsyncLoader.LoadGLBModelAsync('../assets/models/Female.glb');
			}
			return this.femaleModel;
		} else {
			if (this.maleModel == null) {
				this.maleModel = await AsyncLoader.LoadGLBModelAsync('../assets/models/Male.glb');
			}
			return this.maleModel;
		}
	}

	static async GetCollectableModel() {
		if (this.collectable == null) {
			this.collectable = await AsyncLoader.LoadGLBModelAsync('../assets/models/coleccionable.glb');
		}

		return Utils.clone(this.collectable.scene);
	}

	loadFbx(url) {
		return new Promise((resolve, reject) => {
			this.FBXLoader.load(url, (data) => resolve(data), null, reject);
		});
	}

	modelLoader(url) {
		return new Promise((resolve, reject) => {
			this.loader.load(url, (data) => resolve(data), null, reject);
		});
	}

	static async LoadEnvMapAsync(path, renderer) {
		const envMap = await AsyncLoader.Instance().loadEnvMap(path, renderer);
		return envMap;
	}

	loadEnvMap(url, renderer) {
		const pmremGenerator = new THREE.PMREMGenerator(renderer);
		pmremGenerator.compileEquirectangularShader();
		return new Promise((resolve, reject) => {
			this.rgbeLoader.load(
				url,
				(data) => {
					const envMap = pmremGenerator.fromEquirectangular(data).texture;
					data.dispose();
					pmremGenerator.dispose();
					resolve(envMap);
				},
				null,
				reject
			);
		});
	}

	async GetName(text, size) {
		if (this.font == null) {
			this.font = await this.loadFont();
		}
		return this.font.generateShapes(text, size);
	}

	async loadFont() {
		return new Promise((resolve, reject) => {
			this.fontLoader.load(
				'../assets/fonts/poppins-bold-normal-700.json',
				(font) => {
					resolve(font);
				},
				null,
				reject
			);
		});
	}
}
