function NotificationPopup(args: any) {
	return (
		<div className='login-popup-background'>
			<div className='login-popup'>
				<div className='message'>{args.message}</div>
				<button className='accept-button' onClick={(e) => args.colseFunc()}>
					Aceptar
				</button>
			</div>
		</div>
	);
}

export {NotificationPopup};
