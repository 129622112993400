/* eslint-disable jsx-a11y/alt-text */
import {FC, useState} from 'react';
import {CharacterConfigurator} from './CharacterConfigurator';
import {ChatAgora} from './ChatAgora';
import {OptionsPopup} from './OptionsPopup';
import {UserModel} from '../auth/models/UserModel';

import './socket-modules.scss';

import {PostQuestion} from '../../Socket/ApolloUtils.js';
import {toAbsoluteUrl} from '../../../_metronic/helpers';
import {DataManager} from '../../Socket/DataManager.js';
import {RoomInfo} from './RoomInfoPopup';

interface GlobalManagerRef {
	globalManager: any;
	user: any;
}
interface MessageModel {
	user: UserModel;
	type: 'in' | 'out';
	text: string;
	time: string;
	template?: boolean;
}

const HUD: FC<GlobalManagerRef> = ({globalManager, user}): JSX.Element => {
	//
	const [showCharacterConfigurator, setShowCharacterConfigurator] = useState(false);
	globalManager.setShowCharacterConfigurator = setShowCharacterConfigurator;
	//
	const [showMainHud, setShowMainHud] = useState(false);
	globalManager.setShowMainHud = setShowMainHud;
	//
	const [showEmotes, setShowEmotes] = useState(false);
	globalManager.setShowEmotes = setShowEmotes;
	//
	const [showAgoraHud, setShowAgoraHud] = useState(false);
	globalManager.setShowAgoraHud = setShowAgoraHud;
	//
	const [showLoadingScreen, setShowLoadingScreen] = useState(false);
	globalManager.ShowLoadingScreen = (assetsToLoad: number) => {
		globalManager.assetsToLoad = assetsToLoad;
		globalManager.loadedAssets = 0;
		setLoadAmount('0%');
		switchShowLoadingScreen(true);
	};
	globalManager.showLoadingScreen = showLoadingScreen;
	const [loadAmount, setLoadAmount] = useState('0%');
	globalManager.assetLoaded = () => {
		globalManager.loadedAssets++;
		setLoadAmount((globalManager.loadedAssets / globalManager.assetsToLoad) * 100 + '%');
		if (globalManager.loadedAssets === globalManager.assetsToLoad) {
			setTimeout(() => setShowLoadingScreen(false), 500);
		}
	};

	//
	const [showPopupMenu, setShowPopupMenu] = useState(false);
	globalManager.setShowPopupMenu = setShowPopupMenu;

	//
	const [currentPopupMenu, setCurrentPopupMenu] = useState(-1);
	//globalManager.setShowPopupMenu = setShowPopupMenu;

	const [objetive, setObjetive] = useState(null);

	//
	const [showChatBackground, setShowChatBackground] = useState(false);
	globalManager.setShowChatBackground = setShowChatBackground;

	const [currentPills, setCurrentPills] = useState(0);
	globalManager.setCurrentPills = setCurrentPills;
	globalManager.currentPills = currentPills;

	let [questionMessage, setQuestionMessage] = useState('');

	const [userRoom, setUserRoom] = useState('Hall');
	globalManager.setUserRoom = setUserRoom;

	const [loadingImageIndex, setLoadingImageIndex] = useState(0);

	const [showRoomButtons, setShowRoomButtons] = useState(false);

	const [outgoingStreamEnabled, setOutgoingStreamEnabled] = useState(true);
	globalManager.setOutgoingStreamEnabled = setOutgoingStreamEnabled;

	const [volumeTresholdSurpassed, setVolumeTresholdSurpassed] = useState(false);

	const [showAgoraChat, setShowAgoraChat] = useState(false);

	const [showExitHall, setShowExitHall] = useState(false);
	globalManager.setShowExitHall = setShowExitHall;

	const [notificationMessage, setNotificationMessage] = useState<string>('');
	globalManager.setNotificationMessage = (message: string) => {
		showPopupMenuFromButton(9);
		setNotificationMessage(message);
	};

	let bufferMessages = globalManager.currentMessages;
	if (!bufferMessages) {
		bufferMessages = [];
		globalManager.currentMessages = bufferMessages;
	}
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [messages, setMessages] = useState<MessageModel[]>(bufferMessages);

	let placeholderText = (
		<>
			<p>
				<b>¿Está tardando demasiado en cargar?</b>
			</p>
			<p>Esto puede producirse por diferentes factores: </p>
			<ul>
				<li>
					<b>Conexión débil, lenta o inestable</b>
				</li>
				<li>
					<b>Su equipo no reúne los requisitos mínimos</b>
				</li>
				<li>
					<b>Está tratando de acceder desde un dispositivo no compatible</b>
				</li>
				<li>
					<b>Está usando un navegador no compatible</b>
				</li>
				<li>
					<b>Tiene demasiadas aplicaciones/programas/pestañas abiertas</b>
				</li>
			</ul>{' '}
		</>
	);

	let loadAmountToShow = loadAmount.includes('.') ? loadAmount.split('.')[0] + '%' : loadAmount;

	function switchShowLoadingScreen(show: any) {
		setShowLoadingScreen(show);
	}

	function showPopupMenuFromButton(index: number, item: any = null) {
		if (index === 7) {
			globalManager.newMessages = 0;
		}
		globalManager.hud.lockClick();
		setCurrentPopupMenu(index);
		if(item) setObjetive(item);
		setShowPopupMenu(true);
	}

	globalManager.showPopupMenuFromButton = showPopupMenuFromButton;

	function clickOnChatBackground() {
		globalManager.hud.clickOnHud();
		document.getElementById('kt_drawer_chat')?.classList.remove('drawer-on');
		setShowChatBackground(false);
	}

	const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (e.keyCode === 13 && e.shiftKey === false) {
			e.preventDefault();
			sendQuestion();
		}
	};

	function sendQuestion() {
		globalManager.hud.loseChatFocus();
		questionMessage = questionMessage.trim();
		if (questionMessage === '') return;

		let datetime = new Date().toISOString().slice(0, 19).replace('T', ' ');
		PostQuestion(globalManager.user.email, questionMessage, datetime);
		setQuestionMessage('');
	}

	function updateLoadingImage() {
		if (showLoadingScreen) {
			let nextIndex = loadingImageIndex + 1;
			if (nextIndex > 190) nextIndex = 0;
			setLoadingImageIndex(nextIndex);
		}
	}
	globalManager.updateLoadingImage = updateLoadingImage;

	function getLoadingImage() {
		let imageAmount = 8;
		let imageToShow = Math.floor((parseInt(loadAmount.slice(0, -1)) / 100) * imageAmount);
		return '/media/loading/Loading_' + imageToShow + '.png';
	}

	function focusQuestionField() {
		let questionsField = document.getElementById('questions-field');
		if (questionsField) questionsField.focus();
	}

	function silenceSelf() {
		globalManager.hud.clickOnHud();
		//
		if (outgoingStreamEnabled) {
			globalManager.streamControl.disableOutgoingStream();
			setOutgoingStreamEnabled(false);
			globalManager.scene.sendMute(true);
		} else {
			globalManager.streamControl.enableOutgoingStream();
			setOutgoingStreamEnabled(true);
			globalManager.scene.sendMute(false);
		}
	}
	globalManager.silenceSelf = silenceSelf;

	function updateVolume(volume: number) {
		//
		if (!showMainHud) return;
		//
		let volumeTreshold = 50;
		if (volume > volumeTreshold && !volumeTresholdSurpassed) {
			setVolumeTresholdSurpassed(true);
		} else if (volume < volumeTreshold && volumeTresholdSurpassed) {
			setVolumeTresholdSurpassed(false);
		}
	}
	globalManager.updateVolume = updateVolume;

	function getMicroIcon() {
		if (outgoingStreamEnabled) {
			if (volumeTresholdSurpassed) return '/media/icons/castillo_monzon/MicroHablando.png';
			else return '/media/icons/castillo_monzon/microphone2.png';
		} else {
			return '/media/icons/castillo_monzon/MicroSilenciado.png';
		}
	}

	function switchAgoraChat() {
		try {
			var items: any = document.getElementsByClassName('popup-panel-chat');
			items[0].style.animation = 'popupRightExit 0.5s';
			setTimeout(() => {
				setShowAgoraChat(false);
			}, 500);
			focus = false;
		} catch {
			setShowAgoraChat(true);
			setTimeout(() => {
				focus = true;
				focusOnChatField();
			}, 500);
		}
	}

	function quitAgora() {
		//cerrarchat
		setShowAgoraChat(false);
		globalManager.scene.changeRoom('Hall');
	}

	function receiveMessage(incomingMessage: any) {
		let incomingMessageGood = incomingMessage.newMessage as MessageModel;
		const cg = document.getElementById('chatScroll');
		let isAtBottom = false;
		if (cg) {
			isAtBottom = cg.scrollHeight - cg.offsetHeight - cg.scrollTop === 0;
		}
		//
		if (incomingMessageGood.user.email !== globalManager.user.email) {
			//
			incomingMessageGood.type = 'in';
			globalManager.currentMessages.push(incomingMessageGood);
			//
			if (!(showAgoraChat || (showPopupMenu && currentPopupMenu === 7))) {
				if (!globalManager.newMessages) globalManager.newMessages = 1;
				else globalManager.newMessages++;
			}
			//
			setMessages(JSON.parse(JSON.stringify(bufferMessages)));
			if (isAtBottom && cg) cg.scrollTop = cg.scrollHeight;
		}
	}

	var focus = true;

	function focusOnChatField() {
		let chatField = document.getElementById('chat-field');
		if (chatField && focus) {
			chatField.focus();
			focus = true;
		}
	}

	const [allowEmoji, SetAllowEmoji] = useState(true);

	function emojiAction(index: number) {
		globalManager.hud.clickOnHud();
		if (!allowEmoji) return;
		globalManager.hud.clickOnHud();
		globalManager.socket.emit('agoraAction', {action: index, room: globalManager.scene.room.name});
		globalManager.scene.showMyFeedback(index);
		SetAllowEmoji(false);
		setTimeout(() => {
			SetAllowEmoji(true);
		}, 1000);
	}

	function teleport(room: string){
		globalManager.roomToGo = room;
		showPopupMenuFromButton(5);
	}

	function goToHall() {
		globalManager.scene.changeRoom('Hall');
	}

	globalManager.receiveMessage = receiveMessage;

	return (
		<div>
			{showMainHud ? (
				<>
					<div className='footer-button-group' style={{backgroundImage: `url('/media/icons/castillo_monzon/cMonzon_botonesBack.png')`}}>
						<button className='hud-button-transparent' tabIndex={-1}>
							<img
								src='/media/icons/castillo_monzon/smiley_W.png'
								onClick={(e) => {
									globalManager.hud.clickOnHud();
									setShowEmotes(!showEmotes);
								}}
							></img>
						</button>
						{showEmotes ? (
							<div className='hud-emotes'>
								<button
									className='heart'
									onClick={() => emojiAction(0)}
									disabled={!allowEmoji}
									tabIndex={-1}
								>
									<img src='/media/icons/castillo_monzon/Corazon.png' />
								</button>
								<button
									className='clap'
									onClick={() => emojiAction(2)}
									disabled={!allowEmoji}
									tabIndex={-1}
								>
									<img src='/media/icons/castillo_monzon/aplausos.png' />
								</button>
								<button
									className='thumb'
									onClick={() => emojiAction(1)}
									disabled={!allowEmoji}
									tabIndex={-1}
								>
									<img src='/media/icons/castillo_monzon/like.png' />
								</button>
							</div>
						) : (
							''
						)}
						<button className='hud-button-transparent' onClick={silenceSelf} tabIndex={-1}>
							<img src={getMicroIcon()} />
						</button>
						<button className='hud-button-transparent' tabIndex={-1}>
							<img
								src='/media/icons/castillo_monzon/chat.png'
								onClick={(e) => showPopupMenuFromButton(7)}
							/>
							{globalManager.newMessages && globalManager.newMessages > 0 ? (
								<div className='message-alert'>{globalManager.newMessages}</div>
							) : (
								''
							)}
						</button>
						{showExitHall ? (
							<button className='hud-button-transparent'>
								<img
									src='/media/icons/castillo_monzon/logout-4.png'
									onClick={(e) => {
										globalManager.hud.clickOnHud();
										goToHall();
									}}
								></img>
							</button>
						) : (
							''
						)}
					</div>
					<div className='left-button-group'>
						<button className='hud-button-transparent' tabIndex={-1}>
							<img
								src='/media/icons/castillo_monzon/gear.png'
								onClick={(e) => showPopupMenuFromButton(0)}
							></img>
						</button>
						<button className='hud-button-transparent' tabIndex={-1}>
							<img
								src='/media/icons/castillo_monzon/info.png'
								onClick={(e) => showPopupMenuFromButton(4)}
							></img>
						</button>
						<button
							className='hud-button-transparent'
							onClick={(e) => showPopupMenuFromButton(8)}
							tabIndex={-1}
						>
							<img src='/media/icons/castillo_monzon/user.png'></img>
						</button>
					</div>
					<div className='right-top-button-group'>
						<button className='hud-button-transparent' tabIndex={-1} >
							<img
								src='/media/icons/castillo_monzon/location.png'
								onClick={(e) => setShowRoomButtons(!showRoomButtons)}
								>
							</img>
						</button>
					</div>
					{showRoomButtons ? (
						<div className='right-button-group'>			
							{DataManager.Instance().rooms.map((room: any) =>
								userRoom !== room.name ?(
									<button key={room.name} className='location-button' onClick={(e) => teleport(room.name)} tabIndex={-1} >
										<b>{room.name}</b>	
									</button>
								) : (
									''
								)
							)}
						</div>
					):(
						''
					)}
				</>
			) : (
				''
			)}
			{showAgoraHud ? (
				<>
					<div className='left-button-group' onClick={globalManager.hud.loseChatFocus}>
						<button className='hud-button-transparent' tabIndex={-1}>
							<img
								src='/media/icons/castillo_monzon/gear.png'
								onClick={(e) => showPopupMenuFromButton(0)}
							></img>
						</button>
						<button className='hud-button-transparent' tabIndex={-1}>
							<img
								src='/media/icons/castillo_monzon/info.png'
								onClick={(e) => showPopupMenuFromButton(4)}
							></img>
						</button>
						<button className='hud-button-transparent' tabIndex={-1}>
							<img
								src='/media/icons/castillo_monzon/user.png'
								onClick={(e) => showPopupMenuFromButton(8)}
							></img>
						</button>
					</div>
					<div className='agora-button-group'style={{backgroundImage: `url('/media/icon/BG_botIcons.png')`}}>
						<button className='hud-button-transparent' style={{marginRight: '1rem'}} tabIndex={-1}>
							<img
								height='40px'
								src='/media/icons/castillo_monzon/chat.png'
								onClick={switchAgoraChat}
							></img>
							{globalManager.newMessages && globalManager.newMessages > 0 ? (
								<div className='message-alert'>{globalManager.newMessages}</div>
							) : (
								''
							)}
						</button>
						<button className='hud-button-transparent' tabIndex={-1}>
							<img height='36px' src='/media/icons/castillo_monzon/logout-4.png' onClick={quitAgora}></img>
						</button>
					</div>
					<div className='question-group' onClick={globalManager.hud.clickOnHud}>
						<textarea
							id='questions-field'
							className='question-textarea'
							placeholder='Escribe tu pregunta...'
							onChange={(e) => setQuestionMessage(e.target.value)}
							onKeyDown={onEnterPress}
							value={questionMessage}
							onClick={focusQuestionField}
						/>
						<button className='question-button' onClick={sendQuestion} tabIndex={-1}>
							ENVIAR
						</button>
					</div>
					{showAgoraChat ? (
						<div className='popup-background-agora'>
							<div className='popup-panel-chat' onClick={globalManager.hud.clickOnHud}>
								<ChatAgora
									globalManager={globalManager}
									setShowAgoraChat={switchAgoraChat}
									focusAfterAnim={() => setTimeout(focusOnChatField, 500)}
								/>
							</div>
						</div>
					) : (
						''
					)}
				</>
			) : (
				''
			)}
			{showCharacterConfigurator ? (
				<CharacterConfigurator globalManager={globalManager} user={user} />
			) : (
				''
			)}
			{showLoadingScreen ? (
				<div id='loadingScreen' className='loading-screen'>
					<div className='logo-izquierda'>
						<img src={toAbsoluteUrl('/media/icons/castillo_monzon/monzonTemplario.png')}></img>
					</div>
					<div className='logo-derecha'>
						<img src={toAbsoluteUrl('/media/icons/castillo_monzon/Logos-AytoMonzon.png')}></img>
					</div>
					<div className='loading-screen-text'>
						<img className='loading-spinner' src={getLoadingImage()} />
						<div className='loading-text'>Cargando</div>
						<div className='loading-meter'>{loadAmountToShow}</div>
						<div className='loading-hint'>{placeholderText}</div>
					</div>
				</div>
			) : (
				''
			)}
			{showPopupMenu ? (
				<OptionsPopup
					globalManager={globalManager}
					currentPopupMenu={currentPopupMenu}
					notificationMessage={notificationMessage}
					objetive={objetive}
				/>
			) : (
				''
			)}
			{showChatBackground ? (
				<div className='popup-background' onClick={clickOnChatBackground} />
			) : (
				''
			)}
		</div>
	);
};

export {HUD};
