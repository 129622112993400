import {useState} from 'react';
import {useDispatch} from 'react-redux';
import * as Yup from 'yup';
import clsx from 'clsx';
import {Link} from 'react-router-dom';
import {useFormik} from 'formik';
import * as auth from '../redux/AuthRedux';

import {Login as ApolloLogin, PostReset} from '../../../Socket/ApolloUtils';

const loginSchema = Yup.object().shape({
	email: Yup.string()
		.email('Wrong email format')
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.required('Obligatorio'),
	password: Yup.string()
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.required('Obligatorio'),
});

let initialValues = {
	email: '',
	password: '',
	acceptTerms: false,
	privacyPolicy: false,
};

if (localStorage.getItem('email')) {
	initialValues = {
		email: localStorage.getItem('email') || '',
		password: localStorage.getItem('password') || '',
		acceptTerms: true,
		privacyPolicy: true,
	};
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const formik = useFormik({
		initialValues,
		validationSchema: loginSchema,
		onSubmit: (values, {setStatus, setSubmitting}) => {
			values.email = values.email.trim().toLocaleLowerCase();
			setLoading(true);
			setTimeout(() => {
				ApolloLogin(values.email, values.password)
					.then(({data}) => {
						setLoading(false);
						if (data && data.login.correct === 1) {
							localStorage.setItem('email', values.email);
							localStorage.setItem('password', values.password);
							localStorage.setItem('token', data.login.token);
							dispatch(auth.actions.fulfillUser(data.login.user));
						} else {
							setLoading(false);
							setSubmitting(false);
							setStatus({popUp: data.login.correct === -1, msg: data.login.message});
						}
					})
					.catch((error) => {
						console.log(error);
						setLoading(false);
						setSubmitting(false);
						setStatus({popUp: false, msg: 'Ha habido un error, inténtelo más tarde'});
					});
			}, 1000);
		},
	});

	const [showEmailResetError, SetShowEmailResetError] = useState(false);

	function ResetPwdClick(nuevo = false) {
		if (!formik.errors.email) {
			const email = (document.getElementById('emailInput') as HTMLInputElement).value;
			SendResetEmailPost(email, nuevo);
			formik.setStatus(null);
		} else {
			SetShowEmailResetError(true);
			console.log('Invalid email');
		}
	}

	async function SendResetEmailPost(email: string, nuevo = false) {
		setLoading(true);
		const result = await PostReset(email);
		formik.setStatus({popUp: false, msg: result.data.postReset.message});
		setLoading(false);
	}

	return (
		<>
			<form
				className='form w-100'
				onSubmit={formik.handleSubmit}
				noValidate
				id='kt_login_signin_form'
			>
				{formik.status ? (
					!formik.status.popUp ? (
						<div className='mb-lg-15 alert alert-danger'>
							<div className='alert-text font-weight-bold'>{formik.status.msg}</div>
						</div>
					) : !loading ? (
						<div className='login-popup-background'>
							<div className='login-popup'>
								<div className='message'>{formik.status.msg}</div>
								<button className='accept-button' onClick={() => ResetPwdClick(true)}>
									Reestablecer contraseña
								</button>
							</div>
						</div>
					) : (
						<></>
					)
				) : (
					<></>
				)}

				<div className='fv-row mb-10'>
					<label className='form-label fs-6 fw-bolder text-inverse custom-label'>Usuario</label>
					<input
						placeholder='Email'
						{...formik.getFieldProps('email')}
						className={clsx(
							'form-control form-control-lg form-control-solid',
							{'is-invalid': formik.touched.email && formik.errors.email},
							{
								'is-valid': formik.touched.email && !formik.errors.email,
							}
						)}
						type='email'
						name='email'
						autoComplete='off'
						id='emailInput'
					/>
					{formik.touched.email && formik.errors.email && (
						<div className='fv-plugins-message-container'>
							<span role='alert'>{formik.errors.email}</span>
						</div>
					)}
				</div>

				<div className='fv-row mb-10'>
					<div className='d-flex justify-content-between mt-n5'>
						<div className='d-flex flex-stack mb-2'>
							<label className='form-label fw-bolder text-inverse fs-6 mb-0 custom-label'>
								Contraseña
							</label>
						</div>
					</div>
					<input
						placeholder='Contraseña'
						type='password'
						autoComplete='off'
						{...formik.getFieldProps('password')}
						className={clsx(
							'form-control form-control-lg form-control-solid',
							{
								'is-invalid': formik.touched.password && formik.errors.password,
							},
							{
								'is-valid': formik.touched.password && !formik.errors.password,
							}
						)}
					/>
					{formik.touched.password && formik.errors.password && (
						<div className='fv-plugins-message-container'>
							<div className='fv-help-block'>
								<span role='alert'>{formik.errors.password}</span>
							</div>
						</div>
					)}
				</div>
				<div className='checkbox-group'>
					<div className='checkbox-field'>
						<label className='form-check-label' htmlFor='terms-and-conditions'>
							¿Ha olvidado su contraseña?{''}
							<Link to='#' className='ms-1 link-primary' onClick={() => ResetPwdClick()}>
								Restablecer contraseña
							</Link>{' '}
						</label>
					</div>
					<div className='checkbox-field'>
						<input
							className='form-check-input'
							type='checkbox'
							id='terms-and-conditions'
							defaultChecked={formik.values.acceptTerms}
							{...formik.getFieldProps('acceptTerms')}
						/>
						<label className='form-check-label' htmlFor='terms-and-conditions'>
							He leído y acepto los{''}
							<Link to='/auth/terms' className='ms-1 link-primary'>
								Términos y Condiciones
							</Link>{' '}
							de uso
						</label>
					</div>

					<div className='checkbox-field'>
						<input
							className='form-check-input'
							type='checkbox'
							id='privacy-policy'
							defaultChecked={formik.values.privacyPolicy}
							{...formik.getFieldProps('privacyPolicy')}
						/>
						<label className='form-check-label' htmlFor='privacy-policy'>
							He leído y acepto la{''}
							<Link to='/auth/terms' className='ms-1 link-primary'>
								Política de privacidad
							</Link>
						</label>
					</div>
				</div>

				<div className='text-center'>
					<button
						type='submit'
						id='kt_sign_in_submit'
						className='btn btn-lg btn-primary w-50 mb-5'
						disabled={
							formik.isSubmitting ||
							!formik.isValid ||
							!formik.values.acceptTerms ||
							!formik.values.privacyPolicy
						}
					>
						{loading ? (
							<span className='indicator-progress' style={{display: 'block'}}>
								Por favor, espere...
							</span>
						) : (
							<span className='indicator-label'>Acceder</span>
						)}
					</button>
				</div>
			</form>

			{showEmailResetError ? '' : ''}
		</>
	);
}
