function UserInfo(args: any) {
	let gmRef = args.globalManager;

	let userInfo = gmRef.hud.getUserInfo(gmRef.userToShowInPopup);

	return (
		<div className='user-info-panel'>
			{
				//<img className='user-image' src='/media/character-creator/man/hairs/beard4.png' alt='' />
			}
			<div className='text-section'>
				<div className='user-name'>{userInfo.name + ' ' + userInfo.surname}</div>
				<div className='user-position'>{userInfo.position}</div>
				<div className='user-description'>{userInfo.description}</div>
			</div>

			<button className='close-button' onClick={args.exitAudioOptions}>
				<img src='/media/icons/castillo_monzon/Cerrar.png' alt='' />
			</button>
		</div>
	);
}

export {UserInfo};
