import {useState} from 'react';
import * as Yup from 'yup';
import YupPassword from 'yup-password';
import clsx from 'clsx';
import {useFormik} from 'formik';
import {PostChangePwd} from '../../../Socket/ApolloUtils';

const initialValues = {
	password: '',
	repeatPassword: '',
};

YupPassword(Yup); // extend yup

const changePasswordSchema = Yup.object().shape({
	password: Yup.string()
		.min(10, 'Mínimo 3 carácteres')
		.max(50, 'Máximo 50 carácteres')
		.minLowercase(1, 'Mínimo 1 letra minúscula')
		.minUppercase(1, 'Mínimo una letra mayúscula')
		.minNumbers(1, 'Mínimo un número')
		.required('Obligatorio'),
	repeatPassword: Yup.string()
		.required('Obligatorio')
		.oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden'),
});
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const code = urlParams.get('code');
const email = urlParams.get('email');

export function ChangePassword() {
	const [loading, setLoading] = useState(false);
	const formik = useFormik({
		initialValues,
		validationSchema: changePasswordSchema,
		onSubmit: (values, {setStatus, setSubmitting}) => {
			values.password = values.password.trim();
			setLoading(true);
			PostChangePwd(email, code, values.password)
				.then(({data}) => {
					data = data.postChangePwd;
					setLoading(false);
					setSubmitting(false);
					if (data && data.success === 1) {
						setStatus({popUp: true, msg: 'Contraseña cambiada correctamente'});
					} else {
						setStatus({popUp: false, msg: data.message});
					}
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
					setSubmitting(false);
					setStatus({popUp: false, msg: 'Ha ocurrido un error, intentelo más tarde'});
				});
		},
	});

	return (
		<>
			<form
				className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
				noValidate
				id='kt_login_password_reset_form'
				onSubmit={formik.handleSubmit}
			>
				<div className='text-center mb-10'>
					<div className='resetPSW'>Restablecer contraseña</div>
				</div>

				{/* begin::Title */}
				{formik.status ? (
					!formik.status.popUp ? (
						<div className='mb-lg-15 alert alert-danger'>
							<div className='alert-text font-weight-bold'>{formik.status.msg}</div>
						</div>
					) : !loading ? (
						<div className='login-popup-background'>
							<div className='login-popup'>
								<div className='message'>{formik.status.msg}</div>
								<button
									className='accept-button'
									onClick={() => (window.location.href = window.location.href.split('?')[0])}
								>
									Volver
								</button>
							</div>
						</div>
					) : (
						<></>
					)
				) : (
					<></>
				)}
				{/* end::Title */}

				{/* begin::Form group */}
				<div className='fv-row mb-10'>
					<div className='d-flex justify-content-between mt-n5'>
						<div className='d-flex flex-stack mb-2'>
							<label className='form-label fw-bolder test-inverse fs-6 mb-0 custom-label'>
								Nueva contraseña
							</label>
						</div>
					</div>
					<input
						type='password'
						autoComplete='off'
						{...formik.getFieldProps('password')}
						className={clsx(
							'form-control form-control-lg form-control-solid',
							{
								'is-invalid': formik.touched.password && formik.errors.password,
							},
							{
								'is-valid': formik.touched.password && !formik.errors.password,
							}
						)}
					/>
					{formik.touched.password && formik.errors.password && (
						<div className='fv-plugins-message-container'>
							<div className='fv-help-block'>
								<span role='alert'>{formik.errors.password}</span>
							</div>
						</div>
					)}
				</div>
				<div className='fv-row mb-10'>
					<div className='d-flex justify-content-between mt-n5'>
						<div className='d-flex flex-stack mb-2'>
							<label className='form-label fw-bolder test-inverse fs-6 mb-0 custom-label'>
								Repita la nueva contraseña
							</label>
						</div>
					</div>
					<input
						type='password'
						autoComplete='off'
						{...formik.getFieldProps('repeatPassword')}
						className={clsx(
							'form-control form-control-lg form-control-solid',
							{
								'is-invalid': formik.touched.repeatPassword && formik.errors.repeatPassword,
							},
							{
								'is-valid': formik.touched.repeatPassword && !formik.errors.repeatPassword,
							}
						)}
					/>
					{formik.touched.repeatPassword && formik.errors.repeatPassword && (
						<div className='fv-plugins-message-container'>
							<div className='fv-help-block'>
								<span role='alert'>{formik.errors.repeatPassword}</span>
							</div>
						</div>
					)}
				</div>
				{/* end::Form group */}

				{/* begin::Form group */}
				<div className='d-flex flex-wrap justify-content-center pb-lg-0'>
					<button
						type='submit'
						id='kt_password_reset_submit'
						className='btn btn-lg btn-primary fw-bolder me-4'
						style={{marginBottom: '100px'}}
					>
						<span className='indicator-label'>Cambiar</span>
						{loading && (
							<span className='indicator-progress'>
								Por favor, espere...
								<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
							</span>
						)}
					</button>
				</div>
				{/* end::Form group */}
			</form>
		</>
	);
}
