import {useState} from 'react';
import {DataManager} from '../../Socket/DataManager';

function RoomInfo(args: any) {
	let gmRef = args.globalManager;
	let mColor, mText;

	if (getRoomOcupation() === getRoomCapacity() && gmRef.scene.room.multiple) {
		mColor = '#CD482C';
		mText = 'Sala llena';
	} else {
		mColor = '#000000';
		mText = 'Acceder';
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [usersInRoom, setUsersInRoom] = useState(false);
	gmRef.userChangeRoom = () => {
		setUsersInRoom(!usersInRoom);
	};

	const [isRefreshed, RefreshRoomInfo] = useState(false);
	gmRef.RefreshRoomInfo = () => {
		RefreshRoomInfo(!isRefreshed);
	};

	let userEntries: {name: any; surname: any}[] = [];

	// Si está vacia es que no hay usuarios
	if (gmRef.hud.playerList[gmRef.roomToGo]) {
		DataManager.Instance().users.forEach((user: any) => {
			gmRef.hud.playerList[gmRef.roomToGo].forEach((userInRoom: any) => {
				if (user.email === userInRoom) {
					userEntries.push({
						name: user.name,
						surname: user.surname,
					});
				}
			});
		});
	}

	function closeAndGoToRoom() {
		gmRef.scene.changeRoom(gmRef.roomToGo);
		args.exitAudioOptions();
	}

	function getRoomOcupation() {
		if (gmRef.hud.playerList[gmRef.roomToGo]) return gmRef.hud.playerList[gmRef.roomToGo].length;
		else return 0;
	}

	function getRoomCapacity() {
		let roomCapacity = 0;
		DataManager.Instance().rooms.forEach((item: any) => {
			if (gmRef.roomToGo === item.name) {
				roomCapacity = item.capacity;
			}
		});

		return roomCapacity;
	}

	function checkIfRoomIsFull() {
		//
		if (getRoomOcupation() === getRoomCapacity() && gmRef.scene.room.mulltiple) {
			return true;
		}
		//
		return false;
	}

	return (
		<div className='room-info-panel'>
			<div className='room-name'>{gmRef.roomToGo}</div>

			<div className='popup-panel-dot-separator'></div>

			<div className='room-ocupation'>
				Usuarios en la sala <b>{' ' + getRoomOcupation() + '/' + getRoomCapacity()}</b>
			</div>
			<button
				className='room-enter-button'
				onClick={closeAndGoToRoom}
				disabled={checkIfRoomIsFull()}
				style={{background: mColor}}
			>
				{' '}
				{mText}{' '}
			</button>

			<div className='popup-panel-dot-separator'></div>

			<div className='user-list-group'>
				{userEntries.map((item, index) => (
					<div className='user-element' key={index}>
						<div className='inner-group'>
							{
								//<img src='/media/character-creator/man/hairs/beard4.png' alt='' />
							}
							<div className='user-name'>{item.name + ' ' + item.surname}</div>
						</div>
					</div>
				))}
			</div>

			<button className='close-button' onClick={args.exitAudioOptions}>
				<img src='/media/icons/castillo_monzon/Cerrar.png' alt='' />
			</button>
		</div>
	);
}

export {RoomInfo};
