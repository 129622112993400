function CalendarTable(args: any) {
	let mockupAgendaEntries = [
		{
			hour: '16:15',
			title: 'Recepción',
			description: '-',
			location: 'Hall',
		},
		{
			hour: '16:35',
			title: 'Intro',
			description: 'Marta Palazuelos',
			location: 'Ágora',
		},
		{
			hour: '16:40',
			title: 'IPT Spravato TRD',
			description: 'Berta Herrera',
			location: 'Ágora',
		},
		{
			hour: '17:00',
			title: 'Actualización venta y actividad',
			description: 'Josito',
			location: 'Ágora',
		},
		{
			hour: '17:15',
			title: '¿Quién es el nuevo fichaje estrella de marketing?',
			description: 'Nerea',
			location: 'Ágora',
		},
		{
			hour: '17:20',
			title: 'Actualización MKT',
			description: 'Diana & Esteban',
			location: 'Ágora',
		},
		{
			hour: '17:35',
			title: 'Actualización NPP',
			description: 'Cris Pagés',
			location: 'Ágora',
		},
		{
			hour: '17:45',
			title: 'Actualización MedEd: Ask the Experto, Curso Residentes + VIVE',
			description: 'Blanca, Bea & Maite',
			location: 'Ágora',
		},
	];

	let agendaEntries = [];
	agendaEntries = mockupAgendaEntries;

	return (
		<>
			<div className='popup-panel-title'>Agenda</div>
			<table className='calendar-table'>
				<thead>
					<tr>
						<th>Hora</th>
						<th>Título</th>
						<th>Ponente</th>
						<th>Ubicación</th>
					</tr>
				</thead>

				{agendaEntries.map((item, index) => (
					<tbody key={index} className='calendar-row'>
						<tr>
							<td className='hour-cell'>{item.hour}</td>
							<td className='title-cell'>{item.title}</td>
							<td>{item.description}</td>
							<td className='location-cell'>{item.location}</td>
						</tr>
					</tbody>
				))}
			</table>

			{/* <button className="custom-popup-button btn btn-primary" onClick={globalManager.exitAudioOptions}> Return </button> */}
			<button className='close-button' onClick={args.exitAudioOptions}>
				<img src='/media/icons/castillo_monzon/Cerrar.png' alt='' />
			</button>
		</>
	);
}

export {CalendarTable};
