import * as Environment from './environment.js';
import * as THREE from './3D/three.module.js';
import {PlayerControls} from './playerControls.js';
import * as Utils from './utils.js';
import TWEEN from '@tweenjs/tween.js';
import {CSS3DRenderer} from './libs/CSS3DRenderer.js';
import {DataManager} from './DataManager.js';
import {DisposeObj} from './utils.js';
import {PostBestTime, PostLog} from './ApolloUtils.js';
import {getRandomInt} from './utils.js';
import {AsyncLoader} from './AsyncLoader.js';
import {AvatarPoolManager} from './AvatarPoolManager.js';

const ADD_PDF = false;
const ADD_COLLECTABLES = false;
const ADD_NPCS = false;
const NUM_NPC = 0;
const ESPERA = 0;

const AFKMSGTIME = 1000 * 60 * 5; //5 min
const AFKBLUR = 1000 * 60 * 10; //1 min
const AFKKICKTIMER = AFKMSGTIME + AFKBLUR;

const RAYCASTDIST = 20;
const raycaster = new THREE.Raycaster();
raycaster.far = RAYCASTDIST;
const mouse = new THREE.Vector2();
const SendFrequency = 100; //in milliseconds
const SendRate = 1000 / SendFrequency;
const ClickableObjectType = {
	NONE: -1,
	FLOOR: 0,
	VIDEO: 1,
	USER: 2,
	SEAT: 3,
	PDF: 4,
	VIDEO_BUTTON: 5,
};

export const Animations = {
	Idle: 0,
	Walk: 1,
	Seat: 2,
	SeatIdle: 3,
	GetUp: 4,
	Applaud: 5,
	Greet: 6,
};

const ConversationDistanceSquared = 25;

const LOG_TYPE = {
	LOGIN: 1,
	LOGOUT: 2,
	INACTIVE: 3,
	CHANGE_ROOM: 4,
	CHALLENGE_COMPLETED: 5,
	CHALLENGE_RESTART: 6,
	VIDEO_FULLSCREEN: 7,
	FILE_LOADED: 8,
	CHANGE_AVATAR: 9,
};

const MAX_STEP_HEIGHT = 0.2;
const FPS_STACK_LENGTH = 20;
const LOW_PERFORMANCE_FPS = 20;

export class Scene {
	constructor(_movementCallback, room, socket, localMediaStream, hud, id, aspect, globalManager) {
		this.timeBefore = Date.now();
		//this.npcsGroup = [];
		this.audioType = 0;
		this.playerId = id;
		this.playerAnimations = [];
		this.playerMixer = null;
		this.playerActions = [];
		this.clients = [];
		this.npcs = [];
		this.doorArray = [];
		this.ground = [];
		this.elevatorsUp = [];
		this.elevatorsDown = [];
		this.collectables = [];
		this.pdfs = [];
		this.allCollected = globalManager.user.best_time > 0;
		this.isPlayerUp = false;
		this.down = new THREE.Vector3(0, -1, 0);
		this.playerCollision = null;
		this.objUnderMouse = null;
		this.objUnderPlayer = null;
		this.collisionPoint = new THREE.Vector3();
		this.timeForNextSend = 0;
		this.randomPositions = [];
		this.seatsArray = [];
		this.isSeated = false;
		this.restricMovement = false;
		this.afkSend = false;
		this.noAFKSend = true;
		this.window_focus = true;
		this.refreshInterval = null;
		this.videoArray = [];
		this.contextLost = false;

		this.masterVolume = 1;
		this.uniforms = {
			time: {value: 0.0},
			color: {type: 'vec3', value: new THREE.Color(0xffffff)},
		};
		//Video variables
		//Change between Youtube and Vimeo
		this.isYoutube = true;
		this.player = null;
		this.videoIsReady = false;
		this.videoPlaying = false;
		this.fullscreen = false;

		this.objsInFrontOfCamera = [];
		this.headPosition = undefined;
		this.sceneMeshes = [];
		this.sceneRaycastObj = [];

		Scene.glScene = this;
		// eslint-disable-next-line no-undef
		globalThis.scene = this;
		this.globalManager = globalManager;
		this.globalManager.scene = this;
		globalManager.onVolumeChange = (volume) => {
			this.OnVolumeChange(volume);
		};

		this.movementCallback = _movementCallback;

		this.socket = socket;
		this.localMediaStream = localMediaStream;
		this.hud = hud;
		if (this.localMediaStream) {
			this.pidInterval = this.hud.initMicrophoneLevels(
				this.localMediaStream,
				this.hud.colorPids,
				true
			);
		} else {
			globalManager.setOutgoingStreamEnabled(false);
		}

		//THREE scene
		this.scene = new THREE.Scene();
		//this.scene.fog = new THREE.Fog(0x768692, 55, 65);
		this.sceneCss = new THREE.Scene();

		//Camera
		this.width = window.innerWidth;
		this.height = window.innerHeight;
		this.camera = new THREE.PerspectiveCamera(50, this.width / this.height);
		this.camera.position.set(0, 0, 0);
		this.scene.add(this.camera);

		// Para control de movimiento
		this.previousPlayerPosition = new THREE.Vector3();
		this.previousCameraPosition = new THREE.Vector3();

		// Para llevar cuenta de las pildoras
		this.collectedPills = this.allCollected ? 10 : 0;
		this.globalManager.setCurrentPills(this.collectedPills);

		this.hasAntialias = true;

		// Para los videos
		//Video.Hall = new VideoElement(1);
		//Video.Stage = new VideoElement(2);

		//
		this.InitRenderer();

		window.addEventListener('mousemove', this.onMouseMove, false);
		window.addEventListener('resize', this.onWindowResize, false);
		this.modelPool = new AvatarPoolManager(10, this.scene);
		this.AsyncInit(aspect);
		this.globalManager.ChangeQuality = () => {
			this.globalManager.SetQuality(!this.globalManager.lowQuality);
			this.globalManager.ShowLoadingScreen(1);
			DisposeObj(this.scenario);
			this.changeScenario(this.room, true, true);
		};
		//document.addEventListener('unload', this.Dispose);
	}

	SetRoom(room) {
		this.room = room;
		if (this.playerGroup !== undefined) {
			this.playerGroup.position.set(this.room.spawnX, this.room.spawnY, this.room.spawnZ);
			this.camera.position.set(this.room.spawnX, this.room.spawnY + 3, this.room.spawnZ + 3);
		}
	}

	async AsyncInit(aspect) {
		this.globalManager.ShowLoadingScreen(6);
		await this.modelPool.Prepare();

		this.addSelf('', aspect);

		// add controls:
		this.controls = new PlayerControls(this.camera, this.playerGroup, document, this);

		//On windows focus lost
		window.onblur = function (e) {
			if (Scene.glScene.controls) Scene.glScene.controls.FocusLost();
			Scene.glScene.window_focus = false;
			if (!Scene.glScene.restricMovement) {
				Scene.glScene.refreshInterval = setInterval(function () {
					if (!Scene.glScene.window_focus) {
						// eslint-disable-next-line no-restricted-globals
						location.reload();
					}
				}, AFKBLUR);
			} else {
				clearInterval(Scene.glScene.refreshInterval);
			}
		};

		window.onfocus = function (e) {
			Scene.glScene.window_focus = true;
			clearInterval(Scene.glScene.refreshInterval);
		};

		this.updateId = requestAnimationFrame(() => this.update());
	}

	//#region Initialization
	InitRenderer() {
		//CSS3D Renderer
		this.rendererCss = new CSS3DRenderer();
		this.rendererCss.setSize(window.innerWidth, window.innerHeight);
		this.rendererCss.domElement.style.position = 'absolute';
		this.rendererCss.domElement.style.top = 0;

		//THREE WebGL renderer
		this.renderer = new THREE.WebGLRenderer({
			antialias: true,
			alpha: true,
			powerPreference: 'high-performance',
		});
		this.renderer.setClearColor(0x00ff00, 0.0);
		this.renderer.setSize(this.width, this.height);
		//
		this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
		this.renderer.toneMappingExposure = 1;
		this.renderer.outputEncoding = THREE.sRGBEncoding;
		this.renderer.domElement.style.position = 'absolute';
		this.renderer.domElement.style.top = 0;
		this.renderer.domElement.style.zIndex = 1;

		let domElement = document.getElementById('canvas-container');
		domElement.append(this.rendererCss.domElement);
		this.rendererCss.domElement.appendChild(this.renderer.domElement);

		this.renderer.getContext().canvas.addEventListener(
			'webglcontextlost',
			(event) => {
				event.preventDefault();
				// animationID would have been set by your call to requestAnimationFrame
				cancelAnimationFrame(this.updateId);
				this.Dispose();
				this.globalManager.SetQuality(true);
				window.location.assign(window.location.href + 'auth/login/?error=contextLost');
			},
			false
		);

		this.renderer.getContext().canvas.addEventListener(
			'webglcontextrestored',
			(event) => {
				this.updateId = requestAnimationFrame(() => this.update());
			},
			false
		);
	}

	async initEnvironment(room) {
		//await this.changeScenario(room, false);
		this.scenario = await Environment.createEnvironment(
			this.scene,
			room,
			this.renderer,
			this.doorArray,
			this.randomPositions,
			this.seatsArray,
			true,
			this.sceneMeshes,
			this.isYoutube,
			this.sceneRaycastObj,
			this.videoArray,
			this.globalManager.lowQuality
		);
		//this.addLights();
		this.roomConfig(room);

		if (ADD_NPCS) {
			this.female = await AsyncLoader.LoadGLBModelAsync('../assets/models/Female.glb');
			this.male = await AsyncLoader.LoadGLBModelAsync('../assets/models/Male.glb');
			this.addStageNPCS(NUM_NPC, this.female, this.male);
		}
		this.SetFloorPlane();
		this.scene.emotes = Environment.createHearts(this.scene);
	}

	SetFloorPlane() {
		const textureLoader = new THREE.TextureLoader();
		//const texture = textureLoader.load('./assets/Andar.png');
		const texture = textureLoader.load('./media/icons/castillo_monzon/Andar.png');
		const planeMaterial = new THREE.MeshStandardMaterial({
			map: texture,
			transparent: true,
		});
		this.floorPlane = new THREE.Mesh(new THREE.PlaneBufferGeometry(), planeMaterial);
		this.floorPlane.rotation.x = -Math.PI / 2;
		this.floorPlane.position.y = 0.05;
		this.floorPlane.visible = false;
		this.floorPlane.name = 'floor_indicator';
		this.floorPlane.renderOrder = 1;
		this.scene.add(this.floorPlane);
	}

	addLights() {
		//this.scene.add(new THREE.AmbientLight(0xffffff, 0.2));
	}

	async addCollectables() {
		if (!ADD_COLLECTABLES || this.allCollected) return;
		const collectObjs = await Environment.AddCollectables(10);
		let positions = this.copyPositions();
		for (let i = 0; i < collectObjs.length; i++) {
			const obj = collectObjs[i];
			obj.position.copy(this.getRandomPosition(positions));
			this.scene.add(obj);
			obj.boundingBox.translate(obj.position);
			this.collectables.push(obj);
		}
		this.start = new Date();
	}

	OnModelLoaded(gltf, model) {
		const object = gltf.scene;

		object.traverse((child) => {
			if (child.isMesh) {
				child.castShadow = false;
				child.receiveShadow = false;
				child.frustumCulled = true;
			}
			if (child.name.indexOf('Hair') >= 0) {
				model.hairs.push(child);
			} else if (child.name.indexOf('Beard') >= 0) {
				model.beards.push(child);
			} else if (child.name.indexOf('Chest') >= 0) {
				model.jackets.push(child);
			} else if (child.name.indexOf('Trousers') >= 0) {
				model.trousers.push(child);
			} else if (child.name.indexOf('shoes') >= 0) {
				model.shoes.push(child);
			} else if (child.name.indexOf('Female_Body') >= 0) {
				model.skin.push(child);
			}
		});
	}

	randAspect(modelF, modelM) {
		let _aspect = new THREE.Group();
		_aspect.genre = getRandomInt(0, 1);
		if (modelF == null) {
			_aspect.genre = 0;
		} else if (modelM == null) {
			_aspect.genre = 1;
		}

		if (_aspect.genre === 0) {
			_aspect.hair = getRandomInt(0, modelM.hairs.length - 1);
			_aspect.beard = getRandomInt(0, modelM.beards.length - 1);
			_aspect.jacket = getRandomInt(0, modelM.jackets.length - 1);
			_aspect.trousers = getRandomInt(0, modelM.trousers.length - 1);
			_aspect.shoes = getRandomInt(0, modelM.shoes.length - 1);
			_aspect.skin_color = getRandomInt(0, modelM.skin.length - 1);
		} else {
			_aspect.hair = getRandomInt(0, modelF.hairs.length - 1);
			_aspect.jacket = getRandomInt(0, modelF.jackets.length - 1);
			_aspect.trousers = getRandomInt(0, modelF.trousers.length - 1);
			_aspect.shoes = getRandomInt(0, modelF.shoes.length - 1);
			_aspect.skin_color = getRandomInt(0, modelF.skin.length - 1);
		}
		_aspect.__typename = 'Avatar';

		return _aspect;
	}

	async addStageNPCS(num, femaleGltf, maleGltf) {
		let initTime = Date.now();
		let modelF = {
			hairs: [],
			jackets: [],
			trousers: [],
			shoes: [],
			skin: [],
		};

		let modelM = {
			hairs: [],
			beards: [],
			jackets: [],
			trousers: [],
			shoes: [],
			skin: [],
		};

		this.OnModelLoaded(femaleGltf, modelF);
		this.OnModelLoaded(maleGltf, modelM);

		for (let i = 0; i < num; i++) {
			let individualTime = Date.now();
			const group = new THREE.Group();

			group.position.copy(new THREE.Vector3(0, -100, 0));
			group.direction = new THREE.Vector3(0, 0, 1);
			group.name = 'NPC_' + i;

			this.npcs[i] = {};
			this.npcs[i].aspect = this.randAspect(modelF, modelM);
			let processtime = Date.now();
			const result = await Environment.getNewPlayerModel(
				group,
				i,
				this.npcs[i].aspect,
				false,
				false
			);

			console.log('	Process:', Date.now() - processtime);

			if (this.npcs[i] === undefined) {
				return;
			}
			this.setClientModelVariables(
				result.mixer,
				result.animations,
				this.npcs[i],
				group.name,
				group,
				group.position,
				group.rotation
			);
			this.scene.add(group);
			this.npcsGroup.push(group);

			this.npcs[i].ready = true;
			console.log('	Total:', Date.now() - individualTime, 'ms');
			//await this.delayedActivations(group);
			if (ESPERA > 0) await this.sleep(ESPERA);
		}
		console.log(num, 'NPCs TOTAL:', Date.now() - initTime, 'ms');
	}

	async delayedActivations(group) {
		group.children[0].position.y = -100;
		for (let i = 1; i < group.children[0].children[0].children.length; i++) {
			await this.sleep(500);
			const element = group.children[0].children[0].children[i];
			element.visible = true;
		}
		group.children[0].position.y = 0;
	}

	async sleep(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	resetCollectables() {
		//
		this.collectedPills = 0;
		this.allCollected = false;
		this.globalManager.setCurrentPills(this.collectedPills);
		this.DisposeCollectables();
		this.addCollectables();
		PostLog(LOG_TYPE.CHALLENGE_RESTART, this.globalManager.user.id);
	}

	async addPDFs() {
		if (ADD_PDF) {
			this.start = new Date();
			const pdfObjs = await Environment.AddPDFs(5);
			let positions = this.copyPositions();
			for (let i = 0; i < pdfObjs.length; i++) {
				const obj = pdfObjs[i];
				obj.position.copy(this.getRandomPosition(positions));
				obj.position.y = 1;
				this.scene.add(obj);
				this.pdfs.push(obj);
			}
		}
	}

	copyPositions() {
		let positions = [];
		for (let i = 0; i < this.randomPositions.length; i++) {
			const pos = this.randomPositions[i];
			positions.push(pos.clone());
		}
		return positions;
	}

	getRandomPosition(positions) {
		const i = Utils.getRandomInt(0, positions.length - 1);
		const position = positions[i];
		positions.splice(i, 1);
		return position;
	}

	async addSelf(_name, aspect) {
		this.playerGroup = new THREE.Group();
		if (this.room !== undefined) {
			this.playerGroup.position.set(this.room.spawnX, this.room.spawnY, this.room.spawnZ);
			this.camera.position.set(this.room.spawnX, this.room.spawnY + 3, this.room.spawnZ + 3);
		}
		this.playerGroup.direction = new THREE.Vector3(0, 0, 1);
		this.playerGroup.name = 'SelfPlayer';

		//
		const playerModel = await this.modelPool.GetPlayerModel(aspect);
		this.playerGroup.add(playerModel);
		this.playerGroup.add(playerModel.collisionCube);
		this.playerCube = playerModel.collisionCube;
		playerModel.collisionCube.geometry.computeBoundingBox();
		this.playerCube = playerModel.collisionCube;
		this.playerCollision = playerModel.collisionCube.geometry.boundingBox;
		this.onPlayerModelLoad(playerModel.mixer, playerModel.animations, 0);
		// add group to scene
		this.scene.add(this.playerGroup);
		this.addHeadObj();
		this.movementCallback(this.getPlayerPosition());
	}

	onPlayerModelLoad(mixer, animations, id) {
		this.playerAnimations = animations;
		this.playerMixer = mixer;
		for (let i = 0; i < animations.length; i++) {
			this.playerActions[i] = mixer.clipAction(animations[i]);
		}
		this.playerActions[Animations.Idle].play();
	}

	async addHeadObj() {
		this.playerGroup.name = this.playerName;
		this.headPosition = new THREE.Group();
		this.headPosition.position.set(0, 2, 0);
		this.playerGroup.add(this.headPosition);
	}

	//#endregion Initilization

	//#region Clients

	setClientModelVariables(mixer, animations, client, name, group, position, rotation) {
		client.animations = animations;
		client.mixer = mixer;
		client.name = name;
		client.group = group;
		client.desiredPosition = new THREE.Vector3(position[0], position[1], position[2]);
		client.desiredRotation = new THREE.Quaternion().fromArray(rotation);
		client.movementAlpha = 0;
		client.actions = [];
		client.group.position.copy(client.desiredPosition);
		for (let i = 0; i < animations.length; i++) {
			client.actions[i] = mixer.clipAction(animations[i]);
		}
		client.actions[Animations.Idle].play();
		client.volume = 0;
	}

	resetClientModelVariables(client) {
		client.animations = null;
		client.mixer = null;
		client.name = null;
		client.desiredPosition = new THREE.Vector3();
		client.desiredRotation = new THREE.Quaternion();
		client.movementAlpha = 0;
		client.actions = [];
		client.volume = 0;
		if (client.group) {
			for (let i = 0; i < this.sceneRaycastObj.length; i++) {
				const element = this.sceneRaycastObj[i];
				if (element === client.group.children[0].collisionCube) {
					this.sceneRaycastObj.splice(i, 1);
					continue;
				}
			}
			this.modelPool.ReleasePlayerModel(client.aspect.genre, client.group.children[0]);
		}
	}

	// add a client meshes, a video element and  canvas for three.js video texture
	async addClient(client) {
		if (this.room.stage) {
			return;
		}
		const playerModelPool = await this.modelPool.GetPlayerModel(client.aspect);
		this.sceneRaycastObj.push(playerModelPool.collisionCube);
		const group = new THREE.Group();
		group.name = client.name;
		group.position.copy(client.position);
		group.quaternion.copy(new THREE.Quaternion().fromArray(client.rotation));
		group.add(playerModelPool);
		group.add(playerModelPool.collisionCube);
		playerModelPool.collisionCube.name = client.id;
		//
		this.setClientModelVariables(
			playerModelPool.mixer,
			playerModelPool.animations,
			this.clients[client.id],
			client.name,
			group,
			client.position,
			client.rotation
		);
		// add group to scene
		this.scene.add(group);

		let displayName = this.getClientDisplayName(client);
		//
		this.clients[client.id].textMesh = await Environment.addName(displayName, group, false, true);
		this.clients[client.id].textMesh.children[0].visible = this.clients[client.id].mute;
		if (this.clients[client.id].seated) {
			this.PlayAction(this.clients[client.id].actions, Animations.SeatIdle);
		}
		//
		this.clients[client.id].ready = true;
	}

	getClientDisplayName(client) {
		const user = DataManager.Instance().FindUserByEmail(client.name);
		let displayName = client.name;
		if (user != null && user !== undefined) {
			displayName = Utils.GetDisplayName(user);
		} else {
			if (displayName.length > 15) {
				displayName = displayName.slice(0, 14) + '...';
			}
		}
		return displayName;
	}

	getClientDisplayPosition(client) {
		const user = DataManager.Instance().FindUserByEmail(client.name);
		let displayPosition = '\nTest';
		if (user != null && user !== undefined) {
			displayPosition = Utils.GetDisplayPosition(user);
		}
		return displayPosition;
	}

	removeClient(_id) {
		const client = this.clients[_id];
		this.resetClientModelVariables(client);
		this.scene.remove(client.textMesh.parent);
		if (client.group) DisposeObj(client.group);
		DisposeObj(client.textMesh.parent);
		client.peerConnection?.close();
		clearInterval(client.interval);
	}

	removeNPC(_id) {
		const npc = this.npcs[_id];
		this.scene.remove(npc.group);
		if (npc.group) {
			DisposeObj(npc.group);
		}
	}

	// overloaded function can deal with new info or not
	updateClientPositions(_clientProps) {
		//
		if (this.clients == null) {
			return;
		}

		//
		for (let _id in _clientProps) {
			// we'll update ourselves separately to avoid lag...
			if (_id !== this.playerId) {
				// No debería hacer falta este chequeo
				if (this.clients[_id] == null) {
					continue;
				}

				//
				const client = this.clients[_id];
				if (!client.ready) continue;
				client.desiredPosition = new THREE.Vector3(
					_clientProps[_id].position[0],
					_clientProps[_id].position[1],
					_clientProps[_id].position[2]
				);
				client.desiredRotation = new THREE.Quaternion().fromArray(_clientProps[_id].rotation);
				if (isNaN(client.group.position.x)) {
					client.group.position.fromArray(_clientProps[_id].position);
				}
				if (isNaN(client.group.quaternion._x)) {
					client.group.quaternion.fromArray(_clientProps[_id].rotation);
				}
				client.distance = client.group.position.distanceTo(client.desiredPosition);
			}
		}
	}

	// snap to position and rotation if we get close
	interpolatePositions() {
		for (let _id in this.clients) {
			const client = this.clients[_id];
			if (!client.ready) {
				continue;
			}
			if (client.seated) {
				client.group.position.copy(client.desiredPosition);
				client.group.quaternion.slerp(client.desiredRotation, 1);
				continue;
			}
			const lastClientPosition = client.group.position.clone();
			const position = Utils.MoveTowards(
				lastClientPosition,
				client.desiredPosition,
				client.distance * (1 / SendRate)
			);
			if (client.group.position !== client.position) client.group.position.copy(position);
			if (client.group.quaternion !== client.desiredRotation)
				client.group.quaternion.slerp(client.desiredRotation, 0.2);
			if (lastClientPosition.distanceToSquared(position) > 0.0001) {
				if (!client.moving) {
					this.PlayAction(client.actions, Animations.Walk);
				}
				client.moving = true;
			} else {
				if (client.moving) {
					this.PlayAction(client.actions, Animations.Idle);
				}
				client.moving = false;
			}
		}
	}

	updateClientVolumes() {
		// Audio tipo 1 no hace falta tocar nada aqui, ya está a tope
		if (this.audioType === 1) {
			return;
		}
		//
		for (let _id in this.clients) {
			let audioEl = document.getElementById(_id + '_audio');
			if (audioEl) {
				let distance = 0;
				const client = this.clients[_id];
				if (!client.ready) continue;
				if (client.group === undefined) {
					distance = this.playerGroup.position.distanceToSquared(client.desiredPosition);
				} else {
					distance = this.playerGroup.position.distanceToSquared(client.group.position);
				}

				if (distance > ConversationDistanceSquared) {
					audioEl.volume = 0;
				} else {
					let volume = 1 - distance / ConversationDistanceSquared;
					if (isFinite(volume)) {
						audioEl.volume = this.easeInOutQuart(volume) * this.masterVolume;
					}
				}
				client.volume = audioEl.volume / this.masterVolume;
			}
		}
	}

	easeInOutQuart(x) {
		return x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2;
	}

	getPlayerPosition() {
		return [
			[this.playerGroup.position.x, this.playerGroup.position.y, this.playerGroup.position.z],
			[
				this.playerGroup.quaternion._x,
				this.playerGroup.quaternion._y,
				this.playerGroup.quaternion._z,
				this.playerGroup.quaternion._w,
			],
		];
	}

	userAudioAdded(_userName, stream, _id) {
		if (this.clients[_id].interval) clearInterval(this.clients[_id].interval);
		const clientVolInterval = this.hud.initMicrophoneLevels(stream, (volume) => {
			const client = this.clients[_id];
			if (client.textMesh === undefined) {
				return;
			}
			volume *= client.volume;
			if (volume >= 5) {
				client.textMesh.material.color.r = 0;
				client.textMesh.material.color.g = 1;
				client.textMesh.material.color.b = 0;
			} else {
				client.textMesh.material.color.r = 1;
				client.textMesh.material.color.g = 1;
				client.textMesh.material.color.b = 1;
			}
		});
		this.clients[_id].interval = clientVolInterval;
	}
	//#endregion Clients

	//#region Rendering

	update() {
		this.updateId = requestAnimationFrame(() => this.update());
		if (this.globalManager.showLoadingScreen) return;
		// Guardamos posiciones previas para evitar entrar en escenario
		this.previousPlayerPosition.copy(this.playerGroup.position);
		this.previousCameraPosition.copy(this.camera.position);

		// Version de un solo mixer
		let timeNow = Date.now();
		let delta = timeNow - this.timeBefore;
		delta = delta / 1000;
		this.timeBefore = timeNow;
		this.checkPerformance(delta);
		this.timeForNextSend -= delta;
		if (this.playerMixer) this.playerMixer.update(delta);
		for (let _id in this.clients) {
			const client = this.clients[_id];
			if (client.ready) {
				try {
					client.mixer.update(delta);
				} catch {}
			}
		}
		if (ADD_NPCS) {
			for (let i = 0; i < this.npcs.length; i++) {
				const npc = this.npcs[i];
				if (npc.ready) npc.mixer.update(delta);
			}
		}
		//if (this.collectables.length >= 0) {
		//	for (let i = 0; i < this.collectables.length; i++) {
		//		this.collectables[i].mixer.update(delta);
		//	}
		//}

		this.wasMoving = this.playerMoved;
		this.checkGrounded();
		// Actualizamos los controloes
		this.controls.update(delta);

		if (this.playerMoved && this.moving) {
			this.stopMovementTween();
		}

		if (!(this.movingDown || this.movingUp)) {
			if (this.playerMoved || this.moving) {
				if (!this.wasMoving) this.PlayAction(this.playerActions, Animations.Walk);
				this.playerCube.geometry.computeBoundingBox();
				this.playerCollision = this.playerCube.geometry.boundingBox;
				this.playerCollision.translate(this.playerGroup.position);
				//check collision with interactive elements
				this.checkDoors();
				this.checkElevators();
				this.checkCollectables();
				this.checkCollectables();
			} else if (this.wasMoving) {
				this.PlayAction(this.playerActions, Animations.Idle);
			}
		}

		this.updateUserNameRotations();

		if (this.timeForNextSend <= 0) {
			this.updateClientVolumes();
			if (this.playerMoved || this.moving) {
				this.movementCallback(this.getPlayerPosition());
				this.timeForNextSend = 1 / SendRate;
			}
		}

		this.seatDistVisible();
		Environment.animateHearts(this.scene, this.camera.position, delta);

		this.updateClickIndicator();

		if (this.movingUp || this.movingDown || this.moving) {
			TWEEN.update();
		}

		if (this.playerMoved && this.isSeated) {
			this.ReleaseSeat(false);
		}
		this.interpolatePositions();
		//this.CameraVisibilityPass();

		this.uniforms.time.value = this.scene.clock.getElapsedTime(); //(Math.sin(4 * this.scene.clock.getElapsedTime()) + 1) / 2;

		this.checkState();

		this.render();

		// Para la pantalla de carga
		this.globalManager.updateLoadingImage();
	}

	render() {
		//let time = Date.now();
		this.renderer.render(this.scene, this.camera);
		this.rendererCss.render(this.sceneCss, this.camera);
		if (typeof __THREE_DEVTOOLS__ !== 'undefined') {
			// eslint-disable-next-line no-undef
			__THREE_DEVTOOLS__.dispatchEvent(new CustomEvent('observe', {detail: this.scene}));

			// eslint-disable-next-line no-undef
			__THREE_DEVTOOLS__.dispatchEvent(new CustomEvent('observe', {detail: this.renderer}));
		}
		//console.log("Render\n	Total:",Date.now()-time);
	}

	checkGrounded() {
		if (!this.playerMoved && this.grounded) {
			this.groundDistance = 0;
			return;
		}
		raycaster.set(this.playerGroup.position.clone().addScaledVector(this.down, -0.4), this.down);
		raycaster.far = 1;
		let intersects = raycaster.intersectObjects(this.ground, true);
		raycaster.far = RAYCASTDIST;
		this.grounded = false;

		if (intersects && intersects.length > 0) {
			if (intersects[0].distance < 0.01) {
				this.grounded = true;
			}
			this.groundDistance = intersects[0].distance - 0.4;
		} else {
			this.groundDistance = 0;
			this.grounded = false;
		}
	}

	updateUserNameRotations() {
		for (let c in this.clients) {
			const client = this.clients[c];
			if (client.ready) {
				client.textMesh.lookAt(this.camera.position);
			}
		}

		this.doorArray.forEach((door) => {
			if (door.children[0]) door.children[0].lookAt(this.camera.position);
		});
	}

	//#endregion Rendering

	//#region Animations

	PlayAction(actions, action, callback = null) {
		if (actions.length <= 0) return;

		let stop, reset, play;

		for (let i = 0; i < actions.length; i++) {
			const element = actions[i];
			if (element.isRunning()) {
				if (i !== action) {
					stop = element;
				}
			} else if (i === action) {
				if (callback) {
					reset = element;
				}
				play = element;
			}
		}

		if (reset) reset.reset();
		if (play) play.play();
		if (stop) stop.stop();

		if (callback) {
			actions[action].setLoop(THREE.LoopOnce);
			const listener = () => {
				actions[action]._mixer.removeEventListener('finished', listener, false);
				callback();
				actions[action].stop();
			};
			actions[action]._mixer.addEventListener('finished', listener, false);
		}
	}

	//#endregion Animations

	//#region Doors
	checkDoors() {
		let contact = false;
		for (let i = 0; i < this.doorArray.length; i++) {
			const door = this.doorArray[i];
			if (door.bb.intersectsBox(this.playerCollision)) {
				contact = true;
				if (!this.blockDoors) {
					let roomToGo = door.name;
					if (roomToGo === undefined) {
						roomToGo = 'Hall';
						console.warn('Door leading to undefined room, returning to room 0');
					}
					if (roomToGo === 'Top') {
						this.globalManager.showPopupMenuFromButton(15);
						this.blockDoors = true;
					} else {
						this.globalManager.roomToGo = roomToGo;
						this.globalManager.showPopupMenuFromButton(5);
						this.blockDoors = true;
					}
				}
				break;
			}
		}
		if (!contact && this.blockDoors) {
			this.blockDoors = false;
		}
	}

	changeRoom(newRoom) {
		if (newRoom === this.room.name) {
			return;
		}

		this.globalManager.setShowExitHall(newRoom !== 'Hall');

		if (this.isSeated) {
			this.ReleaseSeat(false);
		}
		this.socket.emit('change_room', {
			from: this.room.name,
			to: newRoom,
		});

		this.globalManager.setUserRoom(newRoom);
	}
	//#endregion Doors

	//#region Elevators

	checkElevators() {
		let contact = false;
		if (this.isPlayerUp) {
			if (this.checkIntersectWithObjectsInList(this.elevatorsDown)) {
				contact = true;
				if (!this.blockElevators) {
					this.stopMovementTween();
					this.startMovingInElevetor(false);
				}
			}
		} else {
			if (this.checkIntersectWithObjectsInList(this.elevatorsUp)) {
				contact = true;
				if (!this.blockElevators) {
					this.stopMovementTween();
					this.startMovingInElevetor(true);
				}
			}
		}

		if (!contact && this.blockElevators) {
			this.blockElevators = false;
		}
	}

	checkIntersectWithObjectsInList(list) {
		for (let i = 0; i < list.length; i++) {
			const element = list[i];
			if (element.intersectsBox(this.playerCollision)) {
				return true;
			}
		}
		return false;
	}

	startMovingInElevetor(goingUp) {
		this.controls.setEnable(false);
		this.movingUp = goingUp;
		this.movingDown = !goingUp;
		this.PlayAction(this.playerActions, Animations.Idle);
		const playerPos = this.playerGroup.position;
		new TWEEN.Tween(playerPos)
			.to({y: goingUp ? 14.68 : 0}, 3000)
			.easing(TWEEN.Easing.Cubic.InOut)
			.onUpdate(() => this.controls.forceLookAt())
			.start()
			.onComplete(() => this.stopMoving());
		let lastCamPos = this.camera.position.clone();
		new TWEEN.Tween(this.camera.position)
			.to({x: playerPos.x, y: goingUp ? 3 : 14.68, z: playerPos.z}, 3000)
			.easing(TWEEN.Easing.Cubic.Out)
			.start()
			.onComplete(() => {
				this.camera.position.set(
					playerPos.x,
					lastCamPos.y + (goingUp ? 14.68 : -14.68),
					playerPos.z - 4
				);
			});
	}

	stopMoving() {
		this.controls.setEnable(true);
		this.isPlayerUp = this.movingUp;
		this.movingDown = false;
		this.movingUp = false;
		this.blockElevators = true;
	}

	//#endregion Elevators

	//#region Collectables

	checkCollectables() {
		for (let i = 0; i < this.collectables.length; i++) {
			const element = this.collectables[i];
			if (element.boundingBox.intersectsBox(this.playerCollision)) {
				this.collectObject(i);
				return;
			}
		}
		return;
	}

	collectObject(index) {
		const element = this.collectables.splice(index, 1)[0];
		this.scene.remove(element);
		if (this.collectables.length === 0) {
			this.storeBestTime();
			this.allCollected = true;
		}
		//
		this.collectedPills++;
		this.globalManager.setCurrentPills(this.collectedPills);
	}

	async storeBestTime() {
		const end = new Date();
		const seconds = (end.getTime() - this.start.getTime()) / 1000;
		PostLog(LOG_TYPE.CHALLENGE_COMPLETED, this.globalManager.user.id);
		if (this.globalManager.user.best_time < 0 || this.globalManager.user.best_time > seconds) {
			await PostBestTime(this.globalManager.user.id, seconds);
			this.globalManager.user.best_time = seconds;
		}
	}

	//#endregion Collectables

	//#region Mouse Click
	waitAndMakeRaycasting() {
		setTimeout(() => this.OnClick(), 50);
	}

	looseFocus() {
		if (document.getElementById('questions-field')) {
			document.getElementById('questions-field').blur();
		}
		if (document.getElementById('chat-field')) {
			document.getElementById('chat-field').blur();
		}
	}

	OnClick() {
		//
		if (this.hudElementClickedThisFrame) {
			return true;
		}
		//lose focus from textInput
		this.looseFocus();
		// update the picking ray with the camera and mouse position
		switch (this.objTypeUnderMouse) {
			case ClickableObjectType.FLOOR:
				this.moveToPosition(this.collisionPoint);
				break;
			case ClickableObjectType.VIDEO:
				this.switchVideoState();
				break;
			case ClickableObjectType.USER:
				let id = this.objUnderMouse.name;
				// Nos ignoramos a nosotros mismos
				if (id && id !== this.playerId && id !== '0') {
					this.globalManager.userToShowInPopup = id;
					this.globalManager.showPopupMenuFromButton(6);
				}
				break;
			case ClickableObjectType.SEAT:
				this.SeatPlayer();
				break;
			case ClickableObjectType.PDF:
				this.downloadPDF(this.objUnderMouse.path, this.objUnderMouse.downloadName);
				break;
			case ClickableObjectType.VIDEO_BUTTON:
				const videoId = Number(this.objUnderMouse.name.split('_')[1]);
				this.globalManager.videoUrl = DataManager.Instance().GetVideoById(videoId + 1).url;
				this.globalManager.showPopupMenuFromButton(14);
				break;
			default:
				break;
		}
	}

	async downloadPDF(path_to_file, file_name) {
		const link = document.createElement('a');
		link.href = path_to_file;
		link.download = file_name;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	moveToPosition(point) {
		if (this.isSeated) {
			this.ReleaseSeat(false, () => this.moveToPosition(point));
			return;
		}
		this.moving = true;
		this.floorPlane.visible = false;
		if (this.movementTween != null) {
			this.movementTween.stop();
		}
		let direction = point.clone().sub(this.playerGroup.position);
		const distance = direction.length();
		direction.normalize();
		this.rotatePlayerForAutoMove(direction);
		const time = (distance / this.controls.moveSpeed) * 1000;
		const cameraDistance = new THREE.Vector3(
			this.playerGroup.position.x - this.camera.position.x,
			0,
			this.playerGroup.position.z - this.camera.position.z
		).length();
		direction.multiplyScalar(cameraDistance);
		this.movementTween = new TWEEN.Tween({
			playerPos: this.playerGroup.position,
			camera: this.camera.position,
		})
			.to(
				{
					playerPos: {
						x: point.x,
						z: point.z,
					},
					camera: {
						x: point.x - direction.x,
						z: point.z - direction.z,
					},
				},
				time
			)
			.onUpdate(() => {
				let cameraPos = this.camera.position.y - this.playerGroup.position.y;
				this.controls.forceLookAt();
				raycaster.set(
					this.playerGroup.position.clone().addScaledVector(this.down, -0.4),
					this.down
				);

				raycaster.far = 1;
				const intersects = raycaster.intersectObjects(this.ground, false);
				raycaster.far = RAYCASTDIST;
				let shouldStop = true;
				if (intersects && intersects.length > 0) {
					this.objUnderPlayer = intersects[0].object;
					this.objUnderPlayer.name += '';
					if (this.checkFloor(intersects, this.objUnderPlayer, true)) {
						shouldStop = false;
						this.playerGroup.position.copy(intersects[0].point);
						this.camera.position.set(
							this.camera.position.x,
							this.playerGroup.position.y + cameraPos,
							this.camera.position.z
						);
					}
				}
				if (shouldStop) {
					this.movementTween.stop();
					this.releasePlayer();
					this.playerGroup.position.copy(this.previousPlayerPosition);
				}
			})
			.onComplete(() => {
				this.releasePlayer();
			});
		//this.controls.setEnable(false);
		this.movementTween.start();
		this.PlayAction(this.playerActions, Animations.Walk);
	}

	releasePlayer() {
		this.controls.setEnable(true);
		this.floorPlane.visible = true;
		this.moving = false;
		this.PlayAction(this.playerActions, Animations.Idle);
	}

	rotatePlayerForAutoMove(direction) {
		const angle = Utils.SignedAngleFromObject(this.playerGroup, direction, Utils.forward) + Math.PI;
		this.playerGroup.rotation.y += angle;
	}

	stopMovementTween() {
		if (this.movementTween != null) {
			this.movementTween.stop();
			this.moving = false;
			this.movementTween = null;
		}
		if (this.controls) this.controls.setEnable(true);
	}

	checkAboveGround(origin, maxDistance) {
		raycaster.set(origin.clone().addScaledVector(Utils.up, MAX_STEP_HEIGHT), this.down);

		raycaster.far = 1;
		const intersects = raycaster.intersectObjects(this.ground, true);
		raycaster.far = RAYCASTDIST;
		if (intersects && intersects.length > 0) {
			this.playerGroup.position.copy(intersects[0].point);
			return intersects[0].point;
		}
		return null;
	}

	SeatPlayer(seatIndex = null) {
		let changeSeat = false;
		if (this.isSeated) {
			changeSeat = true;
			this.ReleaseSeat(changeSeat);
		}
		this.isSeated = true;

		if (seatIndex != null) {
			this.objUnderMouse = this.seatsArray[seatIndex];
		}

		this.seat = this.objUnderMouse.index;

		this.playerGroup.rotation.set(
			this.objUnderMouse.rotation.x,
			this.objUnderMouse.rotation.y,
			this.objUnderMouse.rotation.z
		);

		const forwardOfSeat = Utils.forward
			.clone()
			.applyQuaternion(this.objUnderMouse.quaternion)
			.projectOnPlane(Utils.up)
			.normalize();
		this.playerGroup.position.set(
			this.objUnderMouse.position.x - forwardOfSeat.x / 2,
			this.playerGroup.position.y,
			this.objUnderMouse.position.z - forwardOfSeat.z / 2
		);
		this.camera.position.set(
			this.objUnderMouse.position.x + forwardOfSeat.x,
			this.objUnderMouse.position.y + 1.5,
			this.objUnderMouse.position.z + 2.5 * forwardOfSeat.z
		);
		this.socket.emit('change_seat_state', {
			room: this.room.name,
			newSeatState: true,
			seatIndex: this.objUnderMouse.index,
		});
		this.movementCallback(this.getPlayerPosition());
		if (!changeSeat) {
			this.PlayAction(this.playerActions, Animations.Seat, () => {
				this.PlayAction(this.playerActions, Animations.SeatIdle);
			});
		}
	}

	ReleaseSeat(changeSeat, callback = null) {
		this.controls.enabled = false;

		this.playerGroup.rotation.set(0, this.playerGroup.rotation.y, 0);
		if (!changeSeat) {
			this.PlayAction(this.playerActions, Animations.GetUp, () => {
				this.controls.enabled = true;
				this.PlayAction(this.playerActions, this.playerMoved ? Animations.Walk : Animations.Idle);
				if (callback != null) callback();
			});
		} else {
			this.controls.enabled = true;
			if (callback != null) callback();
		}

		this.isSeated = false;
		this.socket.emit('change_seat_state', {
			room: this.room.name,
			newSeatState: false,
			seatIndex: this.seat,
		});
	}

	seatStateChanged(newSeats) {
		for (let i = 0; i < this.seatsArray.length; i++) {
			const seat = this.seatsArray[i];
			if (newSeats[i] === null) {
				newSeats[i] = undefined;
			}
			if (seat.occupy !== newSeats[i]) {
				if (!(seat.occupy === this.playerId || newSeats[i] === this.playerId)) {
					if (seat.occupy) {
						this.clients[seat.occupy].seated = false;
						this.getUpClient(seat.occupy);
					} else {
						this.clients[newSeats[i]].seated = true;
						if (this.clients[newSeats[i]].ready) {
							this.seatClient(newSeats[i]);
						}
					}
				}
				seat.occupy = newSeats[i];
				seat.visible = newSeats[i] === undefined;
			}
		}
	}

	seatDistVisible() {
		this.seatsArray.forEach((seat) => {
			if (this.playerGroup.position.distanceToSquared(seat.position) > 16 || this.room.stage) {
				seat.visible = false;
			} else if (!seat.occupy) {
				seat.visible = true;
			}
		});
	}

	getUpClient(clientId) {
		this.PlayAction(this.clients[clientId].actions, Animations.GetUp, () => {
			this.PlayAction(this.clients[clientId].actions, Animations.Idle);
		});
	}

	seatClient(clientId) {
		this.PlayAction(this.clients[clientId].actions, Animations.Seat, () => {
			this.PlayAction(this.clients[clientId].actions, Animations.SeatIdle);
		});
	}
	//#endregion Mouse Click

	//#region Event Handlres

	onMouseMove(event) {
		mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
		mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
	}

	onWindowResize(e) {
		Scene.glScene.width = window.innerWidth;
		Scene.glScene.height = window.innerHeight;
		Scene.glScene.camera.aspect = Scene.glScene.width / Scene.glScene.height;
		Scene.glScene.camera.updateProjectionMatrix();
		Scene.glScene.renderer.setSize(Scene.glScene.width, Scene.glScene.height);
		Scene.glScene.rendererCss.setSize(Scene.glScene.width, Scene.glScene.height);
	}

	//#endregion Event Handlers

	//#region Change Room
	async changeScenario(room, clear = true, onlyScene = false, previousRoom = '') {
		if (clear) {
			this.clearRoom(onlyScene);
			this.sceneRaycastObj = [];
		}
		this.scenario = await Environment.createEnvironment(
			this.scene,
			room,
			this.renderer,
			this.doorArray,
			this.randomPositions,
			this.seatsArray,
			false,
			this.sceneMeshes,
			this.isYoutube,
			this.sceneRaycastObj,
			this.videoArray,
			this.globalManager.lowQuality
		);
		this.controls.limitLateralMovement = false;
		if (room.name === 'Hall' || room.name === 'Castillo') {
			this.controls.scale = 10;
		} else {
			this.controls.scale = 0.1;
		}
		this.blockDoors = true;

		let spawnPoint = new THREE.Vector3(room.spawnX, room.spawnY, room.spawnZ);
		if (room.name === 'Castillo' && previousRoom !== 'Hall') {
			const obj = this.FindObjectByName('spawn_cas', this.scenario);
			if (obj) {
				console.log('Assigning spawn position');
				spawnPoint.set(obj.position.x, obj.position.y, obj.position.z);
			}
		}
		if (this.playerGroup !== undefined) {
			this.playerGroup.position.set(spawnPoint.x, spawnPoint.y, spawnPoint.z);
			this.camera.position.set(spawnPoint.x, spawnPoint.y + 3, spawnPoint.z + 3);
		}
		this.movementCallback(this.getPlayerPosition());

		this.roomConfig(room);
	}

	roomConfig(newRoom) {
		if (newRoom.name === 'Hall') {
			this.addCollectables();
			this.addPDFs();
		}

		if (newRoom.stage) {
			this.controls.limitLateralMovement = true;
			this.globalManager.setShowMainHud(false);
			this.globalManager.setShowAgoraHud(true);
			this.restricMovement = true;
			this.SeatPlayer(0);
			this.playerGroup.position.y -= 0.4;
			this.liveFeedbackPositions = this.PrepareFeedbackPositions();
		} else {
			this.globalManager.setShowMainHud(true);
			this.globalManager.setShowAgoraHud(false);
			this.restricMovement = false;
		}
	}

	clearRoom(onlyScene) {
		this.stopMovementTween();

		this.objUnderMouse = null;
		const oldScene = this.scene.getObjectByName('Scenario');
		this.scene.remove(oldScene);
		this.ground = [];
		DisposeObj(oldScene);
		if (this.doorArray.length > 0) {
			this.scene.remove(this.doorArray[0].parent);
			for (const door of this.doorArray) {
				door.geometry.dispose();
				door.material.dispose();
			}
		}
		if (this.seatsArray.length > 0) {
			this.scene.remove(this.seatsArray[0].parent);
			for (const door of this.seatsArray) {
				door.geometry.dispose();
				door.material.dispose();
			}
		}
		this.seatsArray = [];
		this.DisposeCollectables();
		this.DisposePDFs();
		const planeVideo = this.scene.getObjectByName('planeForVideo');
		if (planeVideo) {
			this.scene.remove(planeVideo);
			planeVideo.geometry.dispose();
			planeVideo.material.dispose();
			this.videoCollider = null;
		}
		if (this.videoIsReady) {
			if (this.videoPlaying) {
				this.pauseVideo();
			}
		}
		this.elevatorsDown = [];
		this.elevatorsUp = [];
		this.doorArray = [];
		this.randomPositions = [];
		this.sceneMeshes = [];
		while (this.sceneCss.children.length > 0) {
			this.sceneCss.remove(this.sceneCss.children[0]);
		}
		if (onlyScene) return;

		for (let c in this.clients) {
			try {
				this.removeClient(c);
			} catch (e) {
				console.error(e.message);
			}
		}

		this.clients = [];
		this.npcs = [];
		this.npcsGroup = [];
	}

	DisposeCollectables() {
		for (let i = 0; i < this.collectables.length; i++) {
			const obj = this.collectables[i];
			DisposeObj(obj);
			this.scene.remove(obj);
		}
		this.collectables = [];
	}

	DisposePDFs() {
		for (let i = 0; i < this.pdfs.length; i++) {
			const obj = this.pdfs[i];
			DisposeObj(obj);
			this.scene.remove(obj);
		}
		this.pdfs = [];
	}

	DisposeScene() {
		let objs = [];
		for (let i = 0; i < this.scene.children.length; i++) {
			const obj = this.scene.children[i];
			DisposeObj(obj);
			objs.push(obj);
		}
		for (let i = 0; i < objs.length; i++) {
			const obj = objs[i];
			this.scene.remove(obj);
		}
	}

	GoToTop() {
		const obj = this.FindObjectByName('spawn_cas', this.scenario);
		if (obj) {
			console.log('Assigning spawn position');
			if (this.playerGroup !== undefined) {
				this.playerGroup.position.set(obj.position.x, obj.position.y, obj.position.z);
				this.camera.position.set(obj.position.x, obj.position.y + 3, obj.position.z + 3);
			}
			this.movementCallback(this.getPlayerPosition());
		}
	}

	//#endregion Change Room

	//#region Mouse Move
	updateClickIndicator() {
		document.body.style.cursor = 'default';
		this.objTypeUnderMouse = ClickableObjectType.NONE;

		if (!this.hudElementClickedThisFrame) {
			// update the picking ray with the camera and mouse position
			raycaster.setFromCamera(mouse, this.camera);
			if (this.room.stage) {
				if (this.videoCollider) {
					const intersects = raycaster.intersectObject(this.videoCollider, false);
					if (intersects.length > 0) {
						this.objTypeUnderMouse = ClickableObjectType.VIDEO;
					}
				}
				return;
			}
			let intersects;
			try {
				this.scene.remove(this.floorPlane);
				intersects = raycaster.intersectObjects(this.sceneRaycastObj, true);
				this.scene.add(this.floorPlane);
			} catch (e) {
				this.changePointer();
			}
			if (intersects && intersects.length > 0) {
				this.objUnderMouse = intersects[0].object;
				this.objUnderMouse.name += '';
				if (this.objUnderMouse.name === 'planeForVideo') {
					this.objTypeUnderMouse = ClickableObjectType.VIDEO;
				} else if (
					this.objUnderMouse.name.indexOf('Asiento_') === 0 &&
					this.objUnderMouse.visible &&
					!this.restricMovement
				) {
					this.objTypeUnderMouse = ClickableObjectType.SEAT;
				} else if (this.objUnderMouse.name.indexOf('PDF_') === 0) {
					this.objTypeUnderMouse = ClickableObjectType.PDF;
				} else if (this.checkFloor(intersects, this.objUnderMouse) && !this.restricMovement) {
					this.collisionPoint = intersects[0].point;
					this.objTypeUnderMouse = ClickableObjectType.FLOOR;
				} else if (this.objUnderMouse.isPlayer) {
					this.objTypeUnderMouse = ClickableObjectType.USER;
				} else if (this.objUnderMouse.parent.name.indexOf('video') === 0) {
					this.objUnderMouse = this.objUnderMouse.parent;
					this.objTypeUnderMouse = ClickableObjectType.VIDEO_BUTTON;
					document.body.style.cursor = 'pointer';
				} else {
					this.objTypeUnderMouse = ClickableObjectType.NONE;
				}
			}
		}
		this.changePointer();
	}

	checkFloor(intersects, objUnder, isPlayer = false) {
		if (!isPlayer) {
			if (this.movingDown || this.movingUp || this.ground.length === 0) {
				return false;
			}
		}
		if (objUnder.name === this.ground[0].name) {
			return true;
		}
		if (
			objUnder.name === 'floor' &&
			intersects.length >= 2 &&
			intersects[1].object.name === this.ground[0].name &&
			intersects[1].distance - intersects[0].distance < 0.05
		) {
			return true;
		}
		if (objUnder.parent.name.indexOf('plataforma_ascensor') >= 0) {
			intersects[0].point.copy(objUnder.parent.parent.position);
			intersects[0].point.y = objUnder.parent.position.y + 0.1;
			return true;
		}
		if (
			objUnder.name === 'Seats' ||
			objUnder.name === 'Seats002' ||
			objUnder.name === 'Seats003' ||
			objUnder.name === 'Seats004'
		) {
			return true;
		}
		if (objUnder.parent.name === 'baldosas_superior') {
			return true;
		}
		try {
			if (objUnder.bb.name === 'doorCheck') {
				intersects[0].point.copy(objUnder.position);
				intersects[0].point.y -= (objUnder.bb.max.y - objUnder.bb.min.y) / 2;
				return true;
			}
		} catch {}
	}

	changePointer() {
		switch (this.objTypeUnderMouse) {
			case ClickableObjectType.FLOOR:
				this.floorPlane.visible = true;
				this.floorPlane.position.copy(this.collisionPoint);
				this.floorPlane.lookAt(this.camera.position.x, 100000, this.camera.position.z);
				this.floorPlane.position.y += 0.05;
				break;
			default:
				this.floorPlane.visible = false;
				break;
		}
	}
	//#endregion Mouse Move

	muteUser(userId) {
		this.socket.emit('mute-user', {
			userToMute: userId,
		});
	}

	//#region Video Controls
	switchVideoState() {
		if (this.videoIsReady) {
			if (this.videoPlaying) {
				this.videoFullScreen();
				this.playVideo();
			} else {
				this.pauseVideo();
			}
		}
	}

	screenChange() {
		window.focus();
		this.fullscreen = !this.fullscreen;
		this.restricMovement = this.fullscreen || this.room.stage;
		if (this.fullscreen) {
			PostLog(LOG_TYPE.VIDEO_FULLSCREEN, this.globalManager.user.id);
			if (this.room.stage) return;

			const screenDirection = this.playerGroup.position.clone();
			screenDirection.sub(this.objUnderMouse.position);
			screenDirection.y = 0;
			const angle = Utils.SignedAngleFromObject(this.playerGroup, screenDirection, Utils.forward);
			this.playerGroup.rotation.y += angle;
			this.sendState(0);
		} else if (!this.room.stage) {
			this.sendState(-1);
		}
	}

	videoFullScreen() {
		if (this.isYoutube) {
			document.getElementById('player').requestFullscreen();
		} else {
			this.vimeoPlayer.requestFullscreen();
		}
	}

	playVideo() {
		if (this.isYoutube) {
			this.player.playVideo();
		} else {
			this.vimeoPlayer.play();
		}
		this.videoPlaying = true;
	}

	pauseVideo() {
		if (this.isYoutube) {
			this.player.pauseVideo();
		} else {
			this.vimeoPlayer.pause();
		}
		this.videoPlaying = false;
	}
	//#endregion Video Controls

	//Camera
	CameraVisibilityPass() {
		if (this.headPosition === undefined || this.room.stage) return;

		//set visibility on for previous pass
		this.SetVisibility(this.objsInFrontOfCamera, true);
		this.objsInFrontOfCamera = [];
		//get elements between the camera and the player
		let direction = new THREE.Vector3();
		this.headPosition.getWorldPosition(direction);
		direction.sub(this.camera.position);
		const distance = direction.length();
		raycaster.setFromCamera({x: 0, y: 0}, this.camera);
		const intersects = raycaster.intersectObjects(this.sceneMeshes, false);
		for (let i = 0; i < intersects.length; i++) {
			const element = intersects[i];
			if (element.distance < distance) {
				this.objsInFrontOfCamera.push(element.object);
			}
		}
		//set visibility off for those objects
		this.SetVisibility(this.objsInFrontOfCamera, false);
	}

	SetVisibility(objArray, visible) {
		for (let i = 0; i < objArray.length; i++) {
			objArray[i].layers.set(visible ? 0 : 1);
		}
	}

	OnVolumeChange(volume) {
		if (this.isYoutube && this.player) {
			this.player.setVolume(volume);
		}
		this.masterVolume = volume / 100;
	}
	//#region emojis
	EmojiReceived(client, emoji) {
		const pos = this.GetPositionForEmojiByClient(client);
		Environment.liveFeedback(this.scene, pos, emoji);
	}

	GetPositionForEmojiByClient(client) {
		let pos = new THREE.Vector3();
		if (this.room.stage) {
			let head = getRandomInt(0, this.liveFeedbackPositions.length - 1);
			let obj = this.liveFeedbackPositions[head];
			return obj.position;
		}
		client = this.clients[client];
		pos.set(client.group.position.x, client.group.position.y + 2, client.group.position.z);
		return pos;
	}

	showMyFeedback(type) {
		let pos = new THREE.Vector3(0, 0, 0);
		pos = this.headPosition.localToWorld(pos);
		pos = this.scene.worldToLocal(pos);
		Environment.liveFeedback(this.scene, pos, type);
	}

	PrepareFeedbackPositions() {
		let heads = [];
		this.FindObjectsByName('emoji_', this.scenario, heads);
		return heads;
	}

	FindObjectsByName(name, object, array) {
		if (object.name !== 0 && object.name.indexOf(name) >= 0) {
			array.push(object);
		}
		for (let i = 0; i < object.children.length; i++) {
			this.FindObjectsByName(name, object.children[i], array);
		}
		return array;
	}

	FindObjectByName(name, parent) {
		if (parent.name.indexOf(name) >= 0) {
			return parent;
		}
		var obj = null;
		for (let i = 0; i < parent.children.length; i++) {
			obj = this.FindObjectByName(name, parent.children[i]);
			if (obj) return obj;
		}
		return null;
	}
	//#endregion emojis

	//#region ClientState
	changeClientState(client, state) {
		if (state >= 0) {
			this.clients[client].textMesh.children[1].children[0].visible = false;
			this.clients[client].textMesh.children[1].children[1].visible = false;
			this.clients[client].textMesh.children[1].visible = true;
			this.clients[client].textMesh.children[1].material =
				this.clients[client].textMesh.bubbleMaterial[state];
			this.clients[client].textMesh.children[1].material.needsUpdate = true;
			this.clients[client].textMesh.children[1].children[state].visible = true;
			return;
		}
		this.clients[client].textMesh.children[1].visible = false;
	}

	sendState(state) {
		this.socket.emit('stateAction', {room: this.room.name, state: state});
	}

	checkState() {
		const time = new Date();
		if (!this.restricMovement) {
			if (time - this.controls.afkTimer > AFKMSGTIME) {
				this.noAFKSend = false;
				if (!this.afkSend) {
					this.afkSend = true;
					PostLog(LOG_TYPE.INACTIVE, this.globalManager.user.id, true);
					this.sendState(1);
				}
				this.globalManager.setNotificationMessage(
					'You will be disconected in: ' +
						((AFKKICKTIMER - (time - this.controls.afkTimer)) / 1000).toFixed(0) +
						' seconds.'
				);

				if (time - this.controls.afkTimer > AFKKICKTIMER) {
					window.location.reload();
				}
			} else {
				this.afkSend = false;
				if (!this.noAFKSend) {
					this.noAFKSend = true;
					PostLog(LOG_TYPE.INACTIVE, this.globalManager.user.id, false);
					this.sendState(-1);
				}
			}
		}
	}
	//#endregion

	//#region MicState
	changeMicState(client, mute) {
		try {
			this.clients[client].mute = mute;
			if (this.clients[client].ready) this.clients[client].textMesh.children[0].visible = mute;
			if (this.globalManager.updateAdminPanel) this.globalManager.updateAdminPanel();
		} catch {}
	}

	sendMute(mute) {
		this.mute = mute;
		this.socket.emit('micState', {room: this.room.name, mute: mute});
	}
	//#endregion

	//#region Push Notification
	SendNotification(message, all) {
		this.socket.emit('pushNotification', {message: message, all: all, room: this.room.name});
	}
	//#endregion

	//#region Logs
	SendLog(log_type, user_id, value) {
		PostLog(log_type, user_id, value);
	}
	//#endregion Logs

	//
	SendDbRefresh() {
		this.socket.emit('dbRefresh', {});
	}

	Dispose() {
		cancelAnimationFrame(this.updateId);
		this.clearRoom();
		this.playerGroup = null;
		this.floorPlane = null;
		this.ground = [];
		this.headPosition = null;
		this.playerMixer = null;
		this.playerAnimations = [];
		this.playerActions = [];
		this.scene.background.dispose();
		this.scene.background = null;
		this.scene.environment.dispose();
		this.scene.environment = null;
		this.controls.Dispose();
		this.controls = null;
		this.camera = null;
		DisposeObj(this.scene);
		DisposeObj(this.sceneCss);
		this.renderer.dispose();
		let domElement = document.getElementById('canvas-container');
		domElement.removeChild(domElement.lastChild);
		this.socket.disconnect();
		window.removeEventListener('mousemove', this.onMouseMove, false);
		window.removeEventListener('resize', this.onWindowResize, false);
		this.videoPlaying = false;
		delete this;
	}

	async HashPwd(pwd) {
		//console.log(await bcryptjs.hash(pwd, 10));
	}

	checkPerformance(delta) {
		if (!this.globalManager.showLoadingScreen) {
			this.checkFps(delta);
		}
	}

	checkFps(delta) {
		const avgFps = this.addFps(delta);
		if (avgFps < LOW_PERFORMANCE_FPS && this.hasAntialias && fpsStack.length === FPS_STACK_LENGTH) {
			//this.DeactivateAntialias();
		}
	}

	addFps(delta) {
		const fps = 1 / delta;
		fpsStack.push(fps);
		if (fpsStack.length > FPS_STACK_LENGTH) {
			fpsStack.splice(0, 1);
		}
		let total = 0;
		for (let i = 0; i < fpsStack.length; i++) {
			total += fpsStack[i];
		}
		total /= fpsStack.length;
		return total;
	}

	async DeactivateAntialias() {
		console.log('Deactivitaing antialias');
		this.hasAntialias = false;
		this.renderer.domElement.parentElement.removeChild(this.renderer.domElement);
		this.renderer = new THREE.WebGLRenderer({
			alpha: true,
			powerPreference: 'high-performance',
		});
		this.renderer.setClearColor(0x00ff00, 0.0);
		this.renderer.setSize(this.width, this.height);
		//
		this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
		this.renderer.toneMappingExposure = 1;
		this.renderer.outputEncoding = THREE.sRGBEncoding;
		this.renderer.domElement.style.position = 'absolute';
		this.renderer.domElement.style.top = 0;
		this.renderer.domElement.style.zIndex = 1;
		this.rendererCss.domElement.appendChild(this.renderer.domElement);
		await Environment.loadHDRI(this.renderer, true);
		this.scene.background = Environment.background;
		this.scene.environment = Environment.environment;
	}

	zoomCamera() {
		//TODO
	}
}
const fpsStack = [];
