import socketIOClient from 'socket.io-client';
import * as THREE from './3D/three.module.js';
import {Scene, Animations} from './scene.js';
import {HUD} from './hud.js';
import {CharacterCreator} from './characterCreator.js';
import {DataManager} from './DataManager.js';

import {
	GetUsers,
	GetRooms,
	GetAvatars,
	PostAvatar,
	UpdateAvatar,
	GetVideos,
	PostLog,
} from './ApolloUtils.js';

const LOG_TYPE = {
	LOGIN: 1,
	LOGOUT: 2,
	INACTIVE: 3,
	CHANGE_ROOM: 4,
	CHALLENGE_COMPLETED: 5,
	CHALLENGE_RESTART: 6,
	VIDEO_FULLSCREEN: 7,
	FILE_LOADED: 8,
	CHANGE_AVATAR: 9,
	MICRO_LOGIN: 10,
};

// ---------------------------------------------------------------------------

// ---------------------------------------------------------------------------

const App = ({globalManager}) => {
	const endpoint =
		process.env.NODE_ENV === 'development'
			? 'http://localhost:4001'
			: 'https://mundo.visitavirtualmonzon.com:4001';

	let socket;
	let id;

	// WebRTC Variables:
	const {RTCPeerConnection, RTCSessionDescription} = window;
	let iceServerList;

	// Our local media stream (i.e. webcam and microphone stream)
	let localMediaStream = null;

	// Constraints for our local audio stream
	let mediaConstraints = {video: false, audio: true};

	// Selectores para elegir audio/camara
	let audioInputSelect;
	let audioOutputSelect;
	let selectors;

	// Variables de nuestras cosas ----------------------------------------------------------------------------
	let room = 0;
	let name = null;

	// HUD
	let hud;

	let first = true;
	let scene = null;

	//REFRESH DETECTION
	//let pageAccessedByReload = (
	//	(window.performance.navigation && window.performance.navigation.type === 1) ||
	//		window.performance
	//		.getEntriesByType('navigation')
	//		.map((nav) => nav.type)
	//		.includes('reload')
	//);

	//
	//Get info from DB
	GetDataAndStart();

	globalManager.returnToCC = function () {
		first = true;
		globalManager.scene.Dispose();
		globalManager.setShowMainHud(false);
		const avatar = DataManager.Instance().GetAvatarById(globalManager.user.avatar_id);
		new CharacterCreator(SaveAvatarAndConnect, globalManager, avatar);
	};

	async function GetDataAndStart() {
		globalManager.setShowCharacterConfigurator(false);
		await GetAvatars();
		await GetVideos();
		await GetRooms();
		await GetUsers();

		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		room = urlParams.get('room');
		if (room == null) {
			room = 'Hall';
		}
		name = globalManager.user.email;

		if (globalManager.user.avatar_id != null) {
			//Get the aspect from the avatar_id
			const dataManager = DataManager.Instance();
			if (dataManager.avatars.length > 0) {
				GetAvatarAndConnect();
			} else {
				dataManager.OnAvatarsReceived = () => {
					GetAvatarAndConnect();
				};
			}
			return;
		}
		globalManager.setShowCharacterConfigurator(true);
		new CharacterCreator(SaveAvatarAndConnect, globalManager);
	}

	function GetAvatarAndConnect() {
		const avatar = DataManager.Instance().GetAvatarById(globalManager.user.avatar_id);
		if (avatar != null) {
			ConnectToServer(avatar);
			//
			globalManager.setShowCharacterConfigurator(false);
			globalManager.setShowMainHud(true);
		} else {
			new CharacterCreator(SaveAvatarAndConnect, globalManager);
		}
	}

	async function SaveAvatarAndConnect(aspect, newAvatar = true) {
		if (newAvatar) {
			if (globalManager.user.avatar_id != null) {
				await UpdateAvatar(globalManager.user.avatar_id, aspect);
				PostLog(LOG_TYPE.CHANGE_AVATAR, globalManager.user.id);
			} else {
				await PostAvatar(globalManager.user.id, aspect);
			}
			DataManager.Instance().UpdateAvatar(globalManager.user.avatar_id, aspect);
		}

		//
		globalManager.setShowCharacterConfigurator(false);
		globalManager.setShowMainHud(true);

		ConnectToServer(aspect);
	}

	async function ConnectToServer(aspect) {
		// Cogemos elemos hud
		hud = new HUD(globalManager);
		// Asignamos el hud a la variable troncal
		globalManager.hud = hud;
		globalManager.gotDevices = gotDevices;
		// first get user media
		localMediaStream = await getMedia(mediaConstraints);
		localMediaStream
			? PostLog(LOG_TYPE.MICRO_LOGIN, globalManager.user.id, true)
			: PostLog(LOG_TYPE.MICRO_LOGIN, globalManager.user.id, false);

		try {
			globalManager.deviceInfos = await navigator.mediaDevices
				.enumerateDevices()
				.catch(handleError);
		} catch (e) {
			console.error(e.message);
		}
		room = DataManager.Instance().rooms[0];
		// then initialize socket connection
		initSocketConnection(aspect);

		// finally create the threejs scene
		scene = new Scene(onPlayerMove, room, socket, localMediaStream, hud, id, aspect, globalManager);
		scene.enableOutgoingStream = enableOutgoingStream;
		// Prueba de tipo de audio según room
		switch (room.name) {
			case 'Stage':
				scene.audioType = 1;
				disableOutgoingStream();
				break;
			default:
				scene.audioType = 0;
				break;
		}
	}

	///////////////////////////////
	// Local media stream setup  //
	///////////////////////////////

	// https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
	async function getMedia(_mediaConstraints) {
		let stream = null;

		try {
			//console.log('Trying to get user media');
			stream = await navigator.mediaDevices.getUserMedia(_mediaConstraints);
		} catch (err) {
			console.warn('Failed to get user media!\n' + err);
		}

		return stream;
	}

	function addTracksToPeerConnection(_stream, _pc, _id) {
		try {
			if (_stream == null) {
				//console.log('Local User media stream not yet established!');
			} else {
				//
				_stream.getTracks().forEach((track) => {
					_pc.addTrack(track, _stream);
				});
			}
		} catch (e) {
			console.error(
				'Exception adding tracks to peer connection of ' + scene.clients[_id] + ':\n' + e.message
			);
		}
	}

	// Versión nuestra para que se ajuste a todos los cleintes
	async function attachSinkIdToClientsAudios(sinkId) {
		//
		for (let _id in scene.clients) {
			let audioEl = document.getElementById(_id + '_audio');
			if (audioEl) {
				//
				if (typeof audioEl.sinkId !== 'undefined') {
					audioEl.setSinkId(sinkId).catch((error) => {
						let errorMessage = error;
						if (error.name === 'SecurityError') {
							errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
						}
						console.error(errorMessage);
						// Jump back to first output device in the list as it's the default.
					});
				} else {
					console.warn('Browser does not support output device selection.');
				}
			}
		}
	}

	function changeAudioDestination() {
		//console.log('Changing audio destination');
		const audioDestination = audioOutputSelect.value;
		attachSinkIdToClientsAudios(audioDestination);
	}

	function changeAudioDestinationFromPopupMenu(audioOutputValue) {
		const audioDestination = audioOutputValue;
		attachSinkIdToClientsAudios(audioDestination);
	}

	// Para pillar los dispositivos
	function gotDevices(deviceInfos) {
		//
		audioInputSelect = document.querySelector('select#audioSource');
		audioOutputSelect = document.querySelector('select#audioOutput');
		selectors = [audioInputSelect, audioOutputSelect];

		audioOutputSelect.disabled = !('sinkId' in HTMLMediaElement.prototype);

		// Handles being called several times to update labels. Preserve values.
		const values = selectors.map((select) => select.value);
		selectors.forEach((select) => {
			while (select.firstChild) {
				select.removeChild(select.firstChild);
			}
		});

		for (let i = 0; i !== deviceInfos.length; ++i) {
			const deviceInfo = deviceInfos[i];
			const option = document.createElement('option');
			option.value = deviceInfo.deviceId;
			if (deviceInfo.kind === 'audioinput') {
				option.text = deviceInfo.label || `microphone ${audioInputSelect.length + 1}`;
				audioInputSelect.appendChild(option);
			} else if (deviceInfo.kind === 'audiooutput') {
				option.text = deviceInfo.label || `speaker ${audioOutputSelect.length + 1}`;
				audioOutputSelect.appendChild(option);
			}
		}
		selectors.forEach((select, selectorIndex) => {
			if (
				Array.prototype.slice.call(select.childNodes).some((n) => n.value === values[selectorIndex])
			) {
				select.value = values[selectorIndex];
			}
		});

		//
		audioInputSelect.onclick = hudElementClickedThisFrame;
		audioOutputSelect.onclick = hudElementClickedThisFrame;
		//
		audioInputSelect.onchange = start;
		audioOutputSelect.onchange = changeAudioDestination;
	}

	//
	globalManager.mediaOptions = {};
	globalManager.mediaOptions.start = startFromPopupMenu;
	globalManager.mediaOptions.changeAudioDestination = changeAudioDestinationFromPopupMenu;

	function hudElementClickedThisFrame() {
		hud.clickOnHud();
	}

	function handleError(error) {
		console.error('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
	}

	function start() {
		if (window.stream) {
			window.stream.getTracks().forEach((track) => {
				track.stop();
			});
		}
		const audioSource = audioInputSelect.value;
		const constraints = {
			audio: {
				deviceId: audioSource ? {exact: audioSource} : undefined,
			},
		};

		localMediaStream = navigator.mediaDevices.getUserMedia(constraints);
		navigator.mediaDevices.enumerateDevices().catch(handleError);
	}

	async function startFromPopupMenu(audioInputValue) {
		localMediaStream.getTracks().forEach((track) => {
			track.stop();
		});
		const audioSource = audioInputValue;
		const constraints = {
			audio: {
				deviceId: audioSource ? {exact: audioSource} : undefined,
			},
		};

		clearInterval(scene.pidInterval);

		localMediaStream = await navigator.mediaDevices.getUserMedia(constraints);
		scene.localMediaStream = localMediaStream;

		for (let id in scene.clients) {
			scene.clients[id].peerConnection?.close();
			clearInterval(scene.clients[id].interval);
			let pc = createPeerConnection(id, scene.clients[id].name);
			scene.clients[id].peerConnection = pc;
			callUser(id);
		}
		scene.hud.initMicrophoneLevels(localMediaStream, scene.hud.colorPids, true);
	}

	function initSocketConnection(aspect) {
		socket = socketIOClient(endpoint, {
			query: {room: room.name, name: name, aspect: JSON.stringify(aspect)},
		});
		//pageAccessedByReload = false;

		globalManager.socket = socket;

		socket.on('connect', () => {});

		//On connection server sends the client his ID and a list of all keys
		socket.on('introduction', OnSocketIntroduction);

		// when a new user has entered the server
		socket.on('newUserConnected', OnSocketNewUserConnected);

		//
		socket.on('userDisconnected', OnSocketUserDisconnected);

		// Update when one of the users moves in space
		socket.on('userPositions', (_clientProps) => scene.updateClientPositions(_clientProps));

		socket.on('call-made', OnSocketCallMade);

		socket.io.on('reconnect', OnSocketReconnect);

		socket.on('answer-made', OnSocketAnswerMade);

		socket.on('iceCandidateFound', (data) => {
			scene.clients[data.socket].peerConnection.addIceCandidate(data.candidate);
		});

		// Nuestras --------------------------------------
		// Recibir click
		socket.on('mute-received', (data) => {
			globalManager.silenceSelf();
		});

		// Recibir broadcast
		socket.on('broadcast-receive', (data) => {
			//
			if (data.userId && data.userId !== scene.playerId) {
				disableOutgoingStream();
				//
				alert('Broadcast sent by another player \n Muting');
			}
		});

		// Petición de llamada
		socket.on('ask-call-received', (data) => {
			callUser(data.requester);
		});

		socket.on('already_connected', (data) => {
			window.location.assign(window.location.href + 'auth/login/?error=alreadyLogged');
		});
		socket.on('room_full', async (data) => {
			await scene.changeRoom('Hall');
			globalManager.setNotificationMessage('La sala está completa, pruebe más tarde');
		});

		socket.on('seat_state_changed', (data) => {
			scene.seatStateChanged(data);
		});

		socket.on('chat_message', (data) => {
			globalManager.receiveMessage(data);
		});
		socket.on('agoraAction', OnSocketAgoraAction);
		socket.on('stateAction', OnSocketStateAction);
		socket.on('micState', OnSocketMicState);
		socket.on('pushNotification', (data) => {
			globalManager.setNotificationMessage(data.message);
		});
		socket.on('dbRefresh', OnSocketDbRefresh);

		socket.on('disconnect', OnSocketDisconnect);
	}

	async function OnSocketIntroduction(
		_id,
		_name,
		_clientNum,
		_clientsJson,
		_ids,
		_namesPerRoom,
		_iceServers,
		_room,
		_roomName,
		reconnecting
	) {
		const previousRoom = room ? room.name : '';
		_room.name = _roomName;
		room = _room;
		if (reconnecting && scene.room !== undefined) {
			scene.playerId = _id; // Esta parece que se pisa ---------------------------------
			id = _id;
			return;
		}
		scene.SetRoom(room);
		const _clients = JSON.parse(_clientsJson);
		// keep local copy of ice servers:
		iceServerList = _iceServers;

		scene.playerId = _id; // Esta parece que se pisa ---------------------------------
		id = _id;
		scene.playerName = _name;
		scene.globalManager.seats = _room.seats;
		hud.setPlayerList(JSON.parse(_namesPerRoom));
		if (first) {
			scene.initEnvironment(room);
			scene.room = room;
			scene.sendMute(scene.localMediaStream == null);
		}
		if (!first) {
			//if(_room = scene.room) return;
			scene.changeScenario(_room, true, false, previousRoom);
		}
		first = false;

		// for each existing user, add them as a client and add tracks to their peer connection
		for (let i = 0; i < _ids.length; i++) {
			const client = _clients[_ids[i]];
			client.id = _ids[i];
			if (client.id !== scene.playerId) {
				client.aspect = JSON.parse(client.aspect);
				await addClient(client);
				// Si tenemos local media stream llamamos
				if (localMediaStream) {
					callUser(client.id);
				} else {
					// Si no pedimos que nos llamen
					socket.emit('ask-call', {
						requester: scene.playerId,
						destinatary: client.id,
					});
				}
			}
		}
	}

	async function OnSocketNewUserConnected(
		clientCount,
		_id,
		_name,
		_aspect,
		_ids,
		_roomName,
		_mute
	) {
		//let time = Date.now();
		if (_id === scene.playerId) return;
		//
		if (_roomName !== room.name) {
			hud.addUserToRoom(_name, _roomName);
			return;
		}
		//

		let alreadyHasUser = false;
		for (let i = 0; i < Object.keys(scene.clients).length; i++) {
			if (Object.keys(scene.clients)[i] === _id) {
				alreadyHasUser = true;
				break;
			}
		}
		if (!alreadyHasUser) {
			await addClient({
				id: _id,
				name: _name,
				aspect: JSON.parse(_aspect),
				position: new THREE.Vector3(),
				rotation: [0, 0, 0, 1],
				mute: _mute,
			});
			hud.addUserToRoom(_name, _roomName);
		}
		//console.log("	Shocket Connection:",Date.now()-time,"ms");
	}

	function OnSocketUserDisconnected(clientCount, _id, _name, _roomName) {
		if (_id === scene.playerId) return;
		hud.removeUserFromRoom(_name, _roomName);
		//
		if (_roomName !== room.name || room.stage) return;
		// Update the data from the server
		scene.removeClient(_id);
		delete scene.clients[_id];
	}

	function OnSocketReconnect() {
		onPlayerMove(scene.getPlayerPosition());
		scene.sendMute(scene.mute);
	}

	async function OnSocketCallMade(data) {
		// set remote session description to incoming offer
		await scene.clients[data.socket].peerConnection.setRemoteDescription(
			new RTCSessionDescription(data.offer)
		);

		// create answer and set local session description to that answer
		const answer = await scene.clients[data.socket].peerConnection.createAnswer();
		await scene.clients[data.socket].peerConnection.setLocalDescription(
			new RTCSessionDescription(answer)
		);

		// send answer out to caller
		socket.emit('make-answer', {
			answer,
			to: data.socket,
		});
	}

	async function OnSocketAnswerMade(data) {
		// set the remote description to be the incoming answer
		await scene.clients[data.socket].peerConnection
			.setRemoteDescription(new RTCSessionDescription(data.answer))
			.then(function (data) {
				//
			})
			.catch(handleError);

		// what is this for?
		if (!scene.clients[data.socket].isAlreadyCalling) {
			callUser(data.socket);
			scene.clients[data.socket].isAlreadyCalling = true;
		}
	}

	function OnSocketAgoraAction(data) {
		if (data.client === scene.playerId || scene.room.name !== data.room) return;
		scene.EmojiReceived(data.client, data.action);
	}

	function OnSocketStateAction(data) {
		if (data.client === scene.playerId) return;
		scene.changeClientState(data.client, data.state);
	}

	function OnSocketMicState(data) {
		if (data.client === scene.playerId) return;
		scene.changeMicState(data.client, data.mute);
	}

	async function OnSocketDbRefresh(data) {
		await GetAvatars();
		await GetVideos();
		await GetRooms();
		await GetUsers();
		if (globalManager.RefreshRoomInfo) globalManager.RefreshRoomInfo();
	}

	function OnSocketDisconnect(reason) {
		console.error('SOCKET::Server connection lost');
		if (scene.room.name !== 'Stage') {
			scene.seatsArray.forEach((seat) => {
				if (seat.occupy === scene.playerId) {
					scene.isSeated = false;
					scene.PlayAction(scene.playerActions, Animations.Idle);
					seat.occupy = null;
				}
			});
		}

		if (reason !== 'io server disconnect' && reason !== 'io client disconnect') {
			socket.io.opts.query.reconnecting = true;
			socket.io.opts.query.room = scene.room.name;
		}
	}

	async function addClient(client) {
		scene.clients[client.id] = {};
		scene.clients[client.id].id = client.id;
		scene.clients[client.id].name = client.name;
		scene.clients[client.id].aspect = client.aspect;
		scene.clients[client.id].mute = client.mute;

		if (!scene.room.stage) {
			// add peerConnection to the client
			let pc = createPeerConnection(client.id, client.name);
			scene.clients[client.id].peerConnection = pc;

			// boolean for establishing WebRTC connection
			scene.clients[client.id].isAlreadyCalling = false;

			createClientMediaElements(client.id);
		}

		// add client to scene:
		await scene.addClient(client);
	}

	// this function sets up a peer connection and corresponding DOM elements for a specific client
	function createPeerConnection(_id, _name) {
		// create a peer connection for  client:
		const peerConnectionConfiguration = {iceServers: iceServerList};

		// TODO: Revisar error en firefox
		// WebRTC: Using more than two STUN/TURN servers slows down discovery
		// WebRTC: Using five or more STUN/TURN servers causes problems
		let pc = new RTCPeerConnection(peerConnectionConfiguration);

		// add ontrack listener for peer connection
		pc.ontrack = function ({streams: [_remoteStream]}) {
			let audioStream = new MediaStream([_remoteStream.getAudioTracks()[0]]);

			// get access to the audio element:
			const audioEl = document.getElementById(_id + '_audio');
			if (audioEl) {
				audioEl.srcObject = audioStream;
				scene.userAudioAdded(_name, _remoteStream, _id);
			}
		};

		// https://www.twilio.com/docs/stun-turn
		// Here's an example in javascript
		pc.onicecandidate = function (evt) {
			if (evt.candidate) {
				//console.log('OnICECandidate: Forwarding ICE candidate to peer.');
				// send the candidate to the other party via your signaling channel
				socket.emit('addIceCandidate', {
					candidate: evt.candidate,
					to: _id,
				});
			}
		};

		addTracksToPeerConnection(localMediaStream, pc, _id);

		return pc;
	}

	async function callUser(id) {
		if (scene.room.stage) return;
		if (scene.clients.hasOwnProperty(id)) {
			// https://blog.carbonfive.com/2014/10/16/webrtc-made-simple/
			// create offer with session description
			const offer = await scene.clients[id].peerConnection.createOffer();
			await scene.clients[id].peerConnection.setLocalDescription(new RTCSessionDescription(offer));

			socket.emit('call-user', {
				offer,
				to: id,
			});
		}
	}

	// temporarily pause the outgoing stream
	function disableOutgoingStream() {
		try {
			if (localMediaStream) {
				localMediaStream.getTracks().forEach((track) => {
					if (track.kind === 'audio') {
						track.enabled = false;
					}
				});
			}
		} catch (e) {
			console.error('Exception disabling outgoing stream:\n' + e.message);
		}
	}

	// enable the outgoing stream
	function enableOutgoingStream() {
		try {
			if (localMediaStream) {
				localMediaStream.getTracks().forEach((track) => {
					track.enabled = true;
				});
			}
		} catch (e) {
			console.error('Exception enabling outgoing stream:\n' + e.message);
		}
	}

	//
	globalManager.streamControl = {};
	globalManager.streamControl.disableOutgoingStream = disableOutgoingStream;
	globalManager.streamControl.enableOutgoingStream = enableOutgoingStream;

	function onPlayerMove(playerPosition) {
		socket.emit('move', playerPosition);
	}

	function createClientMediaElements(_id) {
		// create audio element for client
		const audioEl = document.createElement('audio'); // TODO: Habrá que gestionar varios
		audioEl.setAttribute('id', _id + '_audio');
		audioEl.controls = 'controls';
		audioEl.autoplay = 'autoplay';
		audioEl.volume = 1;
		//
		if (localMediaStream) {
			audioEl.addEventListener('loadeddata', () => {
				audioEl.play();
			});
		}
		//
		document.body.appendChild(audioEl);
	}

	return (
		<div id='canvas-parent'>
			<div id='canvas-container'></div>
			<div id='player'></div>
		</div>
	);
};
export default App;
