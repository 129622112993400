import * as THREE from './3D/three.module.js';

export function MoveTowards(current, target, maxDistanceDelta) {
	const num1 = target.x - current.x;
	const num2 = target.y - current.y;
	const num3 = target.z - current.z;
	const num4 = num1 * num1 + num2 * num2 + num3 * num3;
	if (num4 === 0 || (maxDistanceDelta >= 0 && num4 <= maxDistanceDelta * maxDistanceDelta)) {
		return target;
	}

	const num5 = Math.sqrt(num4);
	return new THREE.Vector3(
		current.x + (num1 / num5) * maxDistanceDelta,
		current.y + (num2 / num5) * maxDistanceDelta,
		current.z + (num3 / num5) * maxDistanceDelta
	);
}

export function getRandomInt(min, max) {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function SignedAngle(from, to, axis) {
	const num1 = from.angleTo(to);
	const num2 = from.y * to.z - from.z * to.y;
	const num3 = from.z * to.x - from.x * to.z;
	const num4 = from.x * to.y - from.y * to.x;
	const num5 = Math.sign(axis.x * num2 + axis.y * num3 + axis.z * num4);
	if (num5 === 0) {
		return num1;
	}
	return num1 * num5;
}

export function SignedAngleFromObject(obj, direction, reference) {
	const forwardOfObj = reference
		.clone()
		.applyQuaternion(obj.quaternion)
		.projectOnPlane(up)
		.normalize();
	return SignedAngle(forwardOfObj, direction, up);
}

export const forward = new THREE.Vector3(0, 0, 1);
export const backward = new THREE.Vector3(0, 0, -1);
export const left = new THREE.Vector3(1, 0, 0);
export const right = new THREE.Vector3(-1, 0, 0);
export const up = new THREE.Vector3(0, 1, 0);

const MAX_NAME_LENGTH = 15;

export function GetDisplayName(user) {
	let nameSurname = CutName(user.name + ' ' + user.surname);
	return nameSurname;
}

export function GetDisplayPosition(user) {
	let position = CutName(user.position);
	return position;
}

function CutName(name) {
	if (name.length > MAX_NAME_LENGTH) {
		name = name.slice(0, MAX_NAME_LENGTH - 1) + '...';
	}
	return name;
}

export function DisposeObj(obj) {
	if (obj === null || obj === undefined) return;
	//
	obj.traverse((obj) => {
		if (obj.type === 'Mesh' || obj.type === 'SkinnedMesh') {
			obj.geometry.dispose();
			if (obj.material.normalMap != null) {
				obj.material.normalMap.dispose();
			}
			if (obj.material.roughnessMap != null) {
				obj.material.roughnessMap.dispose();
			}
			if (obj.material.metalnessMap != null) {
				obj.material.metalnessMap.dispose();
			}
			if (obj.material.map != null) {
				obj.material.map.dispose();
			}
			if (obj.skeleton != null) {
				obj.skeleton.dispose();
				if (obj.skeleton.boneTexture != null) {
					obj.skeleton.boneTexture.dispose();
				}
			}
			obj.material.dispose();
			try {
				obj.dispose();
			} catch (e) {}
		}
	});
}

export function sortingFunc(a, b) {
	if (a.name < b.name) {
		return -1;
	} else if (b.name.toLowerCase() < a.name.toLowerCase()) {
		return 1;
	}
	return 0;
}

export function clone(model) {
	const clone = model.clone();

	// Reset the cloned skinnedMeshes (if any)
	resetClonedSkinnedMeshes(model, clone);

	return clone;
}

function resetClonedSkinnedMeshes(source, clone) {
	const clonedMeshes = [];
	const meshSources = {};
	const boneClones = {};

	parallelTraverse(source, clone, function (sourceNode, clonedNode) {
		if (sourceNode.isSkinnedMesh) {
			meshSources[clonedNode.uuid] = sourceNode;
			clonedMeshes.push(clonedNode);
		}
		if (sourceNode.isBone) boneClones[sourceNode.uuid] = clonedNode;
	});

	for (let i = 0, l = clonedMeshes.length; i < l; i++) {
		const clone = clonedMeshes[i];
		const sourceMesh = meshSources[clone.uuid];
		const sourceBones = sourceMesh.skeleton.bones;

		clone.skeleton = sourceMesh.skeleton.clone();
		clone.bindMatrix.copy(sourceMesh.bindMatrix);

		clone.skeleton.bones = sourceBones.map(function (bone) {
			return boneClones[bone.uuid];
		});

		clone.bind(clone.skeleton, clone.bindMatrix);
	}
}

function parallelTraverse(a, b, callback) {
	callback(a, b);

	for (let i = 0; i < a.children.length; i++) {
		parallelTraverse(a.children[i], b.children[i], callback);
	}
}
