import {DataManager} from './DataManager.js';
import {Scene} from './scene.js';
// Varaibles externas
let hud;
// Clase para almacenar todas las divs y demás que harán de HUD
export class HUD {
	// Variables
	// Constructor
	constructor(globalManager) {
		//
		hud = this;
		//
		this.globalManager = globalManager;
		// Panel con info de usuario
		this.userInfoPanel = document.getElementById('UserPanel');
		this.userIdLabel = document.getElementById('UserID');
		this.audioConfirmationPanel = document.getElementById('AudioConfirmationPanel');
	}

	initVolumeSlider() {
		var slider = document.getElementById('volumeRange');
		var output = document.getElementById('volumeValue');
		output.innerHTML = slider.value;

		//slider.oninput = this.onVolumeInput;
	}

	onVolumeInput() {
		console.log(this.value);
	}

	showUserInfoPanel(data) {
		//
		const client = Scene.glScene.clients[data.id];
		if (data.id && client !== undefined) {
			this.userInfoId = data.id;
			const user = DataManager.Instance().FindUserByEmail(client.name);
			if (user) {
				this.userIdLabel.innerHTML = `${user.name} ${user.surname}|${user.position}`;
			} else {
				this.userIdLabel.innerHTML = `Usuario: ${client.name}`;
			}
		}
		//
		this.userInfoPanel.hidden = false;
	}

	getUserInfo(id) {
		const client = Scene.glScene.clients[id];
		const user = DataManager.Instance().FindUserByEmail(client.name);
		return user;
	}

	hideUserInfoPanel() {
		hud.clickOnHud();
		hud.userInfoPanel.hidden = true;
	}

	muteUser() {
		Scene.glScene.muteUser(hud.userInfoId);
	}

	//
	clickOnHud() {
		Scene.glScene.hudElementClickedThisFrame = true;
		setTimeout(hud.unlockClick, 250);
	}

	scrollOnHub() {}

	//
	lockClick() {
		Scene.glScene.hudElementClickedThisFrame = true;
	}

	//
	unlockClick() {
		Scene.glScene.hudElementClickedThisFrame = false;
	}

	loseChatFocus() {
		if (document.getElementById('chat-field')) {
			document.getElementById('chat-field').blur();
		}
		if (document.getElementById('questions-field')) {
			document.getElementById('questions-field').blur();
		}
	}

	setPlayerList(playerList) {
		this.playerList = playerList;
		if (this.globalManager.userChangeRoom) this.globalManager.userChangeRoom();
	}

	addUserToRoom(userName, roomName) {
		for (let room in this.playerList) {
			if (room === roomName) {
				this.playerList[room].push(userName);
				if (this.globalManager.userChangeRoom) this.globalManager.userChangeRoom();
				return;
			}
		}
		this.playerList[roomName] = [];
		this.playerList[roomName].push(userName);
		if (this.globalManager.userChangeRoom) this.globalManager.userChangeRoom();
	}

	removeUserFromRoom(user, room) {
		let index = this.playerList[room].indexOf(user);
		if (index >= 0) {
			this.playerList[room].splice(index, 1);
		}
		if (this.globalManager.userChangeRoom) this.globalManager.userChangeRoom();
	}

	initMicrophoneLevels(stream, volumeCallback, isPlayer = false) {
		let audioContext;
		let analyser;
		let microphone;

		if (isPlayer) {
			if (!this.audioContext) {
				this.audioContext = new AudioContext();
				this.analyser = this.audioContext.createAnalyser();
			} else {
				this.microphone.disconnect(this.analyser);
			}

			this.microphone = this.audioContext.createMediaStreamSource(stream);

			audioContext = this.audioContext;
			analyser = this.analyser;
			microphone = this.microphone;
		} else {
			audioContext = new AudioContext();
			analyser = audioContext.createAnalyser();
			microphone = audioContext.createMediaStreamSource(stream);
		}

		analyser.fftSize = 512;
		analyser.minDecibels = -127;
		analyser.maxDecibels = 0;
		analyser.smoothingTimeConstant = 0.4;

		microphone.connect(analyser);
		const volumes = new Uint8Array(analyser.frequencyBinCount);

		return setInterval(() => {
			if (stream !== this.globalManager.scene.localMediaStream && isPlayer) return;
			analyser.getByteFrequencyData(volumes);
			let volumeSum = 0;
			for (const volume of volumes) volumeSum += volume;
			const averageVolume = volumeSum / volumes.length;
			volumeCallback(averageVolume);
			//
			this.volume = averageVolume;
			if (isPlayer) this.globalManager.updateVolume(averageVolume);
		}, 100);
	}

	colorPids(vol) {
		// Chequeo para errores
		let pids_dom = document.getElementById('pids-wrapper');
		if (!pids_dom) return;
		//
		let all_pids = document.getElementById('pids-wrapper').children;
		let pidArray = [];
		for (let i = 0; i < all_pids.length; i++) {
			const element = all_pids[i];
			pidArray.push(element);
		}
		let amout_of_pids = Math.round(vol / 10);
		let elem_range = pidArray.slice(0, amout_of_pids);
		for (let i = 0; i < pidArray.length; i++) {
			pidArray[i].style.backgroundColor = '#e6e7e8';
		}
		for (let i = 0; i < elem_range.length; i++) {
			elem_range[i].style.backgroundColor = '#27ff00';
		}
	}
}
