import {useLayoutEffect, useState} from 'react';
import {UserModel} from '../auth/models/UserModel';

interface MessageModel {
	user: UserModel;
	type: 'in' | 'out';
	text: string;
	time: string;
	template?: boolean;
}

function ChatPopup(args: any) {
	let gmRef = args.globalManager;
	gmRef.newMessages = 0;

	let bufferMessages = gmRef.currentMessages;
	if (!bufferMessages) {
		bufferMessages = [];
		gmRef.currentMessages = bufferMessages;
	}

	let [message, setMessage] = useState<string>('');
	const [messages, setMessages] = useState<MessageModel[]>(bufferMessages);

	const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (e.keyCode === 13 && e.shiftKey === false) {
			e.preventDefault();
			sendMessage();
		}
	};

	function ToBottom() {
		const cg = document.getElementById('chatScroll');
		if (cg) {
			cg.scrollTop = cg.scrollHeight;
			gmRef.chatPosition = cg.scrollHeight;
		}
	}

	function sendMessage() {
		message = message.trim();
		if (message === '') return;
		let now = new Date();
		let messageTime = now.getHours() + ':' + now.getMinutes() + ':' + now.getSeconds();

		const newMessage: MessageModel = {
			user: gmRef.user,
			type: 'out',
			text: message,
			time: messageTime,
		};

		// send answer out to caller
		gmRef.socket.emit('chat_message', {
			newMessage,
		});

		gmRef.currentMessages.push(newMessage);
		setMessages(bufferMessages);
		setMessage('');
		setTimeout(ToBottom, 100);
	}

	function focusOnChatField() {
		let chatField = document.getElementById('chat-field');
		if (chatField) chatField.focus();
	}

	args.focusAfterAnim();

	useLayoutEffect(() => {
		const cg = document.getElementById('chatScroll');
		if (cg) {
			cg.addEventListener('scroll', updatePosition);
			cg.scrollTop = gmRef.chatPosition;
			checkIsAtBottom();
		}
		return () => {
			cg?.removeEventListener('scroll', updatePosition);
		};
	});

	function updatePosition(e: any) {
		gmRef.chatPosition = e.target.scrollTop;
	}

	function checkIsAtBottom() {
		const cg = document.getElementById('chatScroll');
		const tb = document.getElementById('id-down-button');
		if (cg && tb) {
			if (cg.scrollHeight - cg.offsetHeight - cg.scrollTop === 0) {
				tb.hidden = true;
			} else {
				tb.hidden = false;
			}
		}
	}

	return (
		<>
			<div className='panel-title'>CHAT PÚBLICO</div>

			<div className='popup-panel-dot-separator' />

			<div id='chatScroll' className='chat-group' onScroll={checkIsAtBottom}>
				{messages.map((item, index) => (
					<div key={index}>
						{item.type === 'in' ? (
							<div className='message-group-in'>
								{/* <img src='/media/character-creator/man/hairs/beard4.png' alt='' /> */}
								<div className='text-group'>
									<div className='name'>{item.user.name + ' ' + item.user.surname}</div>
									<div className='text'>{item.text}</div>
								</div>
							</div>
						) : (
							<div className='message-group-out'>
								<div className='text-group'>
									<div className='name'>{item.user.name + ' ' + item.user.surname}</div>
									<div className='text'>{item.text}</div>
								</div>
								{/* <img src='/media/character-creator/man/hairs/beard4.png' alt='' /> */}
							</div>
						)}
					</div>
				))}
			</div>
			<button id='id-down-button' className='down-button' onClick={ToBottom}>
				<img src='/media/icons/castillo_monzon/flecha.png' alt='' />
			</button>

			<div className='popup-panel-dot-separator' />

			<div className='write-message-group'>
				<div className='image-group'>
					{/* <img
						className='user-image'
						src='/media/character-creator/man/hairs/beard4.png'
						alt=''
					/> */}
					<div className='input-group'>
						<div className='name'>{gmRef.user.name + ' ' + gmRef.user.surname}</div>
						<div className='input-textarea-wrapper'>
							<textarea
								className='input-textarea'
								id='chat-field'
								onChange={(e) => setMessage(e.target.value)}
								onKeyDown={onEnterPress}
								placeholder='Di algo...'
								rows={1}
								value={message}
								onClick={focusOnChatField}
							/>
						</div>
						<div className='input-button-group'>
							<button className='input-button'>
								<img
									className='input-button-image'
									src='/media/icons/castillo_monzon/send.png'
									onClick={sendMessage}
									alt=''
								/>
							</button>
						</div>
					</div>
				</div>
			</div>

			<button className='close-button' onClick={args.exitAudioOptions}>
				<img src='/media/icons/castillo_monzon/Cerrar.png' alt='' />
			</button>
		</>
	);
}

export {ChatPopup};
