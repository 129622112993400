function TransportPopup(args: any) {
	return (
		<div className='login-popup-background'>
			<div className='transport-popup'>
				<div className='message'>¿Quiere ir arriba?</div>
				<table style={{margin: '2rem'}}>
					<button
						className='accept-button'
						style={{right: '25%', position: 'relative'}}
						onClick={(e) => {
							args.acceptFunction();
							args.colseFunc();
						}}
					>
						Aceptar
					</button>
					<button
						className='cancel-button'
						style={{left: '25%', position: 'relative'}}
						onClick={(e) => args.colseFunc()}
					>
						Cancelar
					</button>
				</table>
			</div>
		</div>
	);
}

export {TransportPopup};
