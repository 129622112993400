import * as THREE from './3D/three.module.js';
import {createLiveVideoElement} from './youtube.js';
import * as Utils from './utils.js';

import {Scene} from './scene.js';
import {AsyncLoader} from './AsyncLoader.js';
import {VideoElement} from './videoElement.js';

const ADD_VIDEO = true;

export let background = null;
export let environment = null;

//#region SceneLoader
export async function createEnvironment(
	scene,
	room,
	renderer,
	doorArray,
	randomPositions,
	seatsArray,
	waitPlayerLoad,
	sceneMeshes,
	isYoutube,
	sceneRaycastObj,
	videoArray,
	lowQuality
) {
	if (waitPlayerLoad) {
		background = null;
		environment = null;
	} else {
		Scene.glScene.globalManager.ShowLoadingScreen(4);
	}
	//
	scene.clock = new THREE.Clock();
	scene.mixer = null;
	scene.name = 'Scene_' + room.name;

	//
	await loadHDRI(renderer);
	scene.background = background;
	Scene.glScene.globalManager.assetLoaded();
	//
	scene.environment = environment;
	Scene.glScene.globalManager.assetLoaded();
	// Cargamos estructura para coliders
	let modelName = room.model + '.glb';

	const doorGroup = new THREE.Group();
	scene.add(doorGroup);
	doorGroup.name = 'DoorColliders';
	doorGroup.matrixAutoUpdate = false;
	const seatsGroup = new THREE.Group();
	scene.add(seatsGroup);
	seatsGroup.name = 'SeatColliders';
	seatsGroup.matrixAutoUpdate = false;
	const path = '../assets/models/' + (lowQuality ? 'low/' : '') + modelName;
	const sceneModel = (await AsyncLoader.LoadGLBModelAsync(path)).scene;
	OnSceneModleLoaded(
		sceneModel,
		renderer,
		scene,
		room.name,
		doorArray,
		doorGroup,
		randomPositions,
		seatsArray,
		seatsGroup,
		sceneMeshes,
		sceneRaycastObj,
		videoArray
	);
	Scene.glScene.scenario = sceneModel;
	Scene.glScene.globalManager.assetLoaded();

	if (room.video !== null) {
		AddVideoToScene(new VideoElement(room.video));
	}

	// Esfera de cielo ---------------------------------------------
	const fakeSkybox = (await AsyncLoader.LoadGLBModelAsync('../assets/models/bg_sphere.glb')).scene;
	fakeSkybox.name = 'Fake Skybox';
	fakeSkybox.matrixAutoUpdate = false;
	scene.add(fakeSkybox);
	Scene.glScene.globalManager.assetLoaded();

	return sceneModel;
}

export function AddVideoToScene(video) {
	if (!ADD_VIDEO) return;

	video.prepare((video) => createLiveVideoElement(video));
}

export async function loadHDRI(renderer, force = false) {
	if (background === null || force) {
		background = await AsyncLoader.LoadEnvMapAsync('assets/MonzonSkybox.hdr', renderer);
	}

	if (environment === null || force)
		environment = await AsyncLoader.LoadEnvMapAsync('assets/environment.hdr', renderer);
}

async function OnSceneModleLoaded(
	model,
	renderer,
	scene,
	room,
	doorArray,
	doorGroup,
	randomPositions,
	seatsArray,
	seatsGroup,
	sceneMeshes,
	sceneRaycastObj,
	videoArray
) {
	let obj = null;
	try {
		model.traverse(function (child) {
			obj = child;
			if (child.name.indexOf('ascensor_') === 0) {
				const bb = new THREE.Box3();
				bb.setFromObject(child);
				if (child.name.split('_')[1].indexOf('up') === 0) {
					Scene.glScene.elevatorsUp.push(bb);
				} else {
					Scene.glScene.elevatorsDown.push(bb);
				}
			} else if (
				child.name.indexOf('floor_collider') >= 0 ||
				child.name === 'collidersuelo' ||
				child.name === 'collider_suelo'
			) {
				child.visible = false;
				Scene.glScene.ground.push(child);
				sceneRaycastObj.push(child);
			} else if (child.name.indexOf('wood_door') >= 0) {
				if (room === 'Hall') {
					let door = CreateDoorInHall(child, doorGroup);
					door.matrixAutoUpdate = false;
					doorArray.push(door);
					sceneRaycastObj.push(door);
				} else if (room !== 'Stage') {
					let door = CreateDoorToHall(child, doorGroup);
					door.matrixAutoUpdate = false;
					doorArray.push(door);
					sceneRaycastObj.push(door);
				}
				sceneMeshes.push(obj);
			} else if (child.name.indexOf('portal') >= 0) {
				let door = CreateDoorInMonzon(child, doorGroup);
				doorArray.push(door);
				sceneRaycastObj.push(door);
			} else if (child.name.indexOf('RandomPosition') >= 0) {
				if (child.position.y < 3) {
					child.position.y = -0.1;
				} else {
					child.position.y = 14.6;
				}
				randomPositions.push(child.position);
			} else if (child.name.indexOf('Asiento_') >= 0) {
				if (child.rotation.x !== 0) {
					const angle = -Utils.SignedAngleFromObject(child, Utils.forward, Utils.forward);
					child.rotation.set(0, angle, 0);
				}
				seatsArray.push(child);
				sceneRaycastObj.push(child);
			} else if (child.name.indexOf('video') === 0) {
				CreateVideoButton(child);
				videoArray.push(child);
				sceneRaycastObj.push(child);
			} else if (child.material && !child.material.transparent) {
				sceneMeshes.push(obj);
			}
			obj.matrixAutoUpdate = false;
			obj.updateMatrix();
		});
	} catch (e) {
		console.error(e);
	}
	if (doorArray.length === 0) {
		doorGroup.parent.remove(doorGroup);
	}
	if (seatsArray.length === 0) {
		seatsGroup.parent.remove(seatsGroup);
	}
	for (let i = 0; i < seatsArray.length; i++) {
		PrepareSeats(seatsGroup, seatsArray[i]);
	}

	model.name = 'Scenario';
	model.matrixAutoUpdate = false;
	scene.add(model);
	Scene.glScene.seatStateChanged(Scene.glScene.globalManager.seats);
}

function PrepareSeats(seatsGroup, seat) {
	const index = Number(seat.name.split('_')[1]);
	seat.index = index;
	seat.bb = new THREE.Box3();
	seat.bb.setFromObject(seat);
	seat.parent.remove(seat);
	seatsGroup.add(seat);
	return seat;
}
//#endregion SceneLoader

//#region Player Loader
export async function getNewPlayerModel(parent, id, aspect, player, checkLoadedAssets) {
	//let fisrstAwait = Date.now();
	let original = await AsyncLoader.GetPlayerModel(aspect.genre);
	//console.log("Player:",player);
	//console.log("	Await:",Date.now()-fisrstAwait,"ms")

	const skinName = !aspect.genre ? 'Male_Body' : 'Female_Body';

	let hairs = [];
	let beards = [];
	let chests = [];
	let trousers = [];
	let shoes = [];
	let skin = [];
	const object = Utils.clone(original.scene);
	//object.visible = player;
	object.traverse(function (child) {
		if (child.isMesh) {
			child.castShadow = false;
			child.receiveShadow = false;
			child.material.transparent = false;
		}

		if (child.name.indexOf('Hair') >= 0) {
			hairs.push(child);
		} else if (child.name.indexOf('Beard') >= 0) {
			beards.push(child);
		} else if (child.name.indexOf('Chest') >= 0) {
			chests.push(child);
		} else if (child.name.indexOf('Trousers') >= 0) {
			trousers.push(child);
		} else if (child.name.indexOf('shoes') >= 0) {
			shoes.push(child);
		} else if (child.name.indexOf(skinName) >= 0) {
			skin.push(child);
		}
	});
	orderArrays(hairs, beards, chests, trousers, shoes, skin);
	setApearance(hairs, beards, chests, trousers, shoes, skin, aspect, player);
	object.scale.x = 1;
	object.scale.y = 1;
	object.scale.z = -1;

	const geometry = new THREE.BoxBufferGeometry(0.5, 3.6, 0.2);
	const material = new THREE.MeshBasicMaterial();
	const cube = new THREE.Mesh(geometry, material);
	cube.visible = false;
	cube.rotation.set(0, 0, 0, 'XYZ');
	cube.name = id;
	cube.isPlayer = true;

	parent.add(object);
	parent.add(cube);

	if (player) {
		cube.geometry.computeBoundingBox();
		Scene.glScene.playerCube = cube;
		Scene.glScene.playerCollision = cube.geometry.boundingBox;
	}
	//
	const animations = [
		original.animations[2],
		original.animations[0],
		original.animations[5],
		original.animations[6],
		original.animations[3],
		original.animations[1],
		original.animations[4],
	];
	if (checkLoadedAssets) {
		Scene.glScene.globalManager.assetLoaded();
	}
	return {
		mixer: new THREE.AnimationMixer(object),
		animations: animations,
		id: id,
	};
}

function orderArrays(hairs, beards, chests, trousers, shoes, skin) {
	hairs.sort(Utils.sortingFunc);
	chests.sort(Utils.sortingFunc);
	trousers.sort(Utils.sortingFunc);
	shoes.sort(Utils.sortingFunc);
	skin.sort(Utils.sortingFunc);
	beards.sort(Utils.sortingFunc);
}

function setApearance(hairs, beards, chests, trousers, shoes, skin, aspect, isPlayer) {
	DisposeOtherElements(hairs, aspect.hair, isPlayer);
	DisposeOtherElements(beards, aspect.beard, isPlayer);
	DisposeOtherElements(chests, aspect.jacket, isPlayer);
	DisposeOtherElements(trousers, aspect.trousers, isPlayer);
	DisposeOtherElements(shoes, aspect.shoes, isPlayer);
	DisposeOtherElements(skin, aspect.skin_color, isPlayer);
}

function DisposeOtherElements(array, index, isPlayer) {
	for (let i = 0; i < array.length; i++) {
		const element = array[i];
		element.visible = isPlayer;
		if (i !== index) {
			element.parent.remove(element);
			Utils.DisposeObj(element);
		}
	}
}

export async function addName(name, player, material, visible) {
	const name1 = await AsyncLoader.Instance().GetName(name, 40);
	const geometry = new THREE.ShapeBufferGeometry(name1);
	//
	geometry.computeBoundingBox();
	let xMid = -0.5 * (geometry.boundingBox.max.x - geometry.boundingBox.min.x);
	let yMid = -0.5 * (geometry.boundingBox.max.y - geometry.boundingBox.min.y);
	geometry.translate(xMid, yMid, 0);

	//
	if (!material)
		material = new THREE.MeshBasicMaterial({
			color: 0xffffff,
		});
	//
	let textMesh = new THREE.Mesh(geometry, material);
	textMesh.position.set(0, 2.25, 0);

	textMesh.scale.multiplyScalar(0.002);

	const textureLoader = new THREE.TextureLoader();

	//Add Mic
	const micWidth = 26 * 1.25;
	const micHeigth = 36 * 1.25;
	const padding = 30;
	const micGeometry = new THREE.PlaneBufferGeometry(micWidth, micHeigth, 1, 1);
	micGeometry.computeBoundingBox();
	const micTexture = textureLoader.load('./media/icons/castillo_monzon/MicroSilenciado.png');
	micTexture.encoding = THREE.sRGBEncoding;
	const micMaterial = new THREE.MeshStandardMaterial({
		map: micTexture,
		transparent: true,
		depthWrite: false,
	});
	const mic = new THREE.Mesh(micGeometry, micMaterial);
	textMesh.attach(mic);
	mic.position.set(-(micWidth / 2 + padding - xMid), 0, 0.1);
	mic.rotation.set(0, 0, 0, 'XYZ');
	mic.scale.set(1, 1, 1);
	mic.renderOrder = 1;
	mic.visible = false;

	player.add(textMesh);
	textMesh.visible = visible;
	//
	const planeWidth =
		Math.abs(geometry.boundingBox.max.x - geometry.boundingBox.min.x) +
		padding * 2 +
		Math.abs(micGeometry.boundingBox.max.x - micGeometry.boundingBox.min.x) +
		80;
	const planeHeight = Math.abs(geometry.boundingBox.max.y - geometry.boundingBox.min.y) + 40;
	const iconSize = 60;

	//Add bubble
	const bubbleWidth = planeWidth / 2;
	const bubbleHeight = -(planeHeight / 2);
	const bubbleGeometry = new THREE.CircleBufferGeometry(iconSize / 2, 32);
	textMesh.bubbleMaterial = [];
	textMesh.bubbleMaterial[0] = new THREE.MeshBasicMaterial({color: 0xff7b00});
	textMesh.bubbleMaterial[1] = new THREE.MeshBasicMaterial({color: 0xff0000});
	let bubble = new THREE.Mesh(bubbleGeometry, textMesh.bubbleMaterial[1]);
	textMesh.attach(bubble);
	bubble.position.set(bubbleWidth - iconSize / 4, -bubbleHeight, 0.1);
	bubble.rotation.set(0, 0, 0, 'XYZ');
	bubble.scale.set(1, 1, 1);
	bubble.renderOrder = 1;
	bubble.visible = false;

	//Add triangle
	const videoWidth = 12 * 2;
	const videoGeometry = new THREE.PlaneBufferGeometry(videoWidth, videoWidth, 1, 1);
	const videoTexture = textureLoader.load('./media/icons/castillo_monzon/videoButton.png');
	videoTexture.encoding = THREE.sRGBEncoding;
	const videoMaterial = new THREE.MeshStandardMaterial({
		map: videoTexture,
		transparent: true,
		depthWrite: false,
	});

	const video = new THREE.Mesh(videoGeometry, videoMaterial);
	bubble.attach(video);
	video.position.set(0, 0, 0.2);
	video.rotation.set(0, 0, 0, 'XYZ');
	video.scale.set(1, 1, 1);
	video.renderOrder = 2;
	video.visible = true;

	//Add ZZZ
	const zzzWidth = 20;
	const zzzText = await AsyncLoader.Instance().GetName('zzz', zzzWidth);
	const zzzGeometry = new THREE.ShapeBufferGeometry(zzzText);
	zzzGeometry.computeBoundingBox();
	let zzzxMid = -0.5 * (zzzGeometry.boundingBox.max.x - zzzGeometry.boundingBox.min.x);
	let zzzyMid = -0.5 * (zzzGeometry.boundingBox.max.y - zzzGeometry.boundingBox.min.y);

	const zzz = new THREE.Mesh(zzzGeometry, material);
	bubble.attach(zzz);
	zzz.position.set(zzzxMid, zzzyMid, 0.2);
	zzz.rotation.set(0, 0, 0, 'XYZ');
	zzz.scale.set(1, 1, 1);
	zzz.renderOrder = 2;
	zzz.visible = true;

	//Add plane to text
	const planeGeometry = new THREE.PlaneBufferGeometry(planeWidth, planeHeight, 1, 1);
	const texture = textureLoader.load('./media/icons/castillo_monzon/name_bg.png');
	texture.encoding = THREE.sRGBEncoding;
	const planeMaterial = new THREE.MeshStandardMaterial({
		map: texture,
		transparent: true,
	});
	const plane = new THREE.Mesh(planeGeometry, planeMaterial);
	textMesh.attach(plane);
	plane.position.set(0, 0, -0.05);
	plane.rotation.set(0, 0, 0, 'XYZ');
	plane.scale.set(1, 1, 1);

	return textMesh;
}
//#endregion Player Loader

//#region Door Creation
function CreateDoorInHall(door, group) {
	let roomIdentifier = Number(door.name.split('_')[2]);
	let roomName = '';
	if (roomIdentifier === 0) {
		roomName += 'Stage';
	} else {
		roomName += 'Networking' + roomIdentifier;
	}
	const doorObj = CreateDoor(roomName);
	positionDoor(door, doorObj);
	group.add(doorObj);
	return doorObj;
}

function CreateDoorInMonzon(point, group) {
	let roomIdentifier = point.name.split('_')[2];
	let desiredPos = new THREE.Vector3();
	point.getWorldPosition(desiredPos);
	const doorObj = CreateDoor(roomIdentifier);
	positionDoor(point, doorObj, desiredPos);
	group.add(doorObj);

	if (!Scene.glScene.globalManager.lowQuality) AddNameToDoor(roomIdentifier.toUpperCase(), doorObj);

	return doorObj;
}

function CreateVideoButton(videoObj) {
	videoObj.bb = new THREE.Box3();
	videoObj.bb.name = 'videoCheck';
	videoObj.bb.setFromObject(videoObj);
}

async function AddNameToDoor(name, door) {
	if (name === 'TOP') name = 'ARRIBA';
	const name1 = await AsyncLoader.Instance().GetName(name, 30);
	const geometry = new THREE.ShapeBufferGeometry(name1);

	geometry.computeBoundingBox();
	let xMid = -0.5 * (geometry.boundingBox.max.x - geometry.boundingBox.min.x);
	let yMid = -0.5 * (geometry.boundingBox.max.y - geometry.boundingBox.min.y);
	geometry.translate(xMid, yMid, 0);

	const material = new THREE.MeshBasicMaterial({
		color: 0xffffff,
	});
	let textMesh = new THREE.Mesh(geometry, material);
	door.add(textMesh);
	textMesh.position.set(0, 2, 0);
	textMesh.scale.multiplyScalar(0.0075);
	const textureLoader = new THREE.TextureLoader();
	const padding = 30;
	const planeWidth =
		Math.abs(geometry.boundingBox.max.x - geometry.boundingBox.min.x) + padding * 2 + 80;
	const planeHeight = Math.abs(geometry.boundingBox.max.y - geometry.boundingBox.min.y) + 40;
	const planeGeometry = new THREE.PlaneBufferGeometry(planeWidth, planeHeight, 1, 1);
	const texture = textureLoader.load('./media/icons/castillo_monzon/CharacterBG_opaque.png');
	texture.encoding = THREE.sRGBEncoding;
	const planeMaterial = new THREE.MeshStandardMaterial({
		map: texture,
		transparent: true,
	});
	const plane = new THREE.Mesh(planeGeometry, planeMaterial);
	textMesh.attach(plane);
	plane.position.set(0, 0, -0.3);
	plane.rotation.set(0, 0, 0, 'XYZ');
	plane.scale.set(1, 1, 1);
}

function CreateDoorToHall(door, group) {
	const doorObj = CreateDoor('Hall');
	positionDoor(door, doorObj);
	group.add(doorObj);
	return doorObj;
}

function positionDoor(door, doorObj, pos = null) {
	const doorFwd = Utils.up
		.clone()
		.applyQuaternion(door.quaternion)
		.projectOnPlane(Utils.up)
		.normalize();
	if (pos) doorObj.position.copy(pos);
	else doorObj.position.copy(door.position);
	doorObj.position.addScaledVector(doorFwd, 1.3);
	doorObj.rotation.y = Utils.SignedAngle(Utils.forward, doorFwd, Utils.up);
	doorObj.position.y += 0.55;
	doorObj.bb = new THREE.Box3();
	doorObj.bb.name = 'doorCheck';
	doorObj.bb.setFromObject(doorObj);
}

const DoorTriggerMaterial = new THREE.ShaderMaterial({
	//uniforms: Scene.glScene.uniforms,
	fragmentShader: fragmentShader(),
	vertexShader: vertexShader(),
	transparent: true,
	side: THREE.DoubleSide,
	depthWrite: false,
});

function CreateDoor(name) {
	const geometry = new THREE.CylinderBufferGeometry(0.6, 0.6, 1, 24, 1, false);
	// create a default (white) Basic material
	DoorTriggerMaterial.uniforms = Scene.glScene.uniforms;

	const cube = new THREE.Mesh(geometry, DoorTriggerMaterial);
	name = name.charAt(0).toUpperCase() + name.slice(1);
	cube.name = name;
	return cube;
}

function vertexShader() {
	return `
	  varying vec3 vUv; 
  
	  void main() {
		vUv = position; 
  
		vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
		gl_Position = projectionMatrix * modelViewPosition; 
	  }
	`;
}

function fragmentShader() {
	return `
	#define M_PI 3.1415926535897932384626433832795
	uniform vec3 color;
	uniform float time;
	varying vec3 vUv;

	void main() {
		float realY = vUv.y + 0.5;
		float r = vUv.x * vUv.x + vUv.z * vUv.z;
		float sinTime = (sin(4.0 * time) + 1.0) / 2.0;
		float a = (1.0 - realY - (sinTime / 4.0));
		vec3 outColor = color;
		float angle =atan(vUv.x/vUv.z) + time;
		float clampSinR = ((abs(sin(4.0 * angle)) + 1.0)/2.0) * 0.1;
		float sinA = a - clampSinR;
		if( sinA <= 0.21 && sinA >= 0.01){
			float clampSinA = (0.21-sinA)/(0.21-0.01);
			float remapA = (clampSinA * 2.0)-1.0;
			if(remapA > 0.0){
				a = 1.0 - abs(remapA);
				outColor = vec3(1.0,1.0,1.0);
			}
			else{
				float t = 1.0+remapA;
				outColor = mix(color,vec3(1.0,1.0,1.0),t);
				a = a + t;
			}
		}
		else if(sinA > 0.21){
			a = (1.0 - realY) * (0.8 -(sinTime / 4.0)) + (sinTime / 4.0);
		}
		if(realY <= 0.1){
			gl_FragColor = vec4(outColor, 1);
		}
		else if(r >= 0.3){
			gl_FragColor = vec4(outColor, a);
		}
		else{
			gl_FragColor = vec4(outColor, 0);
		}
	}
`;
}
//#endregion Door Creation

export async function AddCollectables(quantity) {
	let collectables = [];
	for (let i = 0; i < quantity; i++) {
		const obj = await createCollectable();
		obj.name = 'Collectable_' + i;
		collectables.push(obj);
	}
	return collectables;
}

async function createCollectable() {
	const gltf = await AsyncLoader.GetCollectableModel();
	gltf.scale.multiplyScalar(0.75);
	gltf.boundingBox = new THREE.Box3();
	gltf.boundingBox.setFromObject(gltf);
	//gltf.scene.mixer = new THREE.AnimationMixer(gltf);
	//gltf.scene.action = gltf.scene.mixer.clipAction(gltf.animations[0]);
	//gltf.scene.action.play();
	return gltf;
}

export async function AddPDFs(num) {
	let pdfs = [];
	for (let i = 0; i < num; i++) {
		const obj = createPDFs();
		obj.name = 'PDF_';
		obj.downloadName = 'pdf_' + i + '.pdf';
		obj.path = '../assets/pdfs/pdf.pdf';
		pdfs.push(obj);
	}
	return pdfs;
}

function createPDFs() {
	const geometry = new THREE.SphereBufferGeometry(0.5, 32, 16);
	const material = new THREE.MeshBasicMaterial({color: 0xffff00});
	const sphere = new THREE.Mesh(geometry, material);

	return sphere;
}

export function createHearts(scene) {
	const _mat = new THREE.MeshBasicMaterial({});
	const geometry = new THREE.BoxBufferGeometry(0, 0, 0);
	const planeGeometry = new THREE.PlaneBufferGeometry(50, 50, 1, 1);
	const textureLoader = new THREE.TextureLoader();

	let meshAmount = 20;
	let meshes = [];

	for (let i = 0; i < meshAmount; i++) {
		meshes[i] = new THREE.Mesh(geometry, _mat);
		const mesh = meshes[i];
		mesh.position.set(0, 2, 0);
		mesh.scale.multiplyScalar(0.006);

		scene.add(meshes[i]);
		mesh.visible = false;
		mesh.available = true;
		mesh.range = 300;
		mesh.material[0] = CreateMaterialForTexture(textureLoader, './assets/heart.svg');
		mesh.material[1] = CreateMaterialForTexture(textureLoader, './assets/thumb_up.svg');
		mesh.material[2] = CreateMaterialForTexture(textureLoader, './assets/clapping.svg');

		mesh.heartAmount = 4;
		mesh.hearts = [];

		for (let j = 0; j < meshes[i].heartAmount; j++) {
			mesh.hearts[j] = new THREE.Mesh(planeGeometry, mesh.material[0]);
			const heart = mesh.hearts[j];
			mesh.attach(heart);
			heart.scale.set(1, 1, 1);
			heart.visible = true;
			heart.velocityY = 60 + Math.random() * 65;
			heart.velocityX = (Math.random() - 0.5) * 65;
		}
	}
	return meshes;
}

function CreateMaterialForTexture(textureLoader, texturePath) {
	const texture = textureLoader.load(texturePath);
	texture.encoding = THREE.sRGBEncoding;
	const mat = new THREE.MeshStandardMaterial({
		map: texture,
		transparent: true,
		depthWrite: false,
	});
	return mat;
}

export function liveFeedback(parent, position = new THREE.Vector3(), type = 0) {
	for (let i = 0; i < parent.emotes.length; i++) {
		const emotes = parent.emotes[i];
		if (emotes.available) {
			emotes.visible = true;
			emotes.timer = new Date();
			let j = 0;
			emotes.hearts.forEach((heart) => {
				heart.material = emotes.material[type];
				heart.material.needsUpdate = true;
				heart.position.set(0, 0, 0.5 * i + 0.1 * j);
				j++;
			});
			emotes.position.copy(position);
			emotes.available = false;
			break;
		}
	}
}

export function animateHearts(parent, camPos, delta) {
	camPos = camPos.clone();
	try {
		parent.emotes.forEach((mesh) => {
			if (!mesh.available) {
				if (new Date() - mesh.timer > 5000) {
					mesh.visible = false;
					mesh.available = true;
				} else {
					mesh.hearts.forEach((heart) => {
						heart.position.set(
							heart.position.x + heart.velocityX * delta,
							heart.position.y + heart.velocityY * delta,
							heart.position.z
						);
					});
					camPos.y = mesh.position.y;
					mesh.lookAt(camPos);
				}
			}
		});
	} catch {}
}
