import {useState} from 'react';

function MediaOptions(args: any) {
	const [volume, setVolume] = useState(args.globalManager.scene.masterVolume * 100);

	const [isChanging, setIsChanging] = useState(false);

	// Procesamos los device infos para montar los select
	let audioInputs: any[] = [];
	let audioOutputs: any[] = [];
	if (args.globalManager.deviceInfos) {
		args.globalManager.deviceInfos.forEach((item: any) => {
			if (item.kind === 'audioinput') audioInputs.push(item);
			if (item.kind === 'audiooutput') audioOutputs.push(item);
		});
		//
		//console.log(audioInputs);
	}

	function startNewAudioInput(e: any) {
		//console.log(e.target.value);
		args.globalManager.deviceInfos.forEach((item: any) => {
			if (item.kind === 'audioinput') {
				if (item.deviceId === e.target.value) {
					item.selected = true;
				} else {
					item.selected = false;
				}
			}
		});
		args.globalManager.mediaOptions.start(e.target.value);
	}

	function changeOutputDestination(e: any) {
		//console.log(e.target.value);
		args.globalManager.deviceInfos.forEach((item: any) => {
			if (item.kind === 'audiooutput') {
				if (item.deviceId === e.target.value) {
					item.selected = true;
				} else {
					item.selected = false;
				}
			}
		});
		args.globalManager.mediaOptions.changeAudioDestination(e.target.value);
	}

	function playAudioTest() {
		const audioEl = document.getElementsByClassName('audio-test')[0] as HTMLAudioElement;
		audioEl.play();
		//console.log(audioEl);
	}

	function changeVolume(e: any) {
		args.globalManager.onVolumeChange(e.target.value);
		setVolume(e.target.value);
	}
	function change(e: any) {
		startNewAudioInput(e);
		setIsChanging(true);
		setTimeout(() => setIsChanging(false), 2000);
	}

	return (
		<>
			<div className='popup-panel-title'>Configuración</div>
			<div className='popup-panel-dot-separator'></div>
			<div className='popup-panel-subtitle'>Audio</div>
			<div id='MediaPanel' className='custom-media-panel'>
				<div className='custom-audio-select'>
					<img width='32' src='/media/icons/castillo_monzon/headphones.png' alt='' />
					<select id='audioOutput' className='custom-select' onChange={changeOutputDestination}>
						{audioOutputs.map((item: any, index: any) => (
							<option
								key={index}
								value={item.deviceId}
								selected={item.selected ?? item.deviceId === 'default'}
							>
								{' '}
								{item.label}{' '}
							</option>
						))}
					</select>
				</div>

				<div className='volume' id='volumeDiv'>
					<div className='main'>
						<img width='32' src='/media/icons/castillo_monzon/alto-volumen.png' alt='' />
						<input
							type='range'
							min={0}
							max={100}
							defaultValue={volume}
							id='volumeRange'
							className='vSlider'
							onChange={changeVolume}
							style={{backgroundSize: volume + '% 100%'}}
						/>
					</div>
					<p className='sliderText'>
						Volumen: <span id='volumeValue'>{volume}</span>
						<span>%</span>
					</p>
				</div>

				<button className='reset-challenge-button' onClick={playAudioTest}>
					{' '}
					Test de sonido{' '}
				</button>
				<audio className='audio-test'>
					<source src='media/sounds/Bip.mp3'></source>
				</audio>

				<div className='custom-audio-select'>
					<img width='32' src='/media/icons/castillo_monzon/microphone.png' alt='' />
					<select id='audioSource' className='custom-select' onChange={change}>
						{audioInputs.map((item: any, index: any) => (
							<option
								key={index}
								value={item.deviceId}
								selected={item.selected ?? item.deviceId === 'default'}
							>
								{' '}
								{item.label}{' '}
							</option>
						))}
					</select>
				</div>
			</div>

			<div id='pids-wrapper'>
				<div className='pid'></div>
				<div className='pid'></div>
				<div className='pid'></div>
				<div className='pid'></div>
				<div className='pid'></div>
				<div className='pid'></div>
				<div className='pid'></div>
				<div className='pid'></div>
				<div className='pid'></div>
				<div className='pid'></div>
			</div>

			{isChanging ? (
				<div className='changeMicDiv'>
					<p id='changingMic'>Cambiando el micrófono...</p>
				</div>
			) : (
				''
			)}

			<div className='popup-panel-dot-separator'></div>
			<div className='popup-panel-subtitle'>Vídeo</div>

			<div className='checkbox-field' style={{padding: '1.5rem'}}>
				<input
					className='form-check-input'
					type='checkbox'
					onClick={() => {
						args.globalManager.ChangeQuality();
						args.exitAudioOptions();
					}}
					defaultChecked={args.globalManager.lowQuality}
				/>
				<label className='form-check-label' style={{marginLeft: '1.5rem'}}>
					Activar mejora de rendimiento
				</label>
			</div>

			<button className='close-button' onClick={args.exitAudioOptions}>
				<img src='/media/icons/castillo_monzon/Cerrar.png' alt='Cerrar' />
			</button>
		</>
	);
}

export {MediaOptions};
