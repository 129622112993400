import {AdminPanel} from './AdminPanel';
import {CalendarTable} from './CalendarTable';
import {ChallengeOptions} from './ChallengeOptions';
import {ChatPopup} from './ChatPopup';
import {Instructions} from './Instructions';
import {MediaOptions} from './MediaOptions';
import {NotificationPopup} from './PushNotification';
import {RoomInfo} from './RoomInfoPopup';
import {TransportPopup} from './TransportPopup';
import {UserInfo} from './UserInfoPopup';
import {UsersTable} from './UsersTable';
import {VideoPopupPanel} from './VideoPopupPanel';

function OptionsPopup(args: any) {
	var focus = true;

	function closePopupMenu() {
		args.globalManager.setShowPopupMenu(false);
		args.globalManager.hud.unlockClick();
		args.globalManager.userChangeRoom = null;
	}

	function closePopupMenuFromButton() {
		var time = 0;
		var items: any;
		switch (args.currentPopupMenu) {
			case 0:
			case 1:
				items = document.getElementsByClassName('popup-panel-left');
				items[0].style.animation = 'popupLeftExit 0.5s';
				time = 500;
				break;
			case 2:
			case 3:
			case 4:
			case 10:
				items = document.getElementsByClassName('popup-panel-right');
				items[0].style.animation = 'popupRightExit 0.5s';
				time = 500;
				break;
			case 7:
				items = document.getElementsByClassName('popup-panel-chat');
				items[0].style.animation = 'popupRightExit 0.5s';
				time = 500;
				focus = false;
				break;
		}
		setTimeout(closePopupMenu, time);
	}

	function clickOnHud(e: any) {
		//console.log(e.target.className);
		if (e.target.className === 'popup-background') {
			closePopupMenuFromButton();
		}
	}

	function goToCharacterConfigurator() {
		closePopupMenuFromButton();
		args.globalManager.setShowAgoraHud(false);
		args.globalManager.hud.clickOnHud();
		args.globalManager.returnToCC();
	}

	function focusOnChatField() {
		let chatField = document.getElementById('chat-field');
		if (chatField && focus) {
			chatField.focus();
			focus = true;
		}
	}

	args.globalManager.updateAdminPanel = () => {};

	return (
		<div className='popup-background' onClick={(e) => clickOnHud(e)}>
			{args.currentPopupMenu === 0 ? (
				<div className='popup-panel-left'>
					<MediaOptions
						globalManager={args.globalManager}
						exitAudioOptions={closePopupMenuFromButton}
					/>
				</div>
			) : (
				''
			)}
			{args.currentPopupMenu === 1 ? (
				<div className='popup-panel-left'>
					<ChallengeOptions
						globalManager={args.globalManager}
						exitAudioOptions={closePopupMenuFromButton}
					/>
				</div>
			) : (
				''
			)}
			{args.currentPopupMenu === 2 ? (
				<div className='popup-panel-right'>
					<CalendarTable
						globalManager={args.globalManager}
						exitAudioOptions={closePopupMenuFromButton}
					/>
				</div>
			) : (
				''
			)}
			{args.currentPopupMenu === 3 ? (
				<div className='popup-panel-right'>
					<UsersTable
						globalManager={args.globalManager}
						exitAudioOptions={closePopupMenuFromButton}
						focusAfterAnim={() => setTimeout(focusOnChatField, 500)}
					/>
				</div>
			) : (
				''
			)}
			{args.currentPopupMenu === 4 ? (
				<div className='popup-panel-right'>
					<Instructions />
					<button className='close-button' onClick={closePopupMenuFromButton}>
						<img src='/media/icons/castillo_monzon/Cerrar.png' alt='Cerrar' />
					</button>
				</div>
			) : (
				''
			)}
			{args.currentPopupMenu === 5 ? (
				<div className='popup-panel-room'>
					<RoomInfo
						globalManager={args.globalManager}
						exitAudioOptions={closePopupMenuFromButton}
					/>
				</div>
			) : (
				''
			)}
			{args.currentPopupMenu === 6 ? (
				<div className='popup-panel-user-info'>
					<UserInfo
						globalManager={args.globalManager}
						exitAudioOptions={closePopupMenuFromButton}
					/>
				</div>
			) : (
				''
			)}
			{args.currentPopupMenu === 7 ? (
				<div className='popup-panel-chat'>
					<ChatPopup
						globalManager={args.globalManager}
						exitAudioOptions={closePopupMenuFromButton}
						focusAfterAnim={() => setTimeout(focusOnChatField, 500)}
					/>
				</div>
			) : (
				''
			)}
			{args.currentPopupMenu === 8 ? (
				<div className='popup-panel-confirmation'>
					<div className='confirmation-text'> ¿Desea editar su avatar? </div>
					<div className='button-group'>
						<button className='confirmation-accept' onClick={goToCharacterConfigurator}>
							{' '}
							Aceptar{' '}
						</button>
						<button className='confirmation-cancel' onClick={closePopupMenuFromButton}>
							{' '}
							Cancelar{' '}
						</button>
					</div>
				</div>
			) : (
				''
			)}
			{args.currentPopupMenu === 9 ? (
				<NotificationPopup message={args.notificationMessage} colseFunc={closePopupMenu} />
			) : (
				''
			)}
			{args.currentPopupMenu === 10 ? (
				<div className='popup-panel-right'>
					<AdminPanel
						globalManager={args.globalManager}
						exitAudioOptions={closePopupMenuFromButton}
					/>
				</div>
			) : (
				''
			)}
			{args.currentPopupMenu === 11 ? (
				<div className='popup-panel-confirmation'>
					<div className='confirmation-text'> ¿Desea refrescar la base de datos? </div>
					<div className='button-group'>
						<button
							className='confirmation-accept'
							onClick={() => {
								args.globalManager.scene.SendDbRefresh();
								closePopupMenuFromButton();
							}}
						>
							{' '}
							Aceptar{' '}
						</button>
						<button
							className='confirmation-cancel'
							onClick={() => args.globalManager.showPopupMenuFromButton(10)}
						>
							{' '}
							Cancelar{' '}
						</button>
					</div>
				</div>
			) : (
				''
			)}
			{args.currentPopupMenu === 12 ? (
				<div className='popup-panel-confirmation'>
					<div className='confirmation-text'>
						{' '}
						¿Desea expulsar a este usuario?<br></br>
						<b>
							{args.objetive.name + ' ' + args.objetive.surname}
							<br></br>
							{args.objetive.email}
						</b>
					</div>
					<div className='button-group'>
						<button
							className='confirmation-accept'
							onClick={() => {
								//TODO ECHAR AL CLIENTE
								closePopupMenuFromButton();
							}}
						>
							{' '}
							Aceptar{' '}
						</button>
						<button
							className='confirmation-cancel'
							onClick={() => args.globalManager.showPopupMenuFromButton(10)}
						>
							{' '}
							Cancelar{' '}
						</button>
					</div>
				</div>
			) : (
				''
			)}
			{args.currentPopupMenu === 13 ? (
				<div className='popup-panel-confirmation'>
					<div className='confirmation-text'>
						{' '}
						¿Donde quieres mover a este usuario?<br></br>
						<b>
							{args.objetive.name + ' ' + args.objetive.surname}
							<br></br>
							{args.objetive.email}
						</b>
					</div>
					<div className='button-group'>
						<button
							className='confirmation-accept'
							onClick={() => {
								//TODO MOVER AL CLIENTE
								closePopupMenuFromButton();
							}}
						>
							{' '}
							Aceptar{' '}
						</button>
						<button
							className='confirmation-cancel'
							onClick={() => args.globalManager.showPopupMenuFromButton(10)}
						>
							{' '}
							Cancelar{' '}
						</button>
					</div>
				</div>
			) : (
				''
			)}
			{args.currentPopupMenu === 14 ? ( //VideoPopup
				<VideoPopupPanel
					url={'https://www.youtube.com/embed/' + args.globalManager.videoUrl}
					colseFunc={closePopupMenu}
				/>
			) : (
				''
			)}
			{args.currentPopupMenu === 15 ? ( //TransportPopup
				<TransportPopup
					acceptFunction={() => args.globalManager.scene.GoToTop()}
					colseFunc={closePopupMenu}
				/>
			) : (
				''
			)}
		</div>
	);
}

export {OptionsPopup};
