function VideoPopupPanel(args: any) {
	return (
		<div className='login-popup-background'>
			<div className='video-popup'>
				<iframe
					title='Video'
					src={args.url + '?autoplay=1'}
					style={{width: '100%', height: '100%'}}
				></iframe>
				<button className='accept-button' onClick={(e) => args.colseFunc()}>
					Cerrar
				</button>
			</div>
		</div>
	);
}
export {VideoPopupPanel};
