import {useState} from 'react';
import {DataManager} from '../../Socket/DataManager';

function AdminPanel(args: any) {
	let gmRef = args.globalManager;
	let userEntries: {
		name: any;
		surname: any;
		position: any;
		description: any;
		location: any;
		email: any;
	}[] = [];

	const [muted, setMuted] = useState(true);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [usersInRoom, setUsersInRoom] = useState(false);
	gmRef.userChangeRoom = () => {
		setUsersInRoom(!usersInRoom);
	};

	let rooms = Object.keys(gmRef.hud.playerList);

	DataManager.Instance().users.forEach((user: any) => {
		rooms.forEach((room: any) => {
			gmRef.hud.playerList[room].forEach((userInRoom: any) => {
				if (user.email === userInRoom) {
					userEntries.push({
						name: user.name,
						surname: user.surname,
						position: user.position,
						description: user.description,
						location: room,
						email: user.email,
					});
				}
			});
		});
		userEntries.sort((a, b) =>
			a.name > b.name
				? 1
				: b.name > a.name
				? -1
				: a.surname > b.surname
				? 1
				: b.surname > a.surname
				? -1
				: a.position > b.position
				? 1
				: b.position > a.position
				? -1
				: 0
		);
	});

	let [roomFilter, setRoomFilter] = useState<boolean>(false);
	let [filter, setFilter] = useState<string>('');
	aplyFilter();

	let [local, localNotification] = useState<boolean>(false);
	let [notification, setNotification] = useState<string>('');

	const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (e.keyCode === 13 && e.shiftKey === false) {
			e.preventDefault();
			setFilter(filter);
		}
	};

	const onEnterPressSendNotification = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (e.keyCode === 13 && e.shiftKey === false) {
			e.preventDefault();
			sendNotification();
		}
	};

	function aplyFilter() {
		let mFilter = filter.trim();
		mFilter.toLowerCase();
		if (mFilter === '') {
			if (roomFilter) {
				userEntries.sort((a, b) =>
					a.location > b.location
						? 1
						: b.location > a.location
						? -1
						: a.name > b.name
						? 1
						: b.name > a.name
						? -1
						: a.surname > b.surname
						? 1
						: b.surname > a.surname
						? -1
						: a.position > b.position
						? 1
						: b.position > a.position
						? -1
						: 0
				);
			}
			return;
		}

		userEntries = [];

		DataManager.Instance().users.forEach((user: any) => {
			rooms.forEach((room: any) => {
				gmRef.hud.playerList[room].forEach((userInRoom: any) => {
					if (
						user.email === userInRoom &&
						(user.surname.toLowerCase().indexOf(mFilter) >= 0 ||
							[user.position, ' ', user.name, ' ', user.surname]
								.join('')
								.toLowerCase()
								.indexOf(mFilter) >= 0 ||
							[user.name, ' ', user.surname].join('').toLowerCase().indexOf(mFilter) >= 0)
					) {
						userEntries.push({
							name: user.name,
							surname: user.surname,
							position: user.position,
							description: user.description,
							location: room,
							email: user.email,
						});
					}
				});
			});
		});
		if (roomFilter) {
			userEntries.sort((a, b) =>
				a.location > b.location
					? 1
					: b.location > a.location
					? -1
					: a.name > b.name
					? 1
					: b.name > a.name
					? -1
					: a.surname > b.surname
					? 1
					: b.surname > a.surname
					? -1
					: a.position > b.position
					? 1
					: b.position > a.position
					? -1
					: 0
			);
		} else {
			userEntries.sort((a, b) =>
				a.name > b.name
					? 1
					: b.name > a.name
					? -1
					: a.surname > b.surname
					? 1
					: b.surname > a.surname
					? -1
					: a.position > b.position
					? 1
					: b.position > a.position
					? -1
					: 0
			);
		}
	}

	function sendNotification() {
		notification = notification.trim();
		if (notification === '') return;
		gmRef.scene.SendNotification(notification, local);
	}

	function muteUser(email: any) {
		let id;
		for (let c in gmRef.scene.clients) {
			if (gmRef.scene.clients[c].name === email) {
				id = c;
				gmRef.scene.clients[c].mute = !gmRef.scene.clients[c].mute;
				break;
			}
		}
		gmRef.scene.muteUser(id);
		setMuted(!muted);
	}

	function getMicroIcon(email: any) {
		let muted;
		for (let c in gmRef.scene.clients) {
			if (gmRef.scene.clients[c].name === email) {
				muted = gmRef.scene.clients[c].mute;
				break;
			}
		}
		if (!muted) {
			return '/media/icons/castillo_monzon/microphone2.png';
		} else {
			return '/media/icons/castillo_monzon/MicroSilenciado.png';
		}
	}

	function updateAdminPanel() {
		setMuted(!muted);
	}
	gmRef.updateAdminPanel = updateAdminPanel;

	return (
		<>
			<div className='popup-panel-title'>PANEL DE ADMINISTRADOR</div>
			<div className='input-refresh'>
				<div className='input-button-group'>
					<button className='input-button' onClick={(e) => gmRef.showPopupMenuFromButton(11)}>
						Refresh DB
					</button>
				</div>
			</div>

			<div className='popup-panel-dot-separator' />

			<div className='input-searchgroup'>
				<div className='input-searcharea-wrapper'>
					<textarea
						className='input-searcharea'
						id='chat-field'
						onKeyDown={onEnterPressSendNotification}
						placeholder='Escribir notificación...'
						rows={1}
						value={notification}
						onChange={(e) => setNotification(e.target.value)}
					/>
				</div>
				<div className='input-button-group'>
					<button className='input-button'>
						<img
							className='input-button-image'
							src='/media/icons/castillo_monzon/send.png'
							onClick={sendNotification}
							alt=''
						/>
					</button>
				</div>
				<div className='new-checkbox-field'>
					<input
						className='form-check-input'
						type='checkbox'
						id='room-filter'
						onClick={() => localNotification(!local)}
					/>
					<span className='form-check-label'>
						{''}Mensaje global{''}
					</span>
				</div>
			</div>

			<div className='popup-panel-dot-separator' />

			<table className='admin-table'>
				<thead>
					<tr>
						<th>Nombre y cargo</th>
						<th>Descripción personal</th>
						<th>Ubicación</th>
						<th>
							<div className='input-searchgroup'>
								<div className='input-searcharea-wrapper'>
									<textarea
										className='input-searcharea'
										id='chat-field'
										onChange={(e) => setFilter(e.target.value)}
										onKeyDown={onEnterPress}
										placeholder='Filtrar lista...'
										rows={1}
										value={filter}
									/>
								</div>
								<div className='new-checkbox-field'>
									<input
										className='form-check-input'
										type='checkbox'
										id='room-filter'
										onClick={() => setRoomFilter(!roomFilter)}
									/>
									<span className='form-check-label'>
										{''}Nombre/Sala{''}
									</span>
								</div>
							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					{userEntries.map((item, index) =>
						item.email !== gmRef.user.email ? (
							<tr key={index} className='user-row'>
								<td className='info-cell'>
									{
										//<img
										//	className='img-user'
										//	src='/media/character-creator/man/hairs/beard4.png'
										//	alt=''
										//></img>
									}
									<button className='mute-button' onClick={() => muteUser(item.email)}>
										<img className='img-mute' src={getMicroIcon(item.email)} alt='' />
									</button>
									<div className='text-section'>
										<div className='name-and-surname'>{item.name + ' ' + item.surname}</div>
										<div className='position'>{item.position}</div>
									</div>
								</td>
								<td>{item.description}</td>
								<td className='location'>{item.location}</td>
								<td className='quick-client-button'>
									<button className='input-button-expulsar' onClick={(e) => gmRef.showPopupMenuFromButton(12,item)}>
										Expulsar
									</button>
									<button className='input-button-mover' onClick={(e) => gmRef.showPopupMenuFromButton(13,item)}>
										Mover
									</button>
								</td>
							</tr>
						) : (
							<tr key={index}></tr>
						)
					)}
				</tbody>
			</table>
			<button className='close-button' onClick={args.exitAudioOptions}>
				<img src='/media/icons/castillo_monzon/Cerrar.png' alt='' />
			</button>
		</>
	);
}

export {AdminPanel};
