import {Redirect, Route, Switch} from 'react-router-dom';
import {SocketPage} from '../pages/socket/SocketPage';

export function PrivateRoutes() {
	return (
		<Switch>
			<Route path='/' component={SocketPage} />
			<Redirect from='/auth' to='/' />
			<Redirect to='error/404' />
		</Switch>
	);
}
