import * as THREE from './3D/three.module.js';
import * as Utils from './utils.js';

let scope = null;

let STATE = {NONE: -1, ROTATE: 0, ZOOM: 1, PAN: 2};
let state = STATE.NONE;

let rotateStart = new THREE.Vector2();
let rotateEnd = new THREE.Vector2();
let rotateDelta = new THREE.Vector2();

let zoomStart = new THREE.Vector2();
let zoomEnd = new THREE.Vector2();
let zoomDelta = new THREE.Vector2();

let PIXELS_PER_ROUND = 1800;
const MaxTimeToClick = 0.2;

// https://github.com/PiusNyakoojo/PlayerControls
export class PlayerControls {
	constructor(camera, player, domElement, scene) {
		this.camera = camera;
		this.player = player;
		this.domElement = domElement !== undefined ? domElement : document;
		this.scene = scene;
		// API

		this.enabled = true;

		this.center = player.position.clone();

		this.moveSpeed = 4;

		this.userZoom = true;
		this.userZoomSpeed = 1.0;

		this.userRotate = true;
		this.userRotateSpeed = 1.5;

		this.minPolarAngle = 60 * THREE.MathUtils.DEG2RAD;
		this.maxPolarAngle = 120 * THREE.MathUtils.DEG2RAD;

		this.minDistance = 1;
		this.maxDistance = 5;

		//
		this.limitLateralMovement = false;
		this.minLateralAngle = -45 * THREE.MathUtils.DEG2RAD;
		this.maxLateralAngle = 45 * THREE.MathUtils.DEG2RAD;

		// internals

		scope = this;

		this.EPS = 0.000001;

		this.phiDelta = 0;
		this.thetaDelta = 0;
		this.scale = 1;
		this.isZoom = false;

		this.desiredDirection = new THREE.Vector3(0, 0, 0);
		this.desiredPosition = new THREE.Vector3(0, 0, 0);
		this.desiredCameraPosition = new THREE.Vector3(1, 2, 1);

		this.keyState = {};

		this.clock = new THREE.Clock();

		this.afkTimer = new Date();

		// Nuestras
		// Control de pulsaod de teclas
		this.oneAvailable = true;
		this.twoAvailable = true;
		this.threeAvailable = true;
		this.nAvailable = true;
		this.mAvailable = true;
		this.bAvailable = true;
		this.controlAvailable = true;
		this.shiftAvailable = true;
		this.altAvailable = true;
		this.zoomAvailable = true;

		this.numberKeysAvailables = [];

		//
		this.verticalSpeed = 0;
		this.initEventListeners();
	}

	initEventListeners() {
		this.domElement.addEventListener(
			'contextmenu',
			function (event) {
				event.preventDefault();
			},
			false
		);
		this.domElement.addEventListener('mousedown', this.onMouseDown, false);
		this.domElement.addEventListener('mousewheel', this.onMouseWheel, false);
		this.domElement.addEventListener('DOMMouseScroll', this.onMouseWheel, false); // firefox
		this.domElement.addEventListener('mousemove', this.onMouseAFK, false);
		this.domElement.addEventListener('keydown', (e) => this.onKeyDown(e), false);
		this.domElement.addEventListener('keyup', (e) => this.onKeyUp(e), false);
	}

	update(delta) {
		if (!this.enabled) {
			return;
		}
		try {
			this.checkKeyStates(delta);
		} catch (error) {
			console.error(error);
		}

		let headPosition = new THREE.Vector3();
		if (this.scene.headPosition !== undefined) {
			this.scene.headPosition.getWorldPosition(headPosition);
		} else {
			headPosition = this.player.position;
		}
		this.center = headPosition;
		this.desiredCameraPosition = this.camera.position.clone();
		const position = this.desiredCameraPosition;
		const offset = position.clone().sub(this.center);

		// angle from z-axis around y-axis
		let theta = Math.atan2(offset.x, offset.z);

		// angle from y-axis
		let phi = Math.atan2(Math.sqrt(offset.x * offset.x + offset.z * offset.z), offset.y);

		theta += this.thetaDelta;
		phi += this.phiDelta;

		// restrict phi to be between desired limits
		phi = THREE.MathUtils.clamp(phi, this.minPolarAngle, this.maxPolarAngle);

		// restrict phi to be between this.EPS and PI-this.EPS
		phi = THREE.MathUtils.clamp(phi, this.EPS, Math.PI - this.EPS);

		if (this.limitLateralMovement) {
			theta = THREE.MathUtils.clamp(theta, this.minLateralAngle, this.maxLateralAngle);
			//theta = THREE.MathUtils.clamp(theta, this.EPS, Math.PI - this.EPS);
		}

		let radius = THREE.MathUtils.clamp(
			offset.length() * this.scale,
			this.minDistance,
			this.maxDistance
		);
		offset.x = radius * Math.sin(phi) * Math.sin(theta);
		offset.y = radius * Math.cos(phi);
		offset.z = radius * Math.sin(phi) * Math.cos(theta);

		position.copy(this.center).add(offset);
		// if (this.scene.checkAboveGround(position, 5)) {
		// 	this.camera.position.copy(position);
		// }
		this.camera.position.copy(position);
		this.camera.lookAt(headPosition);

		this.thetaDelta = 0;
		this.phiDelta = 0;
		this.scale = 1;

		// Gravedad ----------------------------
		if (!this.scene.grounded) {
			//
			this.player.position.y -= Math.min(this.verticalSpeed * delta, this.scene.groundDistance);
			this.verticalSpeed += 9.81 * delta;
		} else {
			this.verticalSpeed = 0;
		}
	}

	forceLookAt() {
		let headPosition = new THREE.Vector3();
		if (this.scene.headPosition) {
			this.scene.headPosition.getWorldPosition(headPosition);
		} else {
			headPosition = this.player.position;
		}
		this.scene.camera.lookAt(headPosition);
	}

	checkKeyStates(delta) {
		this.scene.playerMoved = false;
		//
		this.desiredDirection.set(0, 0, 0);
		let wantToMove = false;
		//
		if (!this.scene.restricMovement) {
			if (!this.scene.hudElementClickedThisFrame) {
				if (this.keyState[38] || this.keyState[87]) {
					// up arrow or 'w' - move forward
					this.desiredDirection.add(Utils.forward);
					wantToMove = true;
				}

				if (this.keyState[40] || this.keyState[83]) {
					// down arrow or 's' - move backward
					this.desiredDirection.add(Utils.backward);
					wantToMove = true;
				}

				if (this.keyState[37] || this.keyState[65]) {
					// left arrow or 'a' - rotate left
					this.desiredDirection.add(Utils.left);
					wantToMove = true;
				}

				if (this.keyState[39] || this.keyState[68]) {
					// right arrow or 'd' - rotate right
					this.desiredDirection.add(Utils.right);
					wantToMove = true;
				}
				if (wantToMove) {
					this.rotateAndMove(delta);
				}
			}
		}
		if (this.scene.globalManager.user.admin) {
			if (
				this.keyState[17] &&
				this.keyState[16] &&
				this.keyState[18] &&
				this.controlAvailable &&
				this.shiftAvailable &&
				this.altAvailable
			) {
				//CONTROL+ALT+SHIFT
				this.controlAvailable = false;
				this.shiftAvailable = false;
				this.altAvailable = false;
				console.log('Admin Mode');
				this.scene.globalManager.showPopupMenuFromButton(10);
			} else if (!(this.keyState[17] && this.keyState[16] && this.keyState[18])) {
				this.controlAvailable = true;
				this.shiftAvailable = true;
				this.altAvailable = true;
			}
		}
	}

	sendFeedback(index) {
		//this.scene.socket.emit('agoraAction', {action: index, room: this.scene.room});
	}

	rotateAndMove(delta) {
		if (this.desiredDirection.x === 0 && this.desiredDirection.z === 0) return;
		if (this.scene.isSeated) {
			this.scene.playerMoved = true;
			return;
		}
		let cameraPos = this.camera.position.y - this.player.position.y;
		const angle = Utils.SignedAngle(Utils.forward, this.desiredDirection, Utils.up);
		const cameraYAngleToCorrect = -Utils.SignedAngleFromObject(
			this.camera,
			Utils.forward,
			Utils.forward
		);
		this.player.rotation.y = cameraYAngleToCorrect + angle;

		this.desiredPosition.copy(this.player.position);
		this.desiredCameraPosition.copy(this.camera.position);
		const xMove = this.moveSpeed * delta * Math.sin(this.player.rotation.y);
		const zMove = this.moveSpeed * delta * Math.cos(this.player.rotation.y);

		this.desiredPosition.x -= xMove;
		this.desiredPosition.z -= zMove;

		this.desiredCameraPosition.x -= xMove;
		this.desiredCameraPosition.z -= zMove;

		const point = this.scene.checkAboveGround(this.desiredPosition);
		if (point) {
			this.player.position.copy(point);
			this.camera.position.copy(this.desiredCameraPosition);
		}

		this.camera.position.y = this.player.position.y + cameraPos;

		this.scene.playerMoved = true;
	}

	setEnable(enable) {
		this.enabled = enable;
		if (!enable) {
			this.playerMoved = false;
			document.removeEventListener('mousemove', scope.onMouseMove, false);
		}
	}

	getAutoRotationAngle() {
		return ((2 * Math.PI) / 60 / 60) * scope.autoRotateSpeed;
	}

	getZoomScale() {
		return Math.pow(0.95, scope.userZoomSpeed);
	}

	//#region Event Handlers
	onMouseDown(event) {
		scope.afkTimer = new Date();

		scope.clock.stop();
		scope.clock.start();
		// Si el raycast pega en otro usuario paramos aqui
		if (scope.scene.hudElementClickedThisFrame) return;

		if (scope.enabled === false) return;
		if (scope.userRotate === false) return;

		event.preventDefault();

		if (event.button === 0) {
			state = STATE.ROTATE;

			rotateStart.set(event.clientX, event.clientY);
		} else if (event.button === 1) {
			state = STATE.ZOOM;

			zoomStart.set(event.clientX, event.clientY);
		}

		document.addEventListener('mousemove', scope.onMouseMove, false);
		document.removeEventListener('mousemove', scope.onMouseAFK, false);
		document.addEventListener('mouseup', scope.onMouseUp, false);
	}

	onMouseMove(event) {
		scope.afkTimer = new Date();

		if (scope.enabled === false) return;
		//lose focus from textInput
		scope.scene.looseFocus();

		event.preventDefault();

		if (state === STATE.ROTATE) {
			rotateEnd.set(event.clientX, event.clientY);
			rotateDelta.subVectors(rotateEnd, rotateStart);
			scope.thetaDelta -=
				((2 * Math.PI * rotateDelta.x) / PIXELS_PER_ROUND) * scope.userRotateSpeed;
			scope.phiDelta -= ((2 * Math.PI * rotateDelta.y) / PIXELS_PER_ROUND) * scope.userRotateSpeed;

			rotateStart.copy(rotateEnd);
		} else if (state === STATE.ZOOM) {
			zoomEnd.set(event.clientX, event.clientY);
			zoomDelta.subVectors(zoomEnd, zoomStart);

			if (zoomDelta.y > 0) {
				scope.scale *= 1 / scope.getZoomScale();
			} else {
				scope.scale *= scope.getZoomScale();
			}

			zoomStart.copy(zoomEnd);
		}
	}

	onMouseAFK(event) {
		scope.afkTimer = new Date();
	}

	onMouseUp(event) {
		scope.afkTimer = new Date();

		if (scope.enabled === false) return;
		if (scope.userRotate === false) return;
		scope.clock.stop();
		if (scope.clock.elapsedTime <= MaxTimeToClick) {
			scope.scene.waitAndMakeRaycasting();
		}

		document.removeEventListener('mousemove', scope.onMouseMove, false);
		document.addEventListener('mousemove', scope.onMouseAFK, false);
		document.removeEventListener('mouseup', scope.onMouseUp, false);

		state = STATE.NONE;
	}

	onMouseWheel(event) {
		scope.afkTimer = new Date();

		if (scope.enabled === false) return;
		if (scope.userRotate === false) return;
		if (scope.scene.hudElementClickedThisFrame) return;

		let delta = 0;

		if (event.wheelDelta) {
			//WebKit / Opera / Explorer 9
			delta = event.wheelDelta;
		} else if (event.detail) {
			// Firefox
			delta = -event.detail;
		}
		if (delta > 0) {
			scope.scale *= scope.getZoomScale();
		} else {
			scope.scale *= 1 / scope.getZoomScale();
		}
	}

	onKeyDown(event) {
		this.afkTimer = new Date();

		event = event || window.event;
		this.keyState[event.keyCode || event.which] = true;
	}

	onKeyUp(event) {
		this.afkTimer = new Date();

		event = event || window.event;
		this.keyState[event.keyCode || event.which] = false;
	}
	//#endregion Event Handlers

	FocusLost() {
		this.keyState = {};
	}

	Dispose() {
		this.domElement.removeEventListener('mousedown', this.onMouseDown, false);
		this.domElement.removeEventListener('mousewheel', this.onMouseWheel, false);
		this.domElement.removeEventListener('DOMMouseScroll', this.onMouseWheel, false); // firefox
		this.domElement.removeEventListener('mouseup', this.onMouseUp, false);

		this.domElement.removeEventListener('keydown', (e) => this.onKeyDown(e), false);
		this.domElement.removeEventListener('keyup', (e) => this.onKeyUp(e), false);
	}
}
