import * as THREE from './3D/three.module.js';
import {Scene} from './scene.js';
import {CSS3DObject} from './libs/CSS3DRenderer.js';
import Player from '@vimeo/player';

export function createLiveVideoElement(video) {
	const material = new THREE.MeshBasicMaterial({
		color: 0x000000,
		opacity: 0.0,
		side: THREE.DoubleSide,
	});

	const geometry = new THREE.PlaneBufferGeometry();

	const planeToElement = new THREE.Mesh(geometry, material);
	planeToElement.scale.copy(video.scale);
	planeToElement.position.copy(video.position);
	//planeToElement.rotation.copy(rotation);
	planeToElement.name = 'planeForVideo';
	planeToElement.frustumCulled = true;
	Scene.glScene.sceneRaycastObj.push(planeToElement);

	Scene.glScene.scene.add(planeToElement);
	Scene.glScene.videoCollider = planeToElement;
	const playerDiv = new videoElement(video.position.x, video.position.y, video.position.z, 0);
	playerDiv.scale.multiplyScalar(video.videoScale);

	Scene.glScene.sceneCss.add(playerDiv);

	if (!video.type) {
		requestAnimationFrame(() => createVimeoPlayer(playerDiv.element.children[0], video.url));
	} // eslint-disable-next-line no-undef
	else if (!youtubeIframeApiReady) {
		// eslint-disable-next-line no-undef
		youtubeCallback = () => {
			requestAnimationFrame(() => CreateTYPlayer(playerDiv.element.children[0].id, video));
		};
	} else {
		requestAnimationFrame(() => CreateTYPlayer(playerDiv.element.children[0].id, video));
	}
}

function CreateTYPlayer(id, video) {
	// eslint-disable-next-line no-undef
	Scene.glScene.player = new YT.Player(id, {
		height: '720',

		width: '1280',

		videoId: video.url,

		events: {
			onReady: (event) => onPlayerReady(event, video),
		},
		playerVars: {
			fs: 0,
			controls: 0,
			iv_load_policy: 3,
			rel: 0,
			loop: 1,
			modestbranding: 1,
			playlist: video.url,
			showinfo: 0,
		},
	});
	try {
		Scene.glScene.player.h.src += '';
	} catch (e) {
		try {
			requestAnimationFrame(() => CreateTYPlayer(id, video));
		} catch (e) {
			console.log('Second error creating player');
		}
	}
}

function onPlayerReady(event, video) {
	//console.log('YouTube player ready');
	Scene.glScene.videoIsReady = true;
	Scene.glScene.videoPlaying = true;
	event.target.playVideo();
	event.target.setVolume(video.initialVolume);
	event.target.getIframe().onfullscreenchange = () => {
		Scene.glScene.screenChange();
	};
}

function createVimeoPlayer(playerDiv, url) {
	let options = {
		url: url, //'https://player.vimeo.com/event/1550090?h=49874b0d4', //'https://vimeo.com/event/1550090/embed', //'https://player.vimeo.com/event/1550090',//
		dnt: true,
		controls: false,
		loop: true,
		muted: false,
		quality: 'auto',
		width: 1280,
		height: 720,
		keyboard: false,
	};
	//const playerDiv = document.getElementById('player');
	Scene.glScene.vimeoPlayer = new Player(playerDiv, options);
	console.log('Viemo player created');
	Scene.glScene.vimeoPlayer.on('play', function () {
		Scene.glScene.videoPlaying = true;
	});
	Scene.glScene.vimeoPlayer.on('loaded', function () {
		console.log('Viemo player loaded');
		Scene.glScene.vimeoPlayer.element.width = '1280';
		Scene.glScene.vimeoPlayer.element.height = '720';
		Scene.glScene.vimeoPlayer.setQuality('auto');
		Scene.glScene.videoIsReady = true;
		Scene.glScene.vimeoPlayer.play();
	});
	Scene.glScene.vimeoPlayer.on('fullscreenchange', function (param) {
		if (!param.fullscreen) {
			console.log('Exiting fullscreen');
			window.focus();
		}
	});
	Scene.glScene.vimeoPlayer.play();
}

function videoElement(x, y, z, ry) {
	const div = document.createElement('div');
	div.id = 'cssDiv';

	div.style.width = '1280px';

	div.style.height = '720px';

	div.style.backgroundColor = '#000';

	let elementIframe = document.getElementById('player');
	if (elementIframe === null) {
		elementIframe = document.createElement('div');
		elementIframe.id = 'player';
	} else {
		elementIframe.parentElement.removeChild(elementIframe);
	}
	// eslint-disable-next-line no-undef
	if (!youtubeIframeApiReady) {
		const tag = document.createElement('script');

		tag.src = 'https://www.youtube.com/iframe_api';

		const firstScriptTag = document.getElementsByTagName('script')[0];

		firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
	}

	div.appendChild(elementIframe);

	const object = new CSS3DObject(div);

	object.position.set(x, y, z);

	object.rotation.y = ry;

	return object;
}
