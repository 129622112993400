import App from '../../Socket/App';
import {FC} from 'react';
import {HUD} from '../../modules/socket/hud';

import {UserModel} from '../../modules/auth/models/UserModel';
import {shallowEqual, useSelector} from 'react-redux';
import {RootState} from '../../../setup';

const SocketPage: FC = () => {
	const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel;

	let globalManager: any = {};
	globalManager.user = {
		avatar_id: user.avatar_id,
		best_time: user.best_time,
		description: user.description,
		email: user.email,
		id: user.id,
		name: user.name,
		position: user.position,
		surname: user.surname,
		admin: user.admin,
		path: user.path,
	};
	globalManager.lowQuality = (localStorage.getItem('lowQuality') ?? 'false') === 'true';
	globalManager.SetQuality = function (value: boolean) {
		globalManager.lowQuality = value;
		localStorage.setItem('lowQuality', value + '');
	};
	globalManager.chatPosition = 0;

	//
	globalManager.characterCreator = {};
	return (
		<>
			<HUD globalManager={globalManager} user={user} />
			<App globalManager={globalManager} />
		</>
	);
};

export {SocketPage};
