import {useState} from 'react';

interface SelectionField {
	name: string;
	bodyPart: string;
	buttons: SelectionButton[];
}

interface SelectionButton {
	image: string;
	image_selected: string;
	color: string;
	onclick: any;
}

function CharacterConfigurator(args: any) {
	//
	const gmRef = args.globalManager;
	let user = args.user;
	//
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_, setCharacterSelection] = useState(gmRef.characterCreator.selection);
	gmRef.setCharacterSelection = setCharacterSelection;
	//
	const [currentGender, setCurrentGender] = useState(0);

	const [currentHairColor, setCurrentHairColor] = useState(0);

	//
	let selectionButtonsInitialIndexes = {
		hair: 0,
		beard: 0,
		jacket: 0,
		trousers: 0,
	};
	const [currentSelectionButtonIndexes, setCurrentSelectionButtonIndexes] = useState(
		selectionButtonsInitialIndexes
	);

	//
	if (!user) {
		return <div></div>;
	}

	// Gender -------------------------------------------------------
	function selectGender(index: number) {
		gmRef.characterCreator.selectGender(index);
		updaterCharacterSelection();
	}
	// Hair ----------------------------------------------------------
	function selectHair(index: number) {
		gmRef.characterCreator.selectHair(index);
		updaterCharacterSelection();
	}
	// Beard -------------------------------------------------------------
	function selectBeard(index: number) {
		gmRef.characterCreator.selectBeard(index);
		updaterCharacterSelection();
	}
	// Jacket ------------------------------------------------------------
	function selectJacket(index: number) {
		gmRef.characterCreator.selectJacket(index);
		updaterCharacterSelection();
	}
	// Trousers ------------------------------------------------------------
	function selectTrousers(index: number) {
		gmRef.characterCreator.selectTrousers(index);
		updaterCharacterSelection();
	}
	// Shoes ---------------------------------------------------------------
	function selectShoes(index: number) {
		gmRef.characterCreator.selectShoes(index);
		updaterCharacterSelection();
	}
	// Skin ------------------------------------------------------------------
	function selectSkin(index: number) {
		gmRef.characterCreator.selectSkin(index);
		updaterCharacterSelection();
	}
	//
	function selectHairColor(index: number) {
		setCurrentHairColor(index);
		updaterCharacterSelection();
	}

	//
	function updaterCharacterSelection() {
		let newCharacterSelection = {
			genre: gmRef.characterCreator.selection.genre,
			hair: gmRef.characterCreator.selection.hair,
			beard: gmRef.characterCreator.selection.beard,
			jacket: gmRef.characterCreator.selection.genre,
			trousers: gmRef.characterCreator.selection.hair,
			shoes: gmRef.characterCreator.selection.beard,
			skin_color: gmRef.characterCreator.selection.genre,
			hair_color: gmRef.characterCreator.selection.hair,
		};
		setCharacterSelection(newCharacterSelection);
	}

	//
	function accept() {
		gmRef.characterCreator.toNextPage();
	}

	//
	function cancel() {
		if (!user.avatar_id || user.avatar_id === 0) {
			// Lo dejo de momneto
			// Pero probablemente no pasemos por aqui
		} else {
			gmRef.characterCreator.cancelOperation();
		}
	}

	let commonOptions: SelectionField[] = [
		{
			name: 'Sexo',
			bodyPart: 'genre',
			buttons: [
				{
					image: '/media/icons/castillo_monzon/Man_Unselect.png',
					image_selected: '/media/icons/castillo_monzon/Man_Select.png',
					color: '',
					onclick: selectGender,
				},
				{
					image: '/media/icons/castillo_monzon/Woman_Unselect.png',
					image_selected: '/media/icons/castillo_monzon/Woman_Select.png',
					color: '',
					onclick: selectGender,
				},
			],
		},
		{
			name: 'Color piel',
			bodyPart: 'skin_color',
			buttons: [
				{
					image: '',
					image_selected: '',
					color: '#ebc9ba',
					onclick: selectSkin,
				},
				{
					image: '',
					image_selected: '',
					color: '#674739',
					onclick: selectSkin,
				},
				{
					image: '',
					image_selected: '',
					color: '#ebd4ba',
					onclick: selectSkin,
				},
			],
		},
		{
			name: 'Color pelo',
			bodyPart: 'hair_color',
			buttons: [
				{
					image: '',
					image_selected: '',
					color: '#252627',
					onclick: selectHairColor,
				},
				{
					image: '',
					image_selected: '',
					color: '#f5ea91',
					onclick: selectHairColor,
				},
				{
					image: '',
					image_selected: '',
					color: '#5f4c0d',
					onclick: selectHairColor,
				},
			],
		},
	];

	//
	let manOptions: SelectionField[] = [
		{
			name: 'Estilo de pelo',
			bodyPart: 'hair',
			buttons: [
				{
					image: '/media/character-creator/man/hairs/hair1.png',
					image_selected: '',
					color: '1',
					onclick: selectHair,
				},
				{
					image: '/media/character-creator/man/hairs/hair2.png',
					image_selected: '',
					color: '2',
					onclick: selectHair,
				},
				{
					image: '/media/character-creator/man/hairs/hair3.png',
					image_selected: '',
					color: '0',
					onclick: selectHair,
				},
				{
					image: '/media/character-creator/man/hairs/hair4.png',
					image_selected: '',
					color: '1',
					onclick: selectHair,
				},
				{
					image: '/media/character-creator/man/hairs/hair5.png',
					image_selected: '',
					color: '2',
					onclick: selectHair,
				},
				{
					image: '/media/character-creator/man/hairs/hair6.png',
					image_selected: '',
					color: '0',
					onclick: selectHair,
				},
				{
					image: '/media/character-creator/man/hairs/beard4.png',
					image_selected: '',
					color: '-1',
					onclick: selectHair,
				},
			],
		},
		{
			name: 'Barbas',
			bodyPart: 'beard',
			buttons: [
				{
					image: '/media/character-creator/man/beards/beard1.png',
					image_selected: '',
					color: '0',
					onclick: selectBeard,
				},
				{
					image: '/media/character-creator/man/beards/beard2.png',
					image_selected: '',
					color: '0',
					onclick: selectBeard,
				},
				{
					image: '/media/character-creator/man/beards/beard3.png',
					image_selected: '',
					color: '0',
					onclick: selectBeard,
				},
				{
					image: '/media/character-creator/man/beards/beard5.png',
					image_selected: '',
					color: '1',
					onclick: selectBeard,
				},
				{
					image: '/media/character-creator/man/beards/beard6.png',
					image_selected: '',
					color: '1',
					onclick: selectBeard,
				},
				{
					image: '/media/character-creator/man/beards/beard7.png',
					image_selected: '',
					color: '1',
					onclick: selectBeard,
				},
				{
					image: '/media/character-creator/man/beards/beard8.png',
					image_selected: '',
					color: '2',
					onclick: selectBeard,
				},
				{
					image: '/media/character-creator/man/beards/beard9.png',
					image_selected: '',
					color: '2',
					onclick: selectBeard,
				},
				{
					image: '/media/character-creator/man/beards/beard10.png',
					image_selected: '',
					color: '2',
					onclick: selectBeard,
				},
				{
					image: '/media/character-creator/man/hairs/beard4.png',
					image_selected: '',
					color: '-1',
					onclick: selectBeard,
				},
			],
		},
		{
			name: 'Chaquetas',
			bodyPart: 'jackets',
			buttons: [
				{
					image: '/media/character-creator/man/jackets/chest7.png',
					image_selected: '',
					color: '',
					onclick: selectJacket,
				},
				{
					image: '/media/character-creator/man/jackets/chest8.png',
					image_selected: '',
					color: '',
					onclick: selectJacket,
				},
				{
					image: '/media/character-creator/man/jackets/chest9.png',
					image_selected: '',
					color: '',
					onclick: selectJacket,
				},
				{
					image: '/media/character-creator/man/jackets/chest1.png',
					image_selected: '',
					color: '',
					onclick: selectJacket,
				},
				{
					image: '/media/character-creator/man/jackets/chest2.png',
					image_selected: '',
					color: '',
					onclick: selectJacket,
				},
				{
					image: '/media/character-creator/man/jackets/chest3.png',
					image_selected: '',
					color: '',
					onclick: selectJacket,
				},
				{
					image: '/media/character-creator/man/jackets/chest4.png',
					image_selected: '',
					color: '',
					onclick: selectJacket,
				},
				{
					image: '/media/character-creator/man/jackets/chest5.png',
					image_selected: '',
					color: '',
					onclick: selectJacket,
				},
				{
					image: '/media/character-creator/man/jackets/chest6.png',
					image_selected: '',
					color: '',
					onclick: selectJacket,
				},
			],
		},
		{
			name: 'Pantalones',
			bodyPart: 'trousers',
			buttons: [
				{
					image: '/media/character-creator/man/trousers/trousers1.png',
					image_selected: '',
					color: '',
					onclick: selectTrousers,
				},
				{
					image: '/media/character-creator/man/trousers/trousers2.png',
					image_selected: '',
					color: '',
					onclick: selectTrousers,
				},
				{
					image: '/media/character-creator/man/trousers/trousers3.png',
					image_selected: '',
					color: '',
					onclick: selectTrousers,
				},
				{
					image: '/media/character-creator/man/trousers/trousers4.png',
					image_selected: '',
					color: '',
					onclick: selectTrousers,
				},
				{
					image: '/media/character-creator/man/trousers/trousers5.png',
					image_selected: '',
					color: '',
					onclick: selectTrousers,
				},
				{
					image: '/media/character-creator/man/trousers/trousers6.png',
					image_selected: '',
					color: '',
					onclick: selectTrousers,
				},
				{
					image: '/media/character-creator/man/trousers/trousers7.png',
					image_selected: '',
					color: '',
					onclick: selectTrousers,
				},
			],
		},
		{
			name: 'Zapatos',
			bodyPart: 'shoes',
			buttons: [
				{
					image: '/media/character-creator/man/shoes/shoes1.png',
					image_selected: '',
					color: '',
					onclick: selectShoes,
				},
				{
					image: '/media/character-creator/man/shoes/shoes2.png',
					image_selected: '',
					color: '',
					onclick: selectShoes,
				},
				{
					image: '/media/character-creator/man/shoes/shoes3.png',
					image_selected: '',
					color: '',
					onclick: selectShoes,
				},
				{
					image: '/media/character-creator/man/shoes/shoes4.png',
					image_selected: '',
					color: '',
					onclick: selectShoes,
				},
			],
		},
	];

	//
	let womanOptions: SelectionField[] = [
		{
			name: 'Estilo de pelo',
			bodyPart: 'hair',
			buttons: [
				{
					image: '/media/character-creator/woman/hairs/hair1.png',
					image_selected: '',
					color: '1',
					onclick: selectHair,
				},
				{
					image: '/media/character-creator/woman/hairs/hair2.png',
					image_selected: '',
					color: '2',
					onclick: selectHair,
				},
				{
					image: '/media/character-creator/woman/hairs/hair3.png',
					image_selected: '',
					color: '0',
					onclick: selectHair,
				},
				{
					image: '/media/character-creator/woman/hairs/hair4.png',
					image_selected: '',
					color: '1',
					onclick: selectHair,
				},
				{
					image: '/media/character-creator/woman/hairs/hair5.png',
					image_selected: '',
					color: '2',
					onclick: selectHair,
				},
				{
					image: '/media/character-creator/woman/hairs/hair6.png',
					image_selected: '',
					color: '0',
					onclick: selectHair,
				},
			],
		},
		{
			name: 'Chaquetas',
			bodyPart: 'jackets',
			buttons: [
				{
					image: '/media/character-creator/woman/jackets/chest1.png',
					image_selected: '',
					color: '',
					onclick: selectJacket,
				},
				{
					image: '/media/character-creator/woman/jackets/chest2.png',
					image_selected: '',
					color: '',
					onclick: selectJacket,
				},
				{
					image: '/media/character-creator/woman/jackets/chest3.png',
					image_selected: '',
					color: '',
					onclick: selectJacket,
				},
				{
					image: '/media/character-creator/woman/jackets/chest4.png',
					image_selected: '',
					color: '',
					onclick: selectJacket,
				},
				{
					image: '/media/character-creator/woman/jackets/chest5.png',
					image_selected: '',
					color: '',
					onclick: selectJacket,
				},
				{
					image: '/media/character-creator/woman/jackets/chest6.png',
					image_selected: '',
					color: '',
					onclick: selectJacket,
				},
				{
					image: '/media/character-creator/woman/jackets/chest7.png',
					image_selected: '',
					color: '',
					onclick: selectJacket,
				},
				{
					image: '/media/character-creator/woman/jackets/chest8.png',
					image_selected: '',
					color: '',
					onclick: selectJacket,
				},
				{
					image: '/media/character-creator/woman/jackets/chest9.png',
					image_selected: '',
					color: '',
					onclick: selectJacket,
				},
			],
		},
		{
			name: 'Pantalones',
			bodyPart: 'trousers',
			buttons: [
				{
					image: '/media/character-creator/woman/trousers/trousers1.png',
					image_selected: '',
					color: '',
					onclick: selectTrousers,
				},
				{
					image: '/media/character-creator/woman/trousers/trousers2.png',
					image_selected: '',
					color: '',
					onclick: selectTrousers,
				},
				{
					image: '/media/character-creator/woman/trousers/trousers3.png',
					image_selected: '',
					color: '',
					onclick: selectTrousers,
				},
				{
					image: '/media/character-creator/woman/trousers/trousers4.png',
					image_selected: '',
					color: '',
					onclick: selectTrousers,
				},
				{
					image: '/media/character-creator/woman/trousers/trousers5.png',
					image_selected: '',
					color: '',
					onclick: selectTrousers,
				},
				{
					image: '/media/character-creator/woman/trousers/trousers6.png',
					image_selected: '',
					color: '',
					onclick: selectTrousers,
				},
				{
					image: '/media/character-creator/woman/trousers/trousers7.png',
					image_selected: '',
					color: '',
					onclick: selectTrousers,
				},
				{
					image: '/media/character-creator/woman/trousers/trousers8.png',
					image_selected: '',
					color: '',
					onclick: selectTrousers,
				},
			],
		},
		{
			name: 'Zapatos',
			bodyPart: 'shoes',
			buttons: [
				{
					image: '/media/character-creator/woman/shoes/shoes1.png',
					image_selected: '',
					color: '',
					onclick: selectShoes,
				},
				{
					image: '/media/character-creator/woman/shoes/shoes2.png',
					image_selected: '',
					color: '',
					onclick: selectShoes,
				},
				{
					image: '/media/character-creator/woman/shoes/shoes3.png',
					image_selected: '',
					color: '',
					onclick: selectShoes,
				},
				{
					image: '/media/character-creator/woman/shoes/shoes4.png',
					image_selected: '',
					color: '',
					onclick: selectShoes,
				},
				{
					image: '/media/character-creator/woman/shoes/shoes5.png',
					image_selected: '',
					color: '',
					onclick: selectShoes,
				},
			],
		},
	];

	//
	let currentGenderOptions = currentGender === 0 ? manOptions : womanOptions;
	//
	let totalOptions = commonOptions.concat(currentGenderOptions);

	//
	currentGenderOptions = manOptions;

	function setCurrentOptions(gender: number) {
		setCurrentGender(gender);
	}
	gmRef.setCurrentOptions = setCurrentOptions;

	//
	function checkIfSelectedClass(
		classname: any,
		buttonIndex: any,
		bodypart: String,
		optionIndex: any
	) {
		let bodyPartIndex = getBodyPartIndex(bodypart);
		if (buttonIndex === bodyPartIndex) {
			if (optionIndex !== 0) return classname + ' custom-underline';
		}
		//
		return classname;
	}

	function getDeSelectedImage(button: SelectionButton, buttonIndex: number) {
		let genreIndex = gmRef.characterCreator.selection ? gmRef.characterCreator.selection.genre : 0;
		if (buttonIndex === genreIndex && button.image_selected !== '') return button.image_selected;
		else return button.image;
	}

	function checkIfShowSelectionButton(
		buttonIndex: any,
		bodypart: String,
		optionIndex: any,
		optionObject: SelectionField,
		button: SelectionButton
	) {
		//
		let buttonGroupIndex = 0;
		switch (bodypart) {
			case 'hair':
				buttonGroupIndex = currentSelectionButtonIndexes.hair;
				break;
			case 'beard':
				buttonGroupIndex = currentSelectionButtonIndexes.beard;
				break;
			case 'jackets':
				buttonGroupIndex = currentSelectionButtonIndexes.jacket;
				break;
			case 'trousers':
				buttonGroupIndex = currentSelectionButtonIndexes.trousers;
				break;
		}
		//
		if (bodypart === 'hair' || bodypart === 'beard') {
			if (parseInt(button.color) === currentHairColor || button.color === '-1') {
				return true;
			} else {
				return false;
			}
		}
		//
		if (optionObject.buttons.length > 5) {
			if (buttonIndex >= buttonGroupIndex && buttonIndex < buttonGroupIndex + 4) {
				return true;
			} else return false;
		} else {
			return true;
		}
	}

	function displaceOptionButtons(
		bodypart: String,
		direction: number,
		optionObject: SelectionField
	) {
		//
		let currentSelectionButtonIndexesCopy = {
			hair: currentSelectionButtonIndexes.hair,
			beard: currentSelectionButtonIndexes.beard,
			jacket: currentSelectionButtonIndexes.jacket,
			trousers: currentSelectionButtonIndexes.trousers,
		};
		//
		let buttonGroupIndex = 0;
		switch (bodypart) {
			case 'hair':
				buttonGroupIndex = currentSelectionButtonIndexesCopy.hair;
				break;
			case 'beard':
				buttonGroupIndex = currentSelectionButtonIndexesCopy.beard;
				break;
			case 'jackets':
				buttonGroupIndex = currentSelectionButtonIndexesCopy.jacket;
				break;
			case 'trousers':
				buttonGroupIndex = currentSelectionButtonIndexesCopy.trousers;
				break;
		}
		//
		buttonGroupIndex += direction;
		// TODO: Acotar el valor
		if (buttonGroupIndex < 0) buttonGroupIndex = 0;
		if (buttonGroupIndex > optionObject.buttons.length - 4)
			buttonGroupIndex = optionObject.buttons.length - 4;
		//
		switch (bodypart) {
			case 'hair':
				currentSelectionButtonIndexesCopy.hair = buttonGroupIndex;
				break;
			case 'beard':
				currentSelectionButtonIndexesCopy.beard = buttonGroupIndex;
				break;
			case 'jackets':
				currentSelectionButtonIndexesCopy.jacket = buttonGroupIndex;
				break;
			case 'trousers':
				currentSelectionButtonIndexesCopy.trousers = buttonGroupIndex;
				break;
		}
		//
		setCurrentSelectionButtonIndexes(currentSelectionButtonIndexesCopy);
	}

	function getBodyPartIndex(bodypart: String) {
		let bodyPartIndex = 0;
		switch (bodypart) {
			case 'genre':
				bodyPartIndex = gmRef.characterCreator.selection
					? gmRef.characterCreator.selection.genre
					: 0;
				break;
			case 'skin_color':
				bodyPartIndex = gmRef.characterCreator.selection
					? gmRef.characterCreator.selection.skin_color
					: 0;
				break;
			case 'hair':
				bodyPartIndex = gmRef.characterCreator.selection
					? gmRef.characterCreator.selection.hair
					: 0;
				break;
			case 'beard':
				bodyPartIndex = gmRef.characterCreator.selection
					? gmRef.characterCreator.selection.beard
					: 0;
				break;
			case 'jackets':
				bodyPartIndex = gmRef.characterCreator.selection
					? gmRef.characterCreator.selection.jacket
					: 0;
				break;
			case 'trousers':
				bodyPartIndex = gmRef.characterCreator.selection
					? gmRef.characterCreator.selection.trousers
					: 0;
				break;
			case 'shoes':
				bodyPartIndex = gmRef.characterCreator.selection
					? gmRef.characterCreator.selection.shoes
					: 0;
				break;
			case 'hair_color':
				bodyPartIndex = currentHairColor;
				break;
		}
		return bodyPartIndex;
	}

	//
	function checkUnderlinedStyle(index: number) {
		if (index < totalOptions.length - 1) return 'cc-selection-field cc-selection-field-underlined';
		else return 'cc-selection-field';
	}

	//
	return (
		<>
			<div className='cc-left-panel'>
				<button className='btn custom-button blue' onClick={accept}>
					Comenzar
				</button>
				{user.avatar_id && user.avatar_id !== 0 ? (
					<button className='btn custom-button gray' onClick={cancel}>
						Volver atrás
					</button>
				) : (
					''
				)}
			</div>
			<div className='cc-right-panel'>
				{totalOptions.map((item, index) => (
					<div className={checkUnderlinedStyle(index)} key={index}>
						<div className='cc-sf-name'>{item.name}</div>
						<div className='cc-sf-button-group'>
							{item.buttons.length > 5 && item.bodyPart !== 'hair' && item.bodyPart !== 'beard' ? (
								<button
									className='cc-sf-button'
									onClick={(e) => displaceOptionButtons(item.bodyPart, -1, item)}
								>
									<img src='media/icons/castillo_monzon/izda.png' alt=''></img>
								</button>
							) : (
								''
							)}
							{item.buttons.map((button, indexB) =>
								checkIfShowSelectionButton(indexB, item.bodyPart, index, item, button) ? (
									<button
										className={checkIfSelectedClass('cc-sf-button', indexB, item.bodyPart, index)}
										key={indexB}
										onClick={(e) => button.onclick(indexB)}
									>
										{button.image !== '' ? (
											<img src={getDeSelectedImage(button, indexB)} alt=''></img>
										) : (
											''
										)}
										{button.image === '' && button.color !== '' ? (
											<div className='color-button' style={{backgroundColor: button.color}} />
										) : (
											''
										)}
									</button>
								) : (
									''
								)
							)}
							{item.buttons.length > 5 && item.bodyPart !== 'hair' && item.bodyPart !== 'beard' ? (
								<button
									className='cc-sf-button'
									onClick={(e) => displaceOptionButtons(item.bodyPart, 1, item)}
								>
									<img src='media/icons/castillo_monzon/dcha.png' alt=''></img>
								</button>
							) : (
								''
							)}
						</div>
					</div>
				))}
			</div>
		</>
	);
}

export {CharacterConfigurator};
