import {useState} from 'react';
import {GetUsersByBestTime} from '../../Socket/ApolloUtils';

function ChallengeOptions(args: any) {
	let gmRef = args.globalManager;

	let highScoreMockup = [
		{
			name: 'Nombre',
			surname: 'Apellido Apellido',
			best_time: 133,
		},
	];

	const [highScoreData, setHighScoreData] = useState(highScoreMockup);
	const [dataReady, setDataReady] = useState(false);

	let highScoreDDBBData: any[] = [];

	if (!dataReady) {
		GetUsersByBestTime().then((data) => {
			data.getUsersByBestTime.forEach((item: any) => {
				highScoreDDBBData.push({name: item.name, surname: item.surname, best_time: item.best_time});
			});
			setDataReady(true);
			setHighScoreData(highScoreDDBBData);
		});
	}

	function getFormattedTime(userTime: number) {
		if (userTime > 60) {
			let bestTimeMinutes = Math.floor(userTime / 60);
			let bestTimeSeconds = userTime % 60;
			//
			let bestTimeMinutesText = bestTimeMinutes < 10 ? '0' + bestTimeMinutes : bestTimeMinutes;
			let bestTimeSecondsText: string =
				bestTimeSeconds < 10 ? '0' + bestTimeSeconds : bestTimeSeconds + '';
			bestTimeSecondsText = bestTimeSecondsText.charAt(0) + bestTimeSecondsText.charAt(1);
			//
			return '00:' + bestTimeMinutesText + ':' + bestTimeSecondsText;
		} else if (userTime < 0) {
			return '...';
		} else {
			return '00:00:' + userTime;
		}
	}

	function resetChallenge() {
		args.globalManager.scene.resetCollectables();
	}

	return (
		<>
			<div className='popup-panel-title'>The Pill speed run challenge</div>
			<div className='popup-panel-dot-separator'></div>
			<div className='challenge-data'>
				<img width={'40px'} src='/media/icons/deusens/TokenColor.png' alt=''></img>
				<div>
					<div>
						Comprimidos recogidos - <b>{args.globalManager.currentPills}/10</b>
					</div>
					<div>
						Mejor tiempo - <b>{getFormattedTime(gmRef.user.best_time)}</b>
					</div>
				</div>
			</div>

			<button className='reset-challenge-button' onClick={resetChallenge}>
				{' '}
				Reiniciar challenge{' '}
			</button>

			<div className='popup-panel-dot-separator'></div>

			<button className='close-button' onClick={args.exitAudioOptions}>
				<img src='/media/icons/castillo_monzon/Cerrar.png' alt='' />
			</button>

			<div className='popup-panel-title'>High Score</div>
			<div className='high-score-group'>
				<ol>
					{highScoreData.map((item, index) => (
						<li className='highscore-element' key={index}>
							{item.name + ' ' + item.surname} - {getFormattedTime(item.best_time)}
						</li>
					))}
				</ol>
			</div>
		</>
	);
}

export {ChallengeOptions};
