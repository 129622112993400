import * as THREE from './3D/three.module.js';
import {AsyncLoader} from './AsyncLoader.js';
import * as Utils from './utils.js';
import {waitUntil} from 'async-wait-until';
import {Scene} from './scene.js';

export class AvatarPoolManager {
	malePool = [];
	femalePool = [];
	poolCount = 0;
	prepared = false;
	scene;
	poolParent;

	constructor(poolCount, scene) {
		this.poolCount = poolCount;
		this.scene = scene;
		this.poolParent = new THREE.Group();
		this.poolParent.visible = true;
		this.poolParent.matrixAutoUpdate = false;
		scene.add(this.poolParent);
	}

	async Prepare() {
		const originalMale = await AsyncLoader.GetPlayerModel(false);
		Scene.glScene.globalManager.assetLoaded();
		const originalFemale = await AsyncLoader.GetPlayerModel(true);
		Scene.glScene.globalManager.assetLoaded();
		for (let i = 0; i < this.poolCount; i++) {
			this.malePool.push(this._createModel(originalMale, false, i));
			this.femalePool.push(this._createModel(originalFemale, true, i));
		}
		this.prepared = true;
	}

	_createModel(original, isFemale, i) {
		const model = new PoolModel(
			Utils.clone(original.scene),
			i,
			isFemale,
			original.animations,
			this.scene
		);
		this.poolParent.add(model);
		model.position.set(0, -100, 0);
		return model;
	}

	async GetPlayerModel(aspect) {
		await this.WaitUntilPrepared();
		const model = this._GetModel(aspect.genre);
		if (model == null) {
			return null;
		} else {
			model.model.traverse((child) => {
				child.matrixAutoUpdate = true;
			});
			model.SetAspect(aspect);
			this.poolParent.remove(model);
			model.position.set(0, 0, 0);
			model.visible = true;
			return model;
		}
	}

	async WaitUntilPrepared() {
		try {
			await waitUntil(() => this.prepared);
		} catch {
			console.log('Loading models is taking to long, check your connection');

			await this.WaitUntilPrepared();
		}
	}

	_GetModel(isFemale) {
		const array = isFemale ? this.femalePool : this.malePool;
		for (let i = 0; i < this.poolCount; i++) {
			const poolElement = array[i];
			if (poolElement.available) {
				poolElement.available = false;
				return poolElement;
			}
		}
		return null;
	}

	ReleasePlayerModel(isFemale, model) {
		const array = isFemale ? this.femalePool : this.malePool;
		for (let i = 0; i < this.poolCount; i++) {
			const poolElement = array[i];
			if (poolElement.poolId === model.poolId) {
				model.available = true;
				model.parent.remove(model);
				this.poolParent.add(model);
				model.parent.remove(model.collisionCube);
				model.position.set(0, -100, 0);
				model.visible = false;
			}
		}
	}
}

const material = new THREE.MeshBasicMaterial();

class PoolModel extends THREE.Object3D {
	model = null;
	available = false;
	poolId = 0;
	hairs = [];
	beards = [];
	chests = [];
	trousers = [];
	shoes = [];
	skin = [];
	skinName = '';
	mixer;
	animations;
	collisionCube;

	constructor(model, id, isFemale, animations) {
		super();
		this.model = model;
		this.add(model);
		this.model.poolId = id;
		this.available = true;
		this.poolId = id;
		this.skinName = !isFemale ? 'Male_Body' : 'Female_Body';
		this.PrepareModel();
		this.animations = [animations[1], animations[0], animations[3], animations[4], animations[2]];
		this.mixer = new THREE.AnimationMixer(model);
	}

	PrepareModel() {
		this.model.traverse((child) => {
			child.matrixAutoUpdate = false;
			if (child.isMesh) {
				child.castShadow = false;
				child.receiveShadow = false;
				child.material.transparent = false;
			}

			if (child.name.indexOf('Hair') >= 0) {
				this.hairs.push(child);
			} else if (child.name.indexOf('Beard') >= 0) {
				this.beards.push(child);
			} else if (child.name.indexOf('Chest') >= 0) {
				this.chests.push(child);
			} else if (child.name.indexOf('Trousers') >= 0) {
				this.trousers.push(child);
			} else if (child.name.indexOf('shoes') >= 0) {
				this.shoes.push(child);
			} else if (child.name.indexOf(this.skinName) >= 0) {
				this.skin.push(child);
			}
		});
		this.orderArrays();
		this.model.scale.x = 1;
		this.model.scale.y = 1;
		this.model.scale.z = -1;

		const geometry = new THREE.BoxBufferGeometry(0.5, 3.6, 0.2);
		this.collisionCube = new THREE.Mesh(geometry, material);
		this.collisionCube.visible = false;
		this.collisionCube.rotation.set(0, 0, 0, 'XYZ');
		this.collisionCube.isPlayer = true;
	}

	orderArrays() {
		this.hairs.sort(Utils.sortingFunc);
		this.chests.sort(Utils.sortingFunc);
		this.trousers.sort(Utils.sortingFunc);
		this.shoes.sort(Utils.sortingFunc);
		this.skin.sort(Utils.sortingFunc);
		this.beards.sort(Utils.sortingFunc);
	}

	SetAspect(aspect) {
		this.hideOtherElements(this.hairs, aspect.hair);
		this.hideOtherElements(this.beards, aspect.beard);
		this.hideOtherElements(this.chests, aspect.jacket);
		this.hideOtherElements(this.trousers, aspect.trousers);
		this.hideOtherElements(this.shoes, aspect.shoes);
		this.hideOtherElements(this.skin, aspect.skin_color);
	}

	hideOtherElements(array, index) {
		for (let i = 0; i < array.length; i++) {
			array[i].matrixAutoUpdate = i === index;
			array[i].visible = i === index;
		}
	}
}
